import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";


class NotFound extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          logoUrl="/img/Hellasfon_logo.png"
          color="transparent"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_Networks_Retail.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <h1 className={classes.title}>
                  404 Η σελίδα δεν υπάρχει
                </h1>
                <h4>
Κάλεσε απο το σταθερό/κινητό με Ηellasfon σταθερή.​
Δωρεάν ομιλία , Chat, και video, με άλλους Ηellasfon συνδρομητές για ΠΑΝΤΑ.
Υπηρεσία την ίδια μέρα!

Kάλεσε απο το κινητό με την Hellasfon εφαρμογή και μείωσε τα έξοδα κινητής τηλεφωνίας.
                </h4>
                <br />
                <Button
                  color="primary"
                  size="lg"
                  href="/contact"
                  rel="noopener noreferrer"
                >
                  Eγγραφή
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Footer />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(NotFound);
