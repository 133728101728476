import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.jsx";

import CardMedia from "@material-ui/core/CardMedia";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";
//import SolutionSection from "./Sections/SolutionSection.jsx";
import AppSection from "../Sections/AppSectionBusinessEN.jsx";

import CookieConsent from "react-cookie-consent";

class WorkFromHomePage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Teleworking / Teleconference"}
          </h1>
          <div>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
                <h2>Teleworking - Working from home.</h2>
                <Typography variant="body1">
                  With HELLASFON PBX, remote working is easy.
                  <br />
                  <b>Teleworking / Teleconference ( Audio, Video, Chat ):</b>
                  <ul>
                    <li><p></p>
                      <b>NO NEED for equipment or devices expenses</b>,
                      you call/receive calls by your mobile and your computer when you are 
                      home or ON THE GO.
                    </li><p></p>
                    <li>
                      Every user enjoys <b>UNLIMITED</b> Communication with his colleagues
                      using video, messages and telephone directly to
                      his pc - {" "}
                      <a href="https://myaccount.hellasfon.com/vuc4">
                        MY ACCOUNT <p></p>
                      </a>
                      
                    </li>
                    <li>
                      Create a <b>Conference</b> between 3 or even more users/colleagues at the same time<p></p>
                    </li>
                    <li>
                      <b>Teleworking / Teleconference</b> is included in  
                       <b> ALL EVCOFFICE PBX's </b> and for <b>ALL users!</b>
                    </li>
                  </ul>
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  component="img"
                  image="../img/coronavirus-hellasfon-01.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="../img/coronavirus-hellasfon-03.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  All COMPANY users participate in teleconference :
                </h2>
                <Typography variant="body1" style={{ color: "#fff" }}>
                  <ol>
                    <li>by <b>IP phone</b> , </li><p></p>
                    <li>by <b>Mobile</b> using <b>EVCOFFICE</b> App and </li><p></p>
                    <li>by <b>PC</b> using {" "}
                      <a
                        style={{ color: "white" }}
                        href="https://myaccount.hellasfon.com/VUC4"
                      >
                        <strong>MY ACCOUNT </strong>
                      </a>{" "}
                      - (customer's webportal).
                    </li>
                  </ol>
                </Typography>
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>ON THE GO colleagues participate :</h2>
                <Typography variant="body1">
                  <ol>
                    <li>
                      <b>By Phone:</b> Calling the number and the password , 
                      that have received by email.<p></p>
                    </li>
                    <li>
                      <b>By PC:</b> Following the link that was send
                      to them ,{" "}
                      they can participate in the conference using <b>Video, voice and chat.</b><p></p>
                    </li>
                    <li>
                      <strong>Easy to use</strong>, NO programm installations or complex processes.
                      NO need for Registration!<p></p>
                    </li>
                    <li>
                      <b>PLUG AND PLAY</b> - following the weblink , you
                      automatically participate in meeting.
                    </li>
                  </ol>
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  component="img"
                  image="../img/coronavirus-hellasfon-06.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="../img/coronavirus_PBX-01.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  Teleworking features
                </h2>
                <Typography variant="body1" style={{ color: "#fff" }}>
                  <ul>
                    <li>Video</li>
                    <li>Calls</li>
                    <li>Chat and messages</li>
                  </ul>
                  <h2>Other facilities:</h2>
                  <ul>
                    <li>
                      Share files, photographs, places between all participants.
                    </li>
                  </ul>
                  <h2>Educational features of PBX</h2>
                  <ul>
                    <li>
                      * Share your screen with all participants by just one click!
                    </li>
                  </ul>
                </Typography>
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>STAY HOME !! WORK FROM HOME!!</h2>
                <Typography variant="body1">
                  Increase Company productivity , by using 
                  <b> HELLASFON FREE</b> tools for easy and remote work that 
                  <b> EVCOFFICE Cloud PBX</b> offers you.
                  <br /> <br />
                </Typography>
                <Typography variant="h5">
                  <b>ONLINE Registration! Ready in 5’ minutes!</b>
                  <br />
                  <br />
                </Typography>
                <Button fullWidth href="/cloudpbx#pricing" color="primary">
                  Register
                </Button>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  style={{
                    paddingTop: 100,
                    paddingRight: 100,
                    paddingBottom: 100,
                  }}
                  component="img"
                  image="../img/coronavirus_PBX-03.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ I agree"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies in this website to improve user's experience.
          {" "}
          <span style={{ fontSize: "12px" }}>
            By clicking in any link in this website you consent to set up cookies. {" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              More information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

WorkFromHomePage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(WorkFromHomePage);
