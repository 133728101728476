import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
//import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
//import PlansTable from "./Sections/PlansTableSection.jsx";
//import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionEN.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import ImageSection from "./Sections/ImageSection.jsx";
//import PriceTable from "./Sections/PriceTableSection.jsx";
//import YouTube from "react-youtube";
//import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import { getRetailPlanPacks, getPlans } from "actions/retailActions";

//import DialogAdvertisment from "./DialogAdvertisment";

class RetailPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      value_radio: "device",
      loading_plan_pack: true,
    };
  }

  async componentDidMount() {
    await this.props.getRetailPlanPacks("no_device");
    await this.props.getPlans("retail");
    this.setState({
      loading_plan_pack: false,
    });
    setTimeout(() => {
      this.setState({ open: true });
    }, 1000);
  }
  handleChange = async (e) => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true,
    });
    await this.props.getRetailPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false,
    });
    if (this.props.retail_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "There no plans for the moment",
      });
    }
  };
  selectPlan = async () => {
    return;
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, retail_plan_packs, plans, ...rest } = this.props;
    //const { loading_plan_pack, classSnackbar, response } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/travelgirl.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{paddingTop: '80px' }}>
                <h5>
                  <strong>
                    360TravelSIM : ONE eSIM for ALL countries of the world!
                  </strong>
                  <br />
                  <ul>
                    <li><b> 200+ countries </b> coverage </li>
                    <li>Suitable for <b> DATA </b> use  </li>
                    <li><b>No Roaming</b></li>
					<li><b>ALL</b> Network providers at the palm of your hand </li> 
					<li><b>INSTANT ACTIVATION</b></li>
                  </ul>
                  <strong>ONE SIM for use EVERYWHERE!</strong>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="https://www.360travelsim.com/en/cart"
                  rel="noopener noreferrer"
                >
                  BUY NOW
                </Button>
				<Button
                  color="secondary"
                  size="lg"
                  href="https://www.360travelsim.com/en/"
                  rel="noopener noreferrer"
                >
                  MORE INFO
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            360TravelSIM 
          </h1>
          <div className={classes.container}>
            <GridContainer id="pricing">
              
              
            </GridContainer>
            <SnackbarContent
              //className={classSnackbar}
              aria-describedby="client-snackbar"
              //message={response}
            />
            
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="/img/test1.jpg"
                headText="One eSIM for ALL countries of the world!"
                text1={[
                  <strong> - Works in more than 200 + Countries. </strong>,
				  <br />,
                  " Get in touch wherever your are located using 550 telecommunication providers. Always be available with packages that can change according your needs ; personal or your business. ",
                  <br />,
				  <br />,
                  <strong>
                    {" "}
                    - No Roaming expenses.{" "}
                  </strong>,
                  "  Use the online platform to see your existing communications usage and the economy to achieve thanks to usage.  ",
                  <br />,
				  <br />,
				  <strong> - Quick & Simple Process.</strong>,
				  <br></br>,
				  " Your new eSIM will be activated within 3 minutes. All you have to do is : 1) choose the country you want 2) choose the package that covers your needs and 3) fill-in your details online. Your 360travelsim will be emailed to you and is ready to be activated !   ",
				]}
              />
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="../img/luggages_sign.jpg"
                headText=" Why choose 360travelsim ? "
                text1={[
                  <strong>Fast and Easy </strong>,
				  <br />,
                  " - The best way to travel to any part of the world ; at any time without being out of reach or having to buy new sim card for every place you are about visit.",
				  <br />,
                  <br />,
                  " ",
                  <strong> Network Coverage & Security - All mobile providers at one eSIM !</strong>,
                  <br />,
                  "- By using 360travelsim you are given the option to choose whichever provider you wish that has the strongest signal or data provision (automatic or manual)",
				   <br />,
                  <br />,
				  <strong>One package ? - Coverage for many countries and several destinations !</strong>,
				  " ",
				  <br />,
                  
				  "- And best of all ? By choosing a package for 360travelsim we give you coverage in other countries and destinations besides the one you initially choosen ! Use your 360travelsim to meet all your coverage and connectivity needs no matter if you are a leisure traveler or travelling abroad for business reasons.",
                  
                ]}
                
              />
			  <br></br>
			  <Button fullWidth href="https://360travelsim.com/en" color="secondary" size="lg" >
                  LEARN MORE ABOUT 360travel eSIM & IT's BENEFITS
                </Button>
            </div>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />

          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ I agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            We use cookies in this website to improve user's experience.{" "}
            <span style={{ fontSize: "12px" }}>
              By clicking on any website link you consent to setup cookies.{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                More information
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

RetailPage.propTypes = {
  classes: PropTypes.object,
  retail_plan_packs: PropTypes.array.isRequired,
  getRetailPlanPacks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  retail_plan_packs: state.retail_reducer.retail_plan_packs,
  plans: state.retail_reducer.plans,
});

export default connect(
  mapStateToProps,
  { getRetailPlanPacks, getPlans }
)(withStyles(landingPageStyle)(RetailPage));
