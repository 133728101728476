import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import PlansTable from "./Sections/PlansTableSection.jsx";
import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import ListItem from "@material-ui/core/ListItem";
import YouTube from "react-youtube";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionBusinessEN.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import ImageSection from "./Sections/ImageSection.jsx";

import PriceTable from "./Sections/PriceTablePBXSection.jsx";

import { connect } from "react-redux";
import { getPBXPlanPacks, getPlans } from "actions/pbxActions";

import DialogAdvertisment from "./DialogAdvertismentEN";

class BussinesPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      value_radio: "device",
      loading_plan_pack: true,
      open: false
    };
  }
  async componentDidMount() {
    await this.props.getPBXPlanPacks();
    this.props.getPlans();
    this.setState({
      loading_plan_pack: false
    });
    setTimeout(() => { this.setState({open: true}); }, 1000);
  }
  handleChange = async e => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true
    });
    await this.props.getPBXPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false
    });
    if (this.props.pbx_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "There are no available plans for the moment"
      });
    }
  };

  selectPlan = async () => {
    return;
  };
  handleClose = () => {
    this.setState({open: false})
  };

  render() {
    const { classes, pbx_plan_packs, plans, ...rest } = this.props;
    const { loading_plan_pack, classSnackbar, response, open } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/hellasfon_business_header_0_0.jpg")}
        >
          <div className={classes.container}>
          <DialogAdvertisment open={open} handleClose={this.handleClose}/>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
              <h5>
                <strong>EVCOFFICE CLOUD PBX!</strong><br />
				<ul>
				  <li><strong>Increase company Productivity, Economy and Flexibility.</strong></li>
                                  <li><strong>AVOID</strong> additional expenses for equipment or devices.</li>
 				  <li><strong>NON-STOP</strong> Communication using EVCOFFICE app, remote working. For all Mobiles and all pc's.</li>
				  <li>Turnkey UC solution with <strong>Plug and Play</strong> - ONLINE registration!</li>
				  </ul>
				  <strong>Ready in 5 minutes and 3 steps!</strong>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="#pricing"
                  rel="noopener noreferrer"
                >
                  Register
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            HELLASFON Business Telephony
          </h1>
          <div className={classes.container}>
            <GridContainer id="info">
            <GridItem xs={12} sm={12} md={12}>

              </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ListItem>
                <Typography component="h4" variant="h4" color="primary">
                Build your own EVCOFFICE CLOUD PBX in 5 minutes and 3 steps!
                </Typography>
              </ListItem>
              <ListItem>
                <YouTube className="youtube-video" videoId={"1m0Z58ijE5w"} />
              </ListItem>
              <ListItem>
                <Typography component="h5" variant="body1" color="textPrimary">
                <p></p><strong>Step 1 - REGISTER </strong> a) Select the plan that suits your business needs. Complete your registration and <strong>choose additional bundles</strong> of talk-time.<br></br><p></p>TALK-TIME is SHARED among ALL Users. After registeration you will receive by email your username/password for your cloud PBX.
                <br></br><p></p>
                <strong>Step 2 – ADD USERS </strong>- Login to MY ACCOUNT , add company users just by entering their email.<br></br><br></br>
                <strong>Step 3 - DOWNLOAD EVCOFFICE App </strong>- Each user downloads <strong>EVCOFFICE</strong> app and connects using username,password that received by email.<br></br><p></p><br></br>
                 <p></p>- With these credentials each user can connect to MY ACCOUNT portal also for Remote Working through his/her pc.<br></br><p></p>
		- Want <strong>IP Telephony devices</strong> for your office ? Pick up from <a href='https://shop.hellasfon.com/'>HELLAFON E-SHOP</a> and they will be sent ready to use ; pre-programmed for your PBX !<br></br>
                <br></br>
                <br></br>
                <strong>
                All HELLASFON plans are WITHOUT CONTRACT and 
                  LOWEST PRICE GUARANTEED.
                  </strong>
                </Typography>
              </ListItem>
              </GridItem>
            </GridContainer>
          </div>

          <div className={classes.container}>
            <SnackbarContent
              className={classSnackbar}
              aria-describedby="client-snackbar"
              message={response}
            />
            <div id="pricing">
              {loading_plan_pack ? (
                <GridContainer  justify="center">
                  <CircularProgress size={50} />
                </GridContainer>
              ) : (
                <React.Fragment>
                  <GridContainer style={{marginTop: "0"}}>
                    <GridItem xs={12} sm={12} md={12}>
                      <PriceTable
                        url={"/regbusiness/"}
                        plan_packs={pbx_plan_packs}
                      />
                    </GridItem>

                  </GridContainer>
                <Typography component="h4" variant="h4" color="primary" style={{ marginBottom: "10px"}}>Additional Bundles</Typography>
                <GridContainer spacing={2}>
                  {plans.map(plan => (
                    <PlansTable
                      plan={plan}
                      key={plan.id_plan}
                      selectPlan={this.selectPlan}
                    />
                  ))}
                </GridContainer>

            </React.Fragment>
              )}
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="../img/hellasfon_evcoffice_app_0.png"
                headText=" Free Apps for mobiles ( Android & IOS ) and pc, for your company ! "
                text1={[
                  <strong>One Dedicated Line for each User</strong>,
                 " - Free voice channels and lines for everybody. All users have their own telephone line. We ensure 100% availability in all EVCOFFICE Cloud PBX's. 100 users ? 100 lines!",<br/>,<br/>,
                  
				  <strong>EVCOFFICE APP</strong>,
            " -  UNLIMITED intercompany calls, video and chat for EVER. Reduce your existing communication expenses by using EVCOFFICE app even when you are ON THE GO.",<br/>,<br/>,
            <strong>Remote work: Teleconference/Teleworking</strong>,
				    "- Each user can communicate with all company members by using video, messages and voicecalls directly from his pc (MY ACCOUNT). Start a conference between 3 or more partners or clients.",<br/>,<br/>,
                <strong>Free productivity tools</strong>,
            " -  Click2Talk (C2T), file transfers, web chat, fax to email, free recording platform , and many more features that will help your company achieve goals of productivity and economy.",<br/>,
				]}
              />
            </div>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ i agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies at this website to increase user's experience.{" "}
            <span style={{ fontSize: "12px" }}>
            By clicking on any of these links of this website, you agree to set up cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
        </CookieConsent>
      </div>
    );
  }
}

BussinesPage.propTypes = {
  classes: PropTypes.object,
  pbx_plan_packs: PropTypes.array.isRequired,
  getPBXPlanPacks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  pbx_plan_packs: state.pbx_reducer.pbx_plan_packs,
  plans: state.pbx_reducer.plans
});

export default connect(
  mapStateToProps,
  { getPBXPlanPacks, getPlans }
)(withStyles(landingPageStyle)(BussinesPage));
