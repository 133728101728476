import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import CardPricing from "components/CardPricing/CardPricing.jsx";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";

import pricingStyle from "assets/jss/material-kit-react/views/landingPageSections/pricingStyle.jsx";

class PriceTable extends React.Component {
  render() {
    const { classes, url, plan_packs } = this.props;

    return (
      <React.Fragment>
        <GridContainer spacing={2} style={{marginTop: "0"}}>
          {plan_packs.map(plan_pack => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={plan_pack.name} xs={12} sm={12} md={plan_packs.length === 1? 12 : 4}>
              <CardPricing>
                <CardHeader
                  className={classes.header}
                  style={ (plan_pack.name === "HOME Unlimited" || plan_pack.name === "Business Line" ||
                  plan_pack.name === "HOME | PLUS")
                    ? {backgroundColor: "#f50057"} : null}
                  title={plan_pack.name}
                  subheader={
                    plan_pack.name === "HOME Unlimited" || plan_pack.name === "Business Line" ||
                    plan_pack.name === "HOME | PLUS"
                      ? "Το πιο δημοφιλές"
                      : ""
                  }
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                    color: "initial"
                  }}
                  action={
                    plan_pack.name === "HOME Unlimited" || plan_pack.name === "Business Line" ||
                    plan_pack.name === "HOME | PLUS" ? (
                      <StarIcon />
                    ) : null
                  }
                />
                <CardContent>
                  <div className={classes.content}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      € {plan_pack.period_cost}*
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /ΜΗΝΑ
                    </Typography>
                  </div>
                  <ul style={{ margin: "0", padding: "0" }}>
                    {plan_pack.description.map(line =>
                      line !== " Δωρεάν εξοπλισμό" ? (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                          style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                        >
                          {line}
                          <Divider />
                        </Typography>
                      ) : (
                        <Typography
                          component="li"
                          variant="h6"
                          align="center"
                          key={line}
                          style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                        >
                          <img
                            src="img/grandstream-ht801.jpg"
                            alt="Hellasfon Grandstream HT801"
                            height="50"
                          />
                          {line}
                          <Divider />
                        </Typography>
                      )
                    )}
                  </ul>
                </CardContent>
                {url !== "/reg/retail/" ? ( <CardActions>
                  <Button
                    fullWidth
                    color="secondary"
                    href="/contact"
                  >
                    Ζητήστε προσφορά
                  </Button>
                </CardActions>) : null
                }
                <CardActions>
                  <Button
                    fullWidth
                    color="primary"
                    style={(plan_pack.name === "HOME Unlimited" || plan_pack.name === "Business Line" ||
                    plan_pack.name === "HOME | PLUS")
                      ?  {backgroundColor: "#f50057"}  : null}
                    href={url + plan_pack.id_plan_pack}
                  >
                    εγγραφή και αγορά υπηρεσίας
                  </Button>
                </CardActions>
              </CardPricing>
              <Typography
                component="li"
                variant="caption"
                align="center"
                style={{
                  listStyle: "none",
                  padding: "5px 0 0 5px",
                  fontSize: "10px",
                  color: "black"
                }}
              >
                {
                  "* Οι τιμές συμπεριλαμβάνουν ΦΠΑ 24% και Τέλος Συνδρομητών Σταθερής Τηλεφωνίας."
                }
              </Typography>
            </Grid>
          ))}
        </GridContainer>
      </React.Fragment>
    );
  }
}
PriceTable.propTypes = {
  classes: PropTypes.object,
  url: PropTypes.string
};

export default withStyles(pricingStyle)(PriceTable);
