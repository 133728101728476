import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusinessEN.jsx";

import CookieConsent from "react-cookie-consent";

class ShipPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Solutions for Shipping Companies"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
              <h2>Sip Trunk Interconnection</h2>
              <Typography variant="body1">
                <li>Unlimited Port Availability</li>
				<li>High Connectivity</li>
				<li> HD Quality Voice</li>
				<li> High Call Completion </li>
				<br></br>
				<strong>Hellasfon is DIRECTLY connected to the INMARSAT Network
				providing : 
				<li>HD voice quality</li>
				<li>lowest calling rates!</li></strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/solution/call-inmarsat-contact" color="primary">
                GET AN OFFER
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/hellasfon_shipping_companies_solution_01.jpg"
                alt="Hellasfon Shipping Companies Solutions"
                title="Hellasfon Shipping Companies Solutions"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="/img/Ship_Solutions_Hellasfon.jpg"
                alt="Shipping Solutions Hellasfon"
                title="Ship Solutions Hellasfon"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>LOCAL Number Solution</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                Call INMARSAT by calling a LOCAL Number in your area. <br /><p></p> <br></br>
				Simple and easy to use. Register a local number in your area and forward all your calls to the Inmarsat number. 
				Easy and seamless. 
				Call your local number and talk to the satellite phone or number with the lowest rates in the market.Save up to 75% on all your calls.
				<br /><p></p> <br></br>
                <Button href="/solution/call-inmarsat-contact" color="secondary">
				 GET AN OFFER
              </Button>
              </Typography>
			 
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>Cloud PBX Solution</h2>
              <Typography variant="body1">
			    <li> Assign LOCAL numbers from ANY country to your PBX and
				reach your Vessels by dialing an extension!</li>
                <li>
                  Shore to Ship Voice Services
                </li>
                <li> Call INMARSAT by dialing an extension number </li>
                <br></br>
				<strong>EASY & COST EFFECTIVE </strong>
				<br></br>
				<h3><strong>Call Local - Talk GLOBAL! </strong></h3>
				<Button href="/solution/call-inmarsat-contact" color="primary">
                GET AN OFFER
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/hellasfon_shore_to_ship_services.jpg"
                alt="shore to Ship voice services"
                title="Hellasfon Shore to Ship voice services"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  <AppSection />
          </GridContainer>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ aggree"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies in this website to improve our customers experience
          .{" "}
          <span style={{ fontSize: "12px" }}>
            By clicking on any link at this webpage you consent to setup cookies
            .{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              More information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

ShipPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(ShipPage);
