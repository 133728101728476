import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import PlansTable from "./Sections/PlansTableSection.jsx";
import Typography from "@material-ui/core/Typography";

import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionEN.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import ImageSection from "./Sections/ImageSection.jsx";

import PriceTable from "./Sections/PriceTableSection.jsx";
//import YouTube from "react-youtube";
import ListItem from "@material-ui/core/ListItem";

import { connect } from "react-redux";
import { getRetailPlanPacks, getPlans } from "actions/retailActions";

//import DialogAdvertisment from "./DialogAdvertisment";

class RetailPageEN extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      value_radio: "device",
      loading_plan_pack: true,
    };
  }

  async componentDidMount() {
    await this.props.getRetailPlanPacks("no_device");
    await this.props.getPlans("retail");
    this.setState({
      loading_plan_pack: false,
    });
    setTimeout(() => {
      this.setState({ open: true });
    }, 1000);
  }
  handleChange = async (e) => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true,
    });
    await this.props.getRetailPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false,
    });
    if (this.props.retail_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "There are no plans at this time",
      });
    }
  };
  selectPlan = async () => {
    return;
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, retail_plan_packs, plans, ...rest } = this.props;
    const { loading_plan_pack, classSnackbar, response } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_Networks_Retail.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{paddingTop: '80px' }}>
                <h5>
                  <strong>
                    Call GREECE like a LOCAL with HELLASFON!
                  </strong>
                  <br />
                  <ul>
                    <li>GREEK Telephone line and number </li>
                    <li><b>REGISTER</b> and get a Landline phone number in your mobile. </li>
                    <li>NO CONTRACTS, NO ROAMING FEES EVER!</li>
					<li>Download app and Sign Up today!</li>
                  </ul>
                  <strong>Call Greece like a LOCAL!</strong>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="#pricing"
                  rel="noopener noreferrer"
                >
                  Sign up
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            Hellasfon Telephony
          </h1>
          <div className={classes.container}>
            <GridContainer id="pricing">
              <ListItem>
                <Typography component="h4" variant="h4" color="primary">
                  Quality, Flexibility and Ecomomy with HELLASFON
                </Typography>
              </ListItem>
              <ListItem>
                <Typography component="h5" variant="body1" color="textPrimary">
                  With HELLASFON you take your GREEK line and number with you where ever you go.
				  <br></br>
				  <br></br>
				  <strong>Step 1</strong> - Select a plan and a GREEK local number that suits your needs.
                  <br></br>
                  <strong>Step 2</strong> - Need more minutes? Finish up your subscription with additional airtime or bundles.
                  <br></br>
				  <strong>Step 3</strong> - Download the free <strong>HELLASFON app</strong> and call Greece today!{" "}
                  
                  
                  <br></br>
				  <br></br>
                </Typography>
              </ListItem>
            </GridContainer>
            <SnackbarContent
              className={classSnackbar}
              aria-describedby="client-snackbar"
              message={response}
            />
            <div id="pricing">
              {loading_plan_pack ? (
                <GridContainer justify="center">
                  <CircularProgress size={50} />
                </GridContainer>
              ) : (
                <React.Fragment>
                  <GridContainer style={{ marginTop: "0" }}>
                    <GridItem xs={12} sm={12} md={12}>
                      <PriceTable
                        url={"/reg/retail/"}
                        plan_packs={retail_plan_packs}
                      />
                    </GridItem>
                  </GridContainer>

                  <Typography
                    component="h4"
                    variant="h4"
                    color="primary"
                    style={{ marginBottom: "10px" }}
                  >
                    Additional Bundles {" "}
                  </Typography>
                  <GridContainer spacing={2}>
                    {plans.map((plan) => (
                      <PlansTable
                        plan={plan}
                        key={plan.id_plan}
                        selectPlan={this.selectPlan}
                      />
                    ))}
                  </GridContainer>
                </React.Fragment>
              )}
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="img/callcenter.jpg"
                headText="Hellasfon Mobile, Business Plans, Telephone Lines and Numbers"
                text1={[
                  <strong> - For home and office </strong>,
                  
				  " -  Home service , EVCOFFICE Cloud PBX solutions, SIP TRUNKS and ROAM FREE GLOBAL GSM services. ",
                  <br />,
                  <strong>
                    {" "}
                    - 360 IOT sim, 360 Travelsim, Retail and Corporate 4G global multi vendor connectivity.
                    {" "}
                  </strong>,
                  "- Check also our PBX CLOUD system  ",
                ]}
              />
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="img/hellasfonappdisplay_0.png"
                headText="Hellasfon app for your Landline number! (Αndroid και IOS)"
                text1={[
                  <strong>HELLASFON APP</strong>,
				  
                  " - Unlimited Calls, Video and Chat with all Hellasfon users, FOR EVER!",
                  <br />,
                  "- ",
                  <strong>Voicemail to email</strong>,
                  <br />,
                  "- Online access to your account. Log into your customer portal to make online payments and see all calls details.-",
                  <strong>MYACCOUNT</strong>,
                ]}
                text2={[
                  <strong>NO CONTRACTS or COMMITMENT</strong>,
                  "- Sign up without commitments! Use service for the period you want, no questions asked. Take your Greek Line and Number with you wherever you go. Call and answer your Greek number from your mobile with HD quality and local rates.",
                  <br />,
                  "- Drastically reduce mobile and landline expenses.",
                  <br />,
                  "- Exchange files, locations, photos, even SMS with Hellasfon app.",
                 <br />,
				  
                  <strong>NO ROAMING!</strong>,
                  "– They call you at your landline number and you respond using your mobile wherever you are in the world! No additional charges!",
                  <br />,
                  "- You are calling using Hellasfon app using your bundle free call time and the low cost rates of Hellasfon.",
                ]}
              />
            </div>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />

          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ I agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            We use cookies on this site to improve the experience of users.
            {" "}
            <span style={{ fontSize: "12px" }}>
              Clicking on any link on this page gives us your consent to use cookies.{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                More info
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

RetailPageEN.propTypes = {
  classes: PropTypes.object,
  retail_plan_packs: PropTypes.array.isRequired,
  getRetailPlanPacks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  retail_plan_packs: state.retail_reducer.retail_plan_packs,
  plans: state.retail_reducer.plans,
});

export default connect(
  mapStateToProps,
  { getRetailPlanPacks, getPlans }
)(withStyles(landingPageStyle)(RetailPageEN));
