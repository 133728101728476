import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
//import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import scrollIntoView from "scroll-into-view-if-needed";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import {
  removeOrder,
  verifyCode,
  verifyMobilePBX,
  getlocalAreas,
} from "actions/pbxActions";

class PhoneBusiness extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      code: "",
      loading_verifyMobile: false,
      errors: {
        phonemove: false,
        code: false,
      },
    };
  }

  async componentDidMount() {
    this.setState({ loading_verifyMobile: false });
    await this.props.getlocalAreas();
  }

  onClick = async (e) => {
    e.preventDefault();

    const {
      company,
      vat,
      classes,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      verify_mobile,
      city,
      aoy,
    } = this.props;

    if (verify_mobile.order_id !== undefined) {
      if (!this.state.loading_verifyMobile) {
        this.setState({ loading_verifyMobile: true });
        const res_back = await this.props.removeOrder(
          verify_mobile.order_id,
          verify_mobile.dsfsafw352
        );
        if (res_back) {
          // display error message
          this.setState({
            loading_verifyMobile: false,
            classSnackbar: classes.errorSnackbar,
            response: "Κάτι πήγε στραβά πάλι.",
          });
        }
      }
    }
    this.setState({ loading_verifyMobile: false });
    this.props.handleBack(
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      city,
      aoy
    );
  };
  onClickChnagePhone = () =>
    this.setState({
      changePhone: !this.state.changePhone,
    });
  onChange = (e) => {
    this.setState(
      (e.target.value === "" && e.target.id === "code") ||
        (e.target.value.length < 5 && e.target.id === "code") ||
        (e.target.value.length > 5 && e.target.id === "code") ||
        (e.target.id === "code" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: false,
            },
          }
    );
  };
  onBlur = (e) => {
    this.setState(
      (e.target.value === "" && e.target.id === "code") ||
        (e.target.value.length < 5 && e.target.id === "code") ||
        (e.target.value.length > 5 && e.target.id === "code") ||
        (e.target.id === "code" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : null
    );
  };
  onVerify = async (e) => {
    e.preventDefault();

    const {
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      classes,
      pbx_plan_pack,
      city,
      aoy,
    } = this.props;

    const { loading_verifyMobile } = this.state;

    // Check For Errors
    if (!loading_verifyMobile) {
      this.setState({ loading_verifyMobile: true });
      // sent axios loading
      const res = await this.props.verifyMobilePBX(
        company,
        vat,
        firstName,
        lastName,
        email,
        phonemobile,
        phone,
        address,
        tk,
        pbx_plan_pack,
        city,
        aoy
      );
      if (res) {
        // display error mesage
        this.setState({
          loading_verifyMobile: false,
          classSnackbar: classes.errorSnackbar,
          response: "Κάτι πήγε πάλι λάθος. Ελέγξτε το κινητό σας τηλέφωνο.",
        });
      }
    }

    this.setState({ loading_verifyMobile: false });
    if (this.props.verify_mobile.verify === true) {
      // response with veryfication code true - enter veryfication code Input
      this.setState({
        classSnackbar: classes.successSnackbar,
        response: "Το SMS αποστάλθηκε με επιτυχία.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
    } else {
      // display error mesage
      this.setState({
        classSnackbar: classes.errorSnackbar,
        response: "Κάτι πήγε πάλι λάθος. Ελέγξτε το κινητό σας τηλέφωνο.",
      });
    }
    return;
  };
  onSubmit = async (e) => {
    e.preventDefault();

    const {
      classes,
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      verify_mobile,
      city,
      aoy,
    } = this.props;

    const { code, loading_verifyMobile } = this.state;

    // Check For Errors
    if (
      code === "" ||
      code === undefined ||
      (code && code.length < 5) ||
      (code && code.length > 5) ||
      (code && !code.match(/^[0-9\b]+$/))
    ) {
      this.setState({
        errors: { code: true },
        classSnackbar: classes.errorSnackbar,
        response: "Ο κωδικός επαλήθευσης SMS δεν είναι έγκυρος.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
      return;
    }
    if (verify_mobile.order_id !== undefined) {
      if (!loading_verifyMobile) {
        this.setState({ loading_verifyMobile: true });
        const res = await this.props.verifyCode(
          verify_mobile.order_id,
          verify_mobile.dsfsafw352,
          code
        );
        if (res) {
          // display error mesage
          this.setState({
            loading_verifyMobile: false,
            classSnackbar: classes.errorSnackbar,
            response: "Ο κωδικός επαλήθευσης SMS δεν είναι έγκυρος.",
          });
        }
      }
    }
    this.setState({ loading_verifyMobile: false });
    if (this.props.verify_code === true) {
      this.setState({
        classSnackbar: classes.successSnackbar,
        response: "Ο αριθμός του κινητού σας έχει επιβεβαιωθεί",
      });
      // handle next
      this.props.handleNext(
        company,
        vat,
        firstName,
        lastName,
        email,
        phonemobile,
        phone,
        address,
        tk,
        accept,
        verify_mobile,
        city,
        aoy
      );
    } else {
      // display error mesage
      this.setState({
        classSnackbar: classes.errorSnackbar,
        response: "Ο κωδικός επαλήθευσης SMS δεν είναι έγκυρος.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
    }

    return;
  };
  render() {
    const {
      activeStep,
      classes,
      phonemobile,
      loading_phoneForm,
      verify_mobile,
    } = this.props;
    const {
      errors,
      classSnackbar,
      response,
      code,
      loading_verifyMobile,
    } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            Επάληθευση Κινητού
          </Typography>
          <Grid container>
            <GridItem xs={12}>
              <Typography>
                Για λόγους ασφάλειας η Hellasfon πρέπει να επαληθεύσει τον
                αριθμό του κινητού σας, ΠΡΙΝ την εγγραφή σας. Ελέγξτε αν
                εμφανίζεται σωστά το νούμερο σας παρακάτω και στην συνέχεια πατήστε
                το κουμπί ΕΠΑΛΗΘΕΥΣΗ ΚΙΝΗΤΟΥ. Διαφορετικά επιστρέψτε στο προηγούμενο βήμα!
              </Typography>
              <CustomInput
                type="text"
                labelText="- ΑΡΙΘΜΟΣ ΚΙΝΗΤΟΥ - "
                id="phonemobile"
                name="phonemobile"
                value={phonemobile}
                onChange={this.onChange}
                disabled={true}
                /*helperText="Συμπληρώστε τον αριθμό του κινητού σας, προσθέτοντας στην αρχή τον κωδικό χώρας π.χ 306988123412 ή 447578754922"*/
                formControlProps={{
                  fullWidth: true,
                }}
                /*inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+30</InputAdornment>
                  )
                }}*/
              />
              {verify_mobile.verify && (
                <CustomInput
                  error={errors.code ? true : false}
                  type="text"
                  labelText="κωδικός επιβεβαίωσης"
                  id="code"
                  name="code"
                  value={code}
                  onChange={this.onChange}
                  onFocus={this.onBlur}
                  onBlur={this.onBlur}
                  disabled={loading_verifyMobile}
                  helperText="Ελέγξτε το κινητό σας ! Σας έχουμε στείλει ένα SMS με κωδικό πρόσβασης"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            </GridItem>
          </Grid>
          <SnackbarContent
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
          <GridContainer className={classes.buttons}>
            <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
              {activeStep !== 0 && (
                <Button
                  onClick={this.onClick}
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  Πίσω
                </Button>
              )}
              {!verify_mobile.verify ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onVerify}
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  {"Επαλήθευση κινητού"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  {"ΕΠΟΜΕΝΟ"}
                </Button>
              )}
              {loading_phoneForm && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {loading_verifyMobile && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </GridItem>
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

PhoneBusiness.propTypes = {
  classes: PropTypes.object,
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};

const mapStateToProps = (state) => ({
  verify_mobile: state.pbx_reducer.verify_mobile,
  verify_code: state.pbx_reducer.verify_code,
  pbx_plan_pack: state.pbx_reducer.pbx_plan_pack,
});

export default connect(
  mapStateToProps,
  { verifyMobilePBX, removeOrder, verifyCode, getlocalAreas }
)(withStyles(workStyle)(PhoneBusiness));
