import React from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import CardMedia from "@material-ui/core/CardMedia";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormGroup from "@material-ui/core/FormGroup";

import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

class AppSection extends React.Component {
  constructor(props) {
    super(props);
    this.androidClick = this.androidClick.bind(this);
    this.iosClick = this.iosClick.bind(this);
  }

  androidClick() {
    window.location.assign(
      "https://shop.hellasfon.com/app/hf.apk"
    );
  }
  iosClick() {
    window.location.assign(
      "https://apps.apple.com/ae/app/hellasfon/id1477447672"
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section} style={{ backgroundColor: "#0178e2" }}>
        <h2
          className={classes.title}
          style={{ color: "white", textAlign: "center" }}
        >
          Hellasfon
        </h2>

        <div className="body" style={{ color: "white", textAlign: "center" }}>
          <h4>
            Κατεβάστε ΔΩΡΕΑΝ το HELLASFON app για android και ios.
            <br />
            ΔΩΡΕΑΝ κλήσεις, chat και videocalls πρός άλλους HELLASFON συνδρομητές για
            ΠΑΝΤΑ!
          </h4>

          <GridContainer justify="center" style={{ marginRight: "15px" }}>
            <FormGroup row>
              <GridItem
                xs={6}
                sm={6}
                md={6}
                style={{
                  paddingLeft: "55px",
                  paddingRight: "55px",
                  paddingTop: "15px",
                }}
              >
                <Fab
                  onClick={() => this.androidClick(this)}
                  style={{
                    width: "120px",
                    height: "120px",
                  }}
                  aria-label="Android"
                  color="secondary"
                >
                  <AndroidIcon style={{ fontSize: "60px" }} />
                </Fab>
              </GridItem>
              <GridItem
                xs={6}
                sm={6}
                md={6}
                style={{
                  paddingLeft: "55px",
                  paddingRight: "55px",
                  paddingTop: "15px",
                }}
              >
                <Fab
                  onClick={() => this.iosClick(this)}
                  style={{
                    width: "120px",
                    height: "120px",
                  }}
                  color="secondary"
                  aria-label="apple"
                >
                  <AppleIcon style={{ fontSize: "60px" }} />
                </Fab>
              </GridItem>
            </FormGroup>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <GridItem xs={12} sm={12} md={6}>
              <CardMedia
                component="img"
                alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                image={require("../../assets/img/hellasfon-smartphone-app.png")}
                title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

AppSection.propTypes = {
  classes: PropTypes.object,
};

//export default AppSection;
export default withStyles(modalStyle)(AppSection);
