import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusinessEN.jsx";

import CookieConsent from "react-cookie-consent";

class EshopPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}s
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Solutions for E-Shops"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
		  
		  
		  
            <GridItem cs={12} sm={12} md={6} style={{ padding: 10 }}>
              <h2>Cloud PBX EVCΟFFICE</h2>
              <Typography variant="body1">
                <li> Unlimited Lines & Voice Channels</li>
				<li> Easy Business Number</li>
				<li> HD Voice Quality</li>
				<li> Take your Eshop's fixed line with you wherever you are ! </li>
				<li> Digital Assistant / Auto reception </li>
				<li> Platform for sending SMS for your E-Shop </li>
				<br></br>
				<strong>Hellasfon has innovative services for business customers providing: 
				<li>HD Sound Quality</li>
				<li>Lower call rates without intermediaries!</li></strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/contact" color="primary">
                GET AN OFFER
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/eshop1.jpg"
                alt="Solutions by Hellasfon for electronics stores"
                title="Hellasfon solutions for e-shops"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 30 }}
                component="img"
                image="/img/recept.jpg"
                alt="Digital Assistand for your PBX"
                title="Hellasfon - Pre Answering Service"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Digital Assistant/Auto Reception</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                With the use of the digital assistant, your customers are listening pre-recorded messages and all your calls are automatically managed based on the scenario you desire. <br /><p></p> 
				There is no limit to the scripts that will be executed! All you have to do is record the pre-answer messages that you want to be heard
				and decide your usage scenario.<br></br>		Simple and easy use !<br></br>
				<br></br>
				At any time you decide to make any change, you can do it very easily yourself; from the pbx center management.<br></br> <br></br> Save up to 75% on all your calls.
				<br /><p></p> <br></br>
                
			  <Button href="/cloudpbx#pricing" color="warning">
                  SEE PBX PACKAGES
              </Button>
              </Typography>
			 
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 30 }}>
              <h2>Send SMS for your e-shop via API</h2>
              <Typography variant="body1">
			    <li> Send SMS messages to your E-shop customers through your website ! </li>
                <li> Use REST API for a real easy interface.</li>
                <li> Credits NEVER expire ! </li>
                <br></br>
				
				<br></br>
				<h3><strong> Complete Solutions for your Business! </strong></h3>
				<Button href="https://myaccount.easyclicksms.com/register" color="primary">
                FREE SIGNUP
              </Button>
			  <Button href="/sms" color="secondary">
                READ MORE
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/sms1.jpg"
                alt="Service SMS for eshops"
                title="Hellasfon - SMS Service for electronic stores"
              />
            </GridItem>
						
		  
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  
		  <AppSection />
		  </GridContainer>
		  
		  <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies on this website to improve 
          users experience.{" "}
          <span style={{ fontSize: "12px" }}>
            By clicking on any link on this page you give 
            your consent to set up cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              More information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

EshopPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(EshopPage);
