import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import JobSection from "./Sections/JobSection.jsx";
import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class Job extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            {"Θέση Εργασίας"}
          </h1>
          <div className={classes.container}>
          <JobSection />
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

Job.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Job);
