import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusiness.jsx";

import CookieConsent from "react-cookie-consent";

class ShipPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Λύσεις για Ναυτιλιακές Εταιρίες"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
		  
		  
		  
            <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
              <h2>Διασύνδεση μέσω Sip Trunk</h2>
              <Typography variant="body1">
                <li>Απεριόριστη διαθεσιμότητα (κανάλια φωνής)</li>
				<li>Υψηλή Συνδεσιμότητα</li>
				<li> HD Ποιότητα φωνής</li>
				<li> Υψηλό ποσοστό επιτυχίας κλήσης  </li>
				<li> Υποστηρίζονται όλα τα τηλεφωνικά κέντρα </li>
				<br></br>
				<strong>Η Hellasfon διαθέτει απευθείας διασύνδεση με το δίκτυο INMARSAT
				παρέχοντας : 
				<li>HD Ποιότητα ήχου</li>
				<li>Χαμηλότερες χρεώσεις κλήσεων χωρίς μεσάζοντες!</li></strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/solution/call-inmarsat-contact" color="primary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/hellasfon_shipping_companies_solution_01.jpg"
                alt="Λύσεις Hellasfon για ναυτιλιακές εταιρίες"
                title="Hellasfon λύσεις για ναυτιλιακές"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="../img/Ship_Solutions_Hellasfon.jpg"
                alt="Λυσεις για ναυτιλιακές απο την Hellasfon"
                title="Hellasfon - Λύσεις για Ναυτιλιακές Εταιρίες"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Κάλεσε INMARSAT με αστική κλήση</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                Κάλεσε INMARSAT καλώντας έναν ΤΟΠΙΚΟ αριθμό (αστικό) της περιοχής σας. <br /><p></p> 
				Κάθε κλήση πρός τον τοπικό αριθμό προωθείται στον INMARSAT αριθμό που επιθυμείτε.<br></br>		Απλή και εύκολη χρήση!<br></br>
				<br></br><br></br>
				Καλείτε τον τοπικό αριθμό και μιλήστε με το δορυφορικό τηλέφωνο ή όποιο άλλο αριθμό επιθυμείτε στις χαμηλότερες χρεώσεις της αγοράς.<br></br> <br></br>Εξοικονομήστε εως 75% σε όλες τις κλήσεις σας.
				<br /><p></p> <br></br>
                <Button href="/solution/call-inmarsat-contact" color="secondary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
			  <Button href="/didnumbers" color="warning">
                  ΔΙΑΘΕΣΙΜΟΙ ΑΡΙΘΜΟΙ
              </Button>
              </Typography>
			 
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>Διασύνδεση μέσω Cloud Τηλεφ. κέντρου</h2>
              <Typography variant="body1">
			    <li> Καταχωρήστε <b>ΤΟΠΙΚΟΥΣ Αριθμούς</b> οποιασδήποτε χώρας στο τηλεφωνικό κέντρο (PBX) σας και καλέστε τα πλοία σας καλώντας απλά εναν εσωτερικό αριθμό!</li>
                <li>
                  Υπηρεσίες φωνής Shore to Ship
                </li>
                <li> Καλέστε αριθμούς INMARSAT απλά πληκτρολογώντας έναν εσωτερικό αριθμό </li>
                <br></br>
				<strong>ΕΥΚΟΛΑ & ΑΠΟΔΟΤΙΚΑ </strong>
				<br></br>
				<h3><strong>Καλέστε Τοπικά - Μιλήστε ΔΙΕΘΝΗ! </strong></h3>
				<Button href="/solution/call-inmarsat-contact" color="primary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
			  <Button href="/didnumbers" color="secondary">
                ΔΙΑΘΕΣΙΜΟΙ ΑΡΙΘΜΟΙ
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/hellasfon_shore_to_ship_services.jpg"
                alt="shore to ship υπηρεσίες ομιλίας"
                title="Hellasfon - Shore to Ship υπηρεσίες φωνής"
              />
            </GridItem>
						
		  
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  
		  <AppSection />
		  </GridContainer>
		  
		  <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών σας.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

ShipPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(ShipPage);
