import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import GridContainer from "components/Grid/GridContainer.jsx";

import scrollIntoView from "scroll-into-view-if-needed";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import { checkEmail } from "actions/retailActions";

class AddressForm extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const {
      classes,
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      city,
    } = props;
    this.state = {
      company: company,
      vat: vat,
      firstName: firstName,
      lastName: lastName,
      email: email,
      emailHelperText: "",
      phonemobile: phonemobile,
      phone: phone,
      address: address,
      tk: tk,
      accept: accept,
      loading: false,
      classSnackbar: classes.hideSnackbar,
      response: "",
      city: city,
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phonemobile: false,
        phone: false,
        address: false,
        tk: false,
        accept: false,
        city: false,
      },
    };
  }
  onChange = (e) => {
    this.setState(
      e.target.id === "accept" || e.target.id === "changePhone"
        ? {
            [e.target.id]: e.target.checked,
          }
        : (e.target.value === "" && e.target.id === "firstName") ||
          (e.target.id === "firstName" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "lastName") ||
          (e.target.id === "lastName" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "city") ||
          (e.target.id === "city" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "address") ||
          (e.target.id === "address" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "tk") ||
          (e.target.id === "tk" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "phonemobile") ||
          (e.target.value.length < 9 && e.target.id === "phonemobile") ||
          (e.target.id === "phonemobile" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "phone") ||
          (e.target.value.length < 10 && e.target.id === "phone") ||
          (e.target.value.length > 10 && e.target.id === "phone") ||
          (e.target.id === "phone" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: false,
            },
          }
    );
  };
  onFocus = (e) => {
    this.setState(
      (e.target.value === "" && e.target.id === "firstName") ||
        (e.target.value.length < 3 && e.target.id === "firstName")
        ? {
            errors: {
              firstName: true,
            },
          }
        : (e.target.value === "" && e.target.id === "lastName") ||
          (e.target.value.length < 3 && e.target.id === "lastName")
        ? {
            errors: {
              lastName: true,
            },
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            errors: {
              email: true,
            },
          }
        : (e.target.value === "" && e.target.id === "phonemobile") ||
          (e.target.value.length < 9 && e.target.id === "phonemobile") ||
          (e.target.id === "phonemobile" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            errors: {
              phonemobile: true,
            },
          }
        : (e.target.value === "" && e.target.id === "phone") ||
          (e.target.value.length < 10 && e.target.id === "phone") ||
          (e.target.value.length > 10 && e.target.id === "phone") ||
          (e.target.id === "phone" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            errors: {
              phone: true,
            },
          }
        : e.target.value === "" && e.target.id === "address"
        ? {
            errors: {
              address: true,
            },
          }
        : e.target.value === "" && e.target.id === "tk"
        ? {
            errors: {
              tk: true,
            },
          }
        : e.target.value === "" && e.target.id === "city"
        ? {
            errors: {
              city: true,
            },
          }
        : e.target.value === "" && e.target.id === "accept"
        ? {
            errors: {
              accept: true,
            },
          }
        : {
            errors: {
              firstName: false,
              lastName: false,
              email: false,
              phonemobile: false,
              address: false,
              tk: false,
              accept: false,
              city: false,
            },
          }
    );
  };

  onClickAccept = () =>
    this.setState({
      accept: !this.state.accept,
    });
  onSubmit = async (e) => {
    e.preventDefault();

    const {
      changePhone,
      phonemove,
      local_area,
      verify_mobile,
      classes,
      aoy,
    } = this.props;

    const {
      company,
      vat,
      firstName,
      lastName,
      phonemobile,
      phone,
      email,
      address,
      loading,
      tk,
      accept,
      city,
    } = this.state;

    // Check For Errors
    if (lastName === "" || lastName.length < 3 || lastName.length > 100) {
      this.setState({
        errors: { lastName: true },
        classSnackbar: classes.errorSnackbar,
        response: "Απαιτείται επώνυμο",
      });
      const node = document.getElementById("lastName");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (firstName === "" || firstName.length < 3 || firstName.length > 100) {
      this.setState({
        errors: { firstName: true },
        classSnackbar: classes.errorSnackbar,
        response: "Απαιτείται Όνομα",
      });
      const node = document.getElementById("firstName");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (
      email === "" ||
      !email.match(/^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/) ||
      email.length > 100
    ) {
      this.setState({
        errors: { email: true },
        classSnackbar: classes.errorSnackbar,
        response: "Απαιτείται Email",
      });
      const node = document.getElementById("email");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (
      phonemobile === "" ||
      phonemobile.length < 9 ||
      !phonemobile.match(/^[0-9\b]+$/)
    ) {
      this.setState({
        errors: { phonemobile: true },
        classSnackbar: classes.errorSnackbar,
        response: "κινητό τηλέφωνο",
      });
      const node = document.getElementById("phonemobile");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (city === "" || city.length < 3 || city.length > 255) {
      this.setState({
        errors: { city: true },
        classSnackbar: classes.errorSnackbar,
        response: "Πόλη",
      });
      const node = document.getElementById("city");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (address === "" || address.length < 3 || address.length > 255) {
      this.setState({
        errors: { address: true },
        classSnackbar: classes.errorSnackbar,
        response: "διεύθυνση",
      });
      const node = document.getElementById("address");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (tk === "" || tk.length < 3 || tk.length > 10) {
      this.setState({
        errors: { tk: true },
        classSnackbar: classes.errorSnackbar,
        response: "Απαιτείται TK",
      });
      const node = document.getElementById("tk");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (accept === false) {
      this.setState({ errors: { accept: true } });
      const node = document.getElementById("accept");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (!loading) {
      this.setState({ loading: true });
      /*const res = await this.props.checkEmail(email);
      if (res) {
        // display error mesage
        this.setState({
          loading: false,
          classSnackbar: classes.errorSnackbar,
          response:
            "κάτι πήγε στραβά , προσπαθήστε ξανά ή επικοινωνήστε με την υποστήριξη.",
        });
      }*/
    }
    this.setState({ loading: false });
    //check the email if exists.
    if (Object.keys(this.props.check_email).length === 0) {
      this.setState({
        emailHelperText: "",
        errors: { email: false },
      });

      this.props.handleNext(
        company,
        vat,
        firstName,
        lastName,
        email,
        phonemobile,
        phone,
        address,
        tk,
        accept,
        changePhone,
        phonemove,
        verify_mobile,
        local_area,
        city,
        aoy
      );
    } else {
      this.setState({
        emailHelperText:
          "Η διεύθυνση email χρησιμοποιείται ήδη, παρακαλούμε αλλάξτε το email ή επικοινωνήστε με το support@hellasfon.com",
        errors: { email: true },
        classSnackbar: classes.errorSnackbar,
        response: "Η διεύθυνση ηλεκτρονικού ταχυδρομείου είναι εσφαλμένη.",
      });
      const node = document.getElementById("email");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
      return;
    }
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      phonemobile,
      phone,
      email,
      emailHelperText,
      address,
      tk,
      accept,
      errors,
      loading,
      classSnackbar,
      response,
      city,
    } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            Προσωπικά στοιχεία
          </Typography>
          <Grid container>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                error={errors.firstName ? true : false}
                required
                type="text"
                labelText="Ονομα *"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                error={errors.lastName ? true : false}
                required
                type="text"
                labelText="Επίθετο *"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                error={errors.email ? true : false}
                required
                type="email"
                labelText="Email *"
                id="email"
                name="email"
                value={email}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                helperText={emailHelperText}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                error={errors.phonemobile ? true : false}
                required
                type="text"
                labelText="Κινητό *"
                id="phonemobile"
                name="phonemobile"
                value={phonemobile}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                helperText="Συμπληρώστε τον αριθμό του κινητού, προσθέτοντας στην αρχή τον κωδικό χώρας π.χ 306988123412 ή 447578754922"
                formControlProps={{
                  fullWidth: true,
                }}
                /*inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+30</InputAdornment>
                  )
                }}*/
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                error={errors.phone ? true : false}
                type="text"
                labelText="Σταθερό"
                id="phone"
                name="phone"
                value={phone}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+30</InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                error={errors.address ? true : false}
                required
                type="text"
                labelText="Διεύθυνση *"
                id="address"
                name="address"
                value={address}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                error={errors.city ? true : false}
                required
                type="text"
                labelText="Πόλη *"
                id="city"
                name="city"
                value={city}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                error={errors.tk ? true : false}
                required
                type="text"
                labelText="Τ.Κ *"
                id="tk"
                name="tk"
                value={tk}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    required
                    id="accept"
                    name="accept"
                    disabled={loading}
                    checked={accept}
                    onClick={this.onClickAccept}
                    onChange={this.onChange}
                  />
                }
                label={
                  <Typography className={classes.labelCheckbox}>
                    Αποδοχή <a href="/eula"> Όρων Χρήσης </a>
                  </Typography>
                }
              />
            </GridItem>
          </Grid>
          <SnackbarContent
            id="response"
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
          <GridContainer className={classes.buttons}>
            <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
              <Button
                type="submit"
                color="primary"
                disabled={loading}
                variant="contained"
              >
                {"ΕΠΟΜΕΝΟ"}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </GridItem>
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}
AddressForm.propTypes = {
  classes: PropTypes.object,
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};

const mapStateToProps = (state) => ({
  check_email: state.retail_reducer.check_email,
  verify_mobile: state.retail_reducer.verify_mobile,
});

export default connect(
  mapStateToProps,
  { checkEmail }
)(withStyles(workStyle)(AddressForm));
