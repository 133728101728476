import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import Typography from "@material-ui/core/Typography";
import CardMedia from '@material-ui/core/CardMedia';
import ListItem from "@material-ui/core/ListItem";

import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
//import AppSection from "../Sections/AppSection.jsx";
import VisaSection from "../Sections/VisaSection.jsx";


class TelephoneCenterPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  
  render() {
    const { classes,  ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_Networks_Retail.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <h1>ΔΕΝ ΕΧΩ ΤΗΛΕΦΩΝΙΚΟ ΚΕΝΤΡΟ</h1>
                <h5>
                Solution for ΤΗΛΕΦΩΝΙΚΟ ΚΕΝΤΡΟ
                </h5>
                <br />
                <Button
                  color="primary"
                  size="lg"
                  href="#more"
                  rel="noopener noreferrer"
                >
                  περισσότερο
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            ΛΥΣΕΙΣ - ΔΕΝ ΕΧΩ ΤΗΛΕΦΩΝΙΚΟ ΚΕΝΤΡΟ
          </h1>
          <div id="more"  className={classes.container}>
          <div className={classes.section}> 
          <GridContainer justify="center">
          <ListItem>
            <Typography  variant="h3" color="primary">
            ΔΕΝ ΕΧΩ ΤΗΛΕΦΩΝΙΚΟ ΚΕΝΤΡΟ
            </Typography> 
          </ListItem>
            <GridItem cs={12} sm={12} md={6}>
                <ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                   Αποκτήστε τηλεφωνικό κέντρο με μηδενικό κόστος
Διασφαλίστε την υψηλή διαθεσιμότητα των υπηρεσιών τηλεφωνίας
Αναβαθμίστε την εικόνα της επιχείρησής σας
Δουλέψτε και από το σπίτι σας, σαν να ήσασταν στο γραφείο σας
Εξοικονομήστε πόρους εκμεταλλευόμενοι τις σημαντικά χαμηλότερες χρεώσεις
Εξυπηρετήστε περισσότερες κλήσεις γρηγορότερα
Μειώστε την πολυπλοκότητα των υποδομών σας
Αξιοποιήστε τις υπάρχουσες συνδέσεις Internet
Απολαύστε την ανώτερη ποιότητα ήχου
Καταργήστε τα πάγια πολλαπλών γραμμών τηλεφώνου
                  </Typography>
                </ListItem>
            </GridItem>
            <GridItem cs={12} sm={12} md={6}>
            <CardMedia
                component="img"
                alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                image="../img/parallax-hellasfon-22.jpg"
                title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
            />
              
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
  
              <Typography component="h4" variant="h4" color="primary">
              ΣΕΝΑΡΙΟ ΧΡΗΣΗΣ
              </Typography>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <Typography
                component="h5"
                variant="body1"
                color="textPrimary"
              >
                Στο σενάριο που αποτυπώνεται στο διπλανό διάγραμμα, παρουσιάζουμε μία τυπική εικόνα μικρής ή μεσαίας επιχείρησης και τον τρόπο από τον οποίο μπορεί να επωφεληθεί από τα προϊόντα και τις υπηρεσίες της HellasFon. Η επιχείρηση του παραδείγματος διατηρεί ένα υποκαταστήμα με λίγους εργαζόμενους και υπάρχει ανάγκη για επικοινωνία και από χώρους εκτός των γραφείων της. Χρησιμοποιώντας απλά και μόνο τις υπηρεσίες εικονικού τηλεφωνικού κέντρου της HellasFon, η επιχείρηση αποκτά όλη τη ζητούμενη λειτουργικότητα, χωρίς να διατηρεί εξοπλισμό τηλεφωνικού κέντρου στις υποδομές της.Με τη μετάβαση στις υπηρεσίες HellasFon επιτυγχάνονται μία σειρά στόχων σε κάθε έναν από τους παρακάτω τομείς.
              </Typography>
            </GridItem>
            <GridItem cs={12} sm={12} md={4}>
            <ListItem>
                  <Typography variant="h6" color="primary">
                  Οικονομία
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1" color="textPrimary">
                  Δεν χρειάζεται να αγοράσετε τηλεφωνικό κέντρο
Όλα τα τερματικά επικοινωνούν μεταξύ τους χωρίς χρέωση
Όλα τα τερματικά απολαμβάνουν τις ίδιες χαμηλές χρεώσεις προς εθνικούς και διεθνείς προορισμούς
Προαιρετικά, μπορεί να εκδίδεται ενοποιημένος λογαριασμός τηλεφωνίας για όλα τα σημεία παρουσίας
                  </Typography>
                </ListItem>
              
            </GridItem>
            <GridItem cs={12} sm={12} md={4}>
            <ListItem>
                  <Typography variant="h6" color="primary">
                  Ευελιξία
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography  variant="body1" color="textPrimary">
                  Οποιαδήποτε επέκταση ή μεταβολή πραγματοποιείται εντός 24 ωρών
Οι απαιτήσεις σε αναβάθμιση υποδομών είναι ελάχιστες ή μηδενικές!
Αλλάχτε μέσω του Internet μόνοι σας τις ρυθμίσεις εκτροπών και δείτε τις αλλαγές σας να εφαρμόζονται άμεσα
                  </Typography>
                </ListItem>
              
            </GridItem>
            <GridItem cs={12} sm={12} md={4}>
            <ListItem>
                  <Typography  variant="h6" color="primary">
                  Γεωγραφική Απελευθέρωση
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1" color="textPrimary">
                  Επικοινωνήστε με τους εξωτερικούς σας συνεργάτες μέσω του Internet χωρίς κόστος
Εντάξτε τηλε-εργαζόμενους στο τηλεφωνικό κέντρο, σαν να εργάζονται στο χώρο σας
                  </Typography>
                </ListItem>
              
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <Typography component="h4" variant="h4" color="primary">
              ΥΠΗΡΕΣΙΕΣ ΓΙΑ ΜΙΚΡΕΣ & ΜΕΣΑΙΕΣ ΕΠΙΧΕΙΡΗΣΕΙΣ
              </Typography>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
            <Button
              fullWidth
              color="primary"
              href="/cloudpbx"
              round
            >
              EVCOFFICE CLOUD PBX
            </Button>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
            <Button
              fullWidth
              color="rose"
              href="/siptrunk"
              round
            >
              Business line - SIP TRUNK
            </Button>
            </GridItem>
          </GridContainer>
         
      </div>
          
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150} 
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

TelephoneCenterPage.propTypes = {
  classes: PropTypes.object,
};


export default withStyles(landingPageStyle)(TelephoneCenterPage);