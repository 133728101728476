import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import Footer from "components/Footer/Footer.jsx";
import Typography from "@material-ui/core/Typography";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import AppSection from "./Sections/AppSectionBusiness.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";
import CircularProgress from "@material-ui/core/CircularProgress";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import { connect } from "react-redux";
import { checkOrder, loadingFn } from "actions/retailActions";


class ThankYou extends React.Component {

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { order_id } = this.props.match.params;
    this.props.loadingFn(true);
    if (order_id !== undefined) {
      this.props.loadingFn(true);
      await this.props.checkOrder(order_id);
    } 
  }
  render() {
    const { classes, loading, check_order, check_order_items, ...rest } = this.props;
    const { id_plan_pack, operation, pattern } = this.props.match.params;

    if (!check_order && !loading) {
       window.location = "/notFound";
    }

    return (
      <div>
        {loading? (<div style={{ 
        position: "fixed", 
        zIndex: "2000", 
        paddingTop: "100px",
        left: "0",
        top: "0",
        right: "0",
        bottom: "0",
        width: "100%", 
        height: "100%", 
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.4)"
        }} />
          ) : null}
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            {"Thank You"}
          </h1>
          <div className={classes.container} style={{ color: "#3c4858" }}>
          {loading ? ( 
              <GridContainer justify="center">
                <CircularProgress size={50} />
              </GridContainer>
            ) : pattern === "callcentre" ? (

              <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {operation === "banktransfer" ? (

                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                    Σας ευχαριστούμε που μας επιλέξατε.
                    </Typography>

                    <ListItem className={classes.listItem}>
                    <Typography variant="h6" className={classes.total}>ΚΩΔΙΚΟΣ ΠΑΡΑΓΓΕΛΙΑΣ:&nbsp;</Typography>
                      <Typography variant="h6" className={classes.total}>#</Typography>
                      <Typography variant="h6" id="transaction_id" className={classes.total}>
                        {check_order.order_id}
                      </Typography>
                    </ListItem>

                    <List disablePadding>
                    {check_order_items.map((item) => (
                      <React.Fragment>
                        <ListItem className={classes.listItem} key={item.id}>
                          <ListItemText
                            primary={item.name}
                          />
                          <Typography variant="h6">{
                            (item.plan_pack_id !== 0) ? (parseFloat(item.startup_cost) + parseFloat(item.period_cost)).toFixed(2) 
                            : (item.plan_pack_id === 0) ? item.startup_cost
                            : item.cost 
                          }</Typography>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                    <ListItem className={classes.listItem}>
                      <ListItemText primary="ΠΟΣΟ" />
                      <Typography variant="h5" id="currency" className={classes.total}>
                        EUR
                      </Typography>
                      <Typography variant="h5"className={classes.total}>&nbsp;</Typography>
                      <Typography variant="h5" id="value" className={classes.total}>
                        {check_order.total_cost}
                      </Typography>
                    </ListItem>
                    </List>

                    <Typography variant="subtitle1">
                      <ul>
                        <li>{id_plan_pack !== "128"
                            ? "Κατάσταση: Σε αναμονή εξόφλησης"
                            : "ΚΑΤΑΣΤΑΣΗ: ΔΩΡΕΑΝ"}</li>
                      </ul>
                    </Typography>

                    <Typography variant="h5" gutterBottom>
                    EUROBANK: IBAN: <strong>GR60 0260 2760 0002 5020 0715 959</strong><br />
                    ALPHA BANK: IBAN: <strong>GR09 0140 6640 6640 0233 0002 230</strong>
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Σημειώστε τον κωδικό παραγγελίας και το ονομά σας στο καταθετήριο.
                      Στείλτε την απόδειξη κατάθεσης στο <a href="mailto:billing@hellasfon.com">billing@hellasfon.com</a>
                      <br /><br /> ή με Fax: <a href="tel:2811300308">2811300308</a><br /><br /> 
                      <strong>Σας ευχαριστούμε για την παραγγελία και περιμένουμε την πληρωμή σας!</strong>
                      </Typography>
                  </React.Fragment> 

                  /*
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Σας ευχαριστούμε που μας επιλέξατε
                    </Typography>
                    <Typography variant="subtitle1">
                      <ul>
                        <li>
                          Κωδικός Παραγγελίας: <strong>#{order_id}</strong>
                        </li>
                        <li>Κατάσταση: Σε αναμονή εξόφλησης</li>
                        <li>
                          Ποσό:<strong>€ {Number(total).toFixed(2)}</strong>
                        </li>
                      </ul>
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      EUROBANK: IBAN:{" "}
                      <strong>GR60 0260 2760 0002 5020 0715 959</strong><br />
                      ALPHA BANK: IBAN:{" "}
					  <strong>GR09 0140 6640 6640 0233 0002 230</strong>
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Σημειώστε τον κωδικό παραγγελίας και το ονομά σας στο
                      καταθετήριο. Στείλτε την απόδειξη κατάθεσης στο{" "}
                      <a href="mailto:billing@hellasfon.com">
                        billing@hellasfon.com
                      </a>
                      <br />
                      <br /> η με Φαξ: <a href="tel:2811300308">2811300308</a>
                      <br />
                      <br />
                      <strong>
                        Σας ευχαριστούμε για την παραγγελία και περιμένουμε την
                        πληρωμή σας.
                      </strong>
                    </Typography>
                  </React.Fragment>
                */

                ) : (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                    Σας ευχαριστούμε που μας επιλέξατε.
                    </Typography>

                    <ListItem className={classes.listItem}>
                    <Typography variant="h6" className={classes.total}>ΚΩΔΙΚΟΣ ΠΑΡΑΓΓΕΛΙΑΣ:&nbsp;</Typography>
                      <Typography variant="h6" className={classes.total}>#</Typography>
                      <Typography variant="h6" id="transaction_id" className={classes.total}>
                        {check_order.order_id}
                      </Typography>
                    </ListItem>

                    <List disablePadding>
                    {check_order_items.map((item) => (
                      <React.Fragment>
                        <ListItem className={classes.listItem} key={item.id}>
                          <ListItemText
                            primary={item.name}
                          />
                          <Typography variant="h6">{
                            (item.plan_pack_id !== 0) ? (parseFloat(item.startup_cost) + parseFloat(item.period_cost)).toFixed(2) 
                            : (item.plan_pack_id === 0) ? item.startup_cost
                            : item.cost 
                          }</Typography>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                    <ListItem className={classes.listItem}>
                      <ListItemText primary="ΠΟΣΟ" />
                      <Typography variant="h5" id="currency" className={classes.total}>
                        EUR
                      </Typography>
                      <Typography variant="h5"className={classes.total}>&nbsp;</Typography>
                      <Typography variant="h5" id="value" className={classes.total}>
                        {check_order.total_cost}
                      </Typography>
                    </ListItem>
                    </List>
                    <Typography variant="subtitle1">
                      <br />
                      Σας έχουμε στείλει ένα email επιβεβαίωσης σχετικά 
                      με την παραγγελία σας. Θα λάβετε επιπλέον ενημέρωση
                      όταν η παραγγελία σας έχει αποσταλθεί.
                      <br />
                      <br />
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Για οποιεσδήποτε ερωτήσεις επικοινωνήστε μαζί μας{" "}
                      <a href="mailto:billing@hellasfon.com">
                        billing@hellasfon.com
                      </a>
                      <br />
                      <br /> τηλέφωνο: <a href="tel:2811300307">2811300307</a>
                      <br />
                    </Typography>

                  </React.Fragment>
                  /*
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Σας ευχαριστούμε που μας επιλέξατε
                    </Typography>
                    <Typography variant="subtitle1">
                      <ul>
                        <li>
                          Κωδικός Παραγγελίας: <strong>#{order_id}</strong>
                        </li>
                        <li>
                          {id_plan_pack !== "128"
                            ? "Κατάσταση: Σε αναμονή εξόφλησης"
                            : "ΚΑΤΑΣΤΑΣΗ: ΔΩΡΕΑΝ"}
                        </li>
                        <li>
                          Ποσό:<strong>€ {Number(total).toFixed(2)}</strong>
                        </li>
                      </ul>
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Ελέγξτε το email σας για τον σύνδεσμο επιβεβαίωσης.
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Για οποιεσδήποτε ερωτήσεις επικοινωνήστε μαζί μας{" "}
                      <a href="mailto:billing@hellasfon.com">
                        billing@hellasfon.com
                      </a>
                      <br />
                      <br /> τηλέφωνο: <a href="tel:2811300307">2811300307</a>
                      <br />
                      <br />
                      <strong>
                        {id_plan_pack !== 128
                          ? "Σας ευχαριστούμε για την παραγγελία και περιμένουμε την πληρωμή σας."
                          : null}
                      </strong>
                    </Typography>
                  </React.Fragment>
                  */

                )}
              </GridItem>
            </GridContainer>
              
            ) : (
              (window.location = "/notFound")
            )}
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

ThankYou.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => ({
  check_order: state.retail_reducer.check_order,
  check_order_items: state.retail_reducer.check_order_items,
  loading: state.retail_reducer.loading
});


export default connect(
  mapStateToProps,
  { checkOrder, loadingFn }
)(withStyles(landingPageStyle)(ThankYou));
