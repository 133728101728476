import React from "react";
import CardPricing from "components/CardPricing/CardPricing.jsx";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from "@material-ui/core/Grid";

import pricingStyle from "assets/jss/material-kit-react/views/landingPageSections/pricingStyle.jsx";

class PlansTable extends React.Component {
constructor(props) {
    super(props);
    this.state = {
        selected: false,
        expanded: false
    }
}
selectPanel = async () => {
    await this.setState({
        selected: !this.state.selected
    });
    this.props.selectPlan(this.props.plan.id_plan,  this.props.plan.name, this.props.plan.period_cost, this.state.selected);
}
handleExpandClick = () => {
    this.setState({
        expanded: !this.state.expanded
    }
    );
};
  render() {
    const { classes, plan, key, disabled } = this.props;
    const { expanded, selected } = this.state;
    return (
      <React.Fragment>
        <Grid item style={{marginBottom: "20px"}} key={key} xs={12} sm={12} md={4}>

            <CardPricing>
            <CardActionArea onClick={!disabled ? this.selectPanel : null} className={selected ? classes.header : classes.headerPlans}>
                <CardHeader
                title={plan.name}
                titleTypographyProps={{ align: "center" }}
                />
                <Divider />
                <CardContent>
                <div className={classes.content}>
                    <Typography component="h3" variant="h3" color="textPrimary">
                    € {plan.period_cost}*
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                    /ΜΗΝΑ
                    </Typography>
                </div>
                </CardContent>
                <CardContent>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <ul style={{ margin: "0", padding: "0" }}>
                            {plan.description.map(line =>
                            (
                                <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={line}
                                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                                >
                                {line}
                                <Divider />
                                </Typography>
                            )
                            )}
                        </ul>
                    </Collapse>
                </CardContent>
                </CardActionArea>
                <CardActions>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={expanded}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>

                </CardPricing>
                <Typography
                component="li"
                variant="caption"
                align="center"
                style={{
                  listStyle: "none",
                  padding: "5px 0 0 5px",
                  fontSize: "10px",
                  color: "black"
                }}
              >
                {
                  "* Οι τιμές συμπεριλαμβάνουν ΦΠΑ 24% και Τέλος Συνδρομητών Σταθερής Τηλεφωνίας."
                }
              </Typography>

        </Grid>


      </React.Fragment>
    );
  }
}
PlansTable.propTypes = {
  classes: PropTypes.object
};

export default withStyles(pricingStyle)(PlansTable);
