import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import ListItem from "@material-ui/core/ListItem";
import CookieConsent from "react-cookie-consent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";

class HomePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_B2B_services.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{paddingTop: '70px' }}>
               <h3>
                  <strong>HELLASFON - YOUR Telecom Business Solution Specialist</strong>
                </h3>
				<h5><b>B2B Turnkey Solutions</b> for your Business</h5>
				<ul>
                  
				  <li>Scalable & Affordable to fit ANY Budget </li> 
				  <li>Easy Implementation for EVERY Business Project </li> 
				  <li>No Commitment or Contracts</li>
				  <li><strong>Lowest Price Guaranteed !</strong></li>
				</ul>  
                               
				<h4 style={{ color: 'lime' }}><b> | <a href="/retail">Home Telephony</a> | <a href="/siptrunk">Business Telephony - Sip Trunks  </a>|  <a href="/cloudpbx">Cloud PBX  </a> | <a href="/iotsim">IoT </a> | </b></h4> 
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            Hellasfon Telephony
          </h1>
          <div>
            <div className={classes.container}>
              <GridContainer id="pricing">
                <ListItem>
                  <Typography
                    component="h4"
                    variant="h4"
                    color="primary"
                  ></Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                    Landline telephony is changing! <b>HELLASFON</b> is a pioneer
                    in these developments, offering flexible plans
                    for individuals and businesses with apps of latest generation.
                    We combine economic benefit of landlines and flexibility of mobiles
                    Landline number and telephony through your mobile. All
                    plans are <b>without commitment</b>, after your {" "}
                    <b>ONLINE registration</b> and <b>available same day!</b>
                  </Typography>
                </ListItem>
              </GridContainer>
            </div>
           
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>BUSINESS – EVCOFFICE Cloud PBX</h2>
                <Typography variant="body1">
                  <b>CLOUD</b> PBX - Latest Generation of PBX, ready to use -
                  same day of registering.
                  <li>Easy DIY Administration.</li>
                  <li>
                    Build in services for remote use using 
                    mobile or computer of each user.
                  </li>
                  <li>
                    <b>Free outbound lines </b> for each user
                    providing company availability up to 100% 
                  </li>
                  <li>  
					Get it today ONLINE by registering without commitments.
                  </li>
                </Typography>
                <br />
                <Button fullWidth href="/cloudpbx" color="primary">
                  See how 
                </Button>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  component="img"
                  image="img/coronavirus-hellasfon-06.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="img/coronavirus_PBX-01.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  Landline Numbers and SIP TRUNK
                </h2>
                <Typography variant="body1" style={{ color: "#fff" }}>
                  Just add telephony lines at your company, price starting from €9,50/month
                  including Unlimited calls to Landlines.
				  <br></br>
				  <br></br>
                  <li>
                    Lower communication fees and raise to the maximum servicing your customers 
                    with HELLASFON monthly plans.
                  </li>
                  <li>
                    Lower upto -30% your office monthly bill , guaranteed lowest price 
                    and no contract commitments.
                  </li>
                </Typography>
                <br />
                <Button fullWidth href="/siptrunk" color="rose">
                  See how
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>Carrier interconnection</h2>
                <Typography variant="body1">
                  Wholesale voice termination to other Telcos.
				  <br></br>
				  <br></br>
                  <li>
                    Hellasfon is a TIER-1 operator in Greece, offering direct CLI
                    routes throughout Europe and the rest of the world.
                  </li>
                  <li>
                    Direct routes for all Satellite destinations, offering
                    competitive rates on all networks.
				  </li>
				  <br></br>
                  <a href="/contact">CONTACT US</a> us with your request today!
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  style={{
                    paddingTop: 100,
                    paddingRight: 100,
                    paddingBottom: 100,
                  }}
                  component="img"
                  image="img/coronavirus_PBX-03.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="img/coronavirus-hellasfon-01.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  IOT – M2M – Travelsim mobile services 
                </h2>
                <Typography
                  variant="body1"
                  style={{
                    color: "#fff",
                  }}
                >
                  <li>
                    HELLAFON is the exclusive representative of {" "}
                    <a
                      className={classes.linkblue}
                      href="https://www.360roaming.com"
                    >
                      360ROAMING
                    </a>{" "}
                    in Greece.
                  </li>
				  <br></br>
                  <li>
                    <a
                      className={classes.linkblue}
                      href="https://360roaming.com/360travelsim/"
                    >
                      360Travelsim
                    </a>{" "}
                    - SIM ideal for business trips and leisure trips.
                    No Roaming fees in more than 200 countries.
                  </li>
				  <br></br>
                  <li>
                    <a
                      className={classes.linkblue}
                      href="https://360roaming.com/360iotsim/"
                    >
                      360IOTsim
                    </a>{" "}
                    - Multi Vendor SIM with global worldwide coverage.
                  </li>
                </Typography>
              </GridItem>
            </GridContainer>
          </div>
		  
		  <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>Hellasfon Home</h2>
                <Typography variant="body1" color="textPrimary">
				  <br></br><br></br>
                  Easy, fast, flexible and above all economical.<br></br>
				  <a className={classes.linkrose} href="https://www.hellasfon.com/reg/retail/191">REGISTER</a> and get your FIXED number at your mobile !{" "} 
				  <br></br>
				  <br></br>
                  Fixed number with no strings attached. Calling from <b>Hellasfon app</b> without additional charges or Roaming fees.
				  <br></br>
                  <br></br>				  
				  <br></br>
				  <br></br>
				  <Button fullWidth href="https://myaccount.easyclicksms.com/register" color="rose">
                  SEE HOW !
				  </Button>
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  style={{
                    paddingTop: 100,
                    paddingRight: 100,
                    paddingBottom: 20,
                  }}
                  component="img"
                  image="../img/coronavirus-hellasfon-03.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
			
			
		  
          <VisaSection />
          <Footer />
          <ScrollUpButton />
        </div>
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ i aggree"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies on this website to improve 
          user's experience.{" "}
          <span style={{ fontSize: "12px" }}>
            By clicking on any hyperlink at this webpage you give
            your consent to set up cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              For more information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(HomePage);
