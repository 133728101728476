import React from "react";
import YouTube from "react-youtube";
//import ReactPlayer from 'react-player';
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {Accordion,  AccordionSummary, AccordionDetails } from "@material-ui/core/";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})( AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

class FAQEVCOFFICE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "panel0",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(panel, expanded) {
    this.setState(
      expanded === panel ? { expanded: "panel0" } : { expanded: panel }
    );
  }

  render() {
    const { expanded } = this.state;
    const opts = {
      height: "390",
      width: "640",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
    return (
      <div>
        <h3 style={{ textAlign: "center", color: "#0178e2" }}>
          Read common Questions & Answers.
        </h3>
		<ExpansionPanel
          expanded={expanded === "panel1"}
          onChange={() => this.handleChange("panel1", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography>
              How can I become a subscriber and get <b>EVCOFFICE PBX ?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel1">
                With automatic registration,<b> EVCOFFICE Cloud PBX </b>is ready to use within
                a few minutes.
                <ol>
                  <li>
                    Choose the plan that suits you best, and number of users
                    in your company.
                  </li>
                  <li>Choose your phone number.</li>
                  <li>
                    For even greater economy, add additional talk time bundles
                    depending on your company needs.
                  </li>
                  <li>
                    * Talk time is shared AMONG ALL PBX Users
                    .
                  </li>
                  <li>
                    Upon completion you will receive an email with 
                    (username) and (password) for your EVCOFFICE Cloud PBX
                    .
                  </li>
                </ol>
                <p>
                  When you choose your Greek DID Number, you will receive
                  an email with all the necessary data needed in order to 
                  (identify?) this number for you. The number you have choosen is  
                  commited already, but remains inactive untill you send us 
                  all the necessary documents. You can send us a) by email
                  at support@hellasfon.com, b) by Fax - 2811300308 or 3) by
                  the EVCOFFICE application (get some photos of your documents
                  and send them as attachment by choosing Feedback menu
                  within the app).<br></br><b> When we receive your identification data,
                  your number is instanly activated! </b>
                </p>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
<ExpansionPanel
          expanded={expanded === "panel2"}
          onChange={() => this.handleChange("panel2", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography>
              How can i become a subscriber of <b>SIP Trunk?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel2">
                <ol>
                  <b>Hellasfon SIPTRUNK</b>
                  <li>
                    Choose your bundle , how many SIP TRUNK channels you desire ( 2, 10 or 30
                    channels) and the number you preffer.
                  </li>
                  <li>
                    After completing all details, you will receive an email with your NEW number, 
                    (username) and your 
                    (password).
                  </li>
                  <li>Sip Trunk is ready to use. </li>
                  <li>
                    By entering in your existing hardware all the settings that we have sent, 
                    you are ready to make and receive calls !
                  </li>
                </ol>
                For Identification your Number.
                <ul>
                  <li>
                    Pls send us an email with attached your ID (Front/Backside),
                    and a utility account (electricity,water,etc)
                    You can see analytical all documents needed for {" "}
                    <a href="/docs/GREEK-DID-REQUIREMENTS.pdf">
                      {" "}
                      ID Verification
                    </a>
                    .
                  </li>
                  <li>
                    For easier and shortier way pls send us your
                    identification data attached as files through FEEDBACK menu
                    of Hellasfon (HF) app.
                  </li>
                  <li>
                    Until the completion of your identification data, your choosen GREEK number 
                    will be reserved and will remain reserved for you.
                    
                  </li>
                  <li>
                    While waiting your identification data you can use normally 
                    your services. During this stage, Hellasfon will use 
                    temporary your existing number as callerid for your outbound calls
                    .
                  </li>
                  <li>
                    Upon process completion , your phone number will be  
                    activated at your account !
                  </li>
                </ul>
              </div>
            </Typography>
          </ExpansionPanelDetails>
       </ExpansionPanel>
<ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={() => this.handleChange("panel3", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel7d-header"
          >
            <Typography>
              How can i become a subscriber of <b>HELLASFON Business
              Line?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel3">
                <b>Hellasfon Business line</b>
                <ol>
                  <li>Fill the application form.</li>
                  <li>
                    Create your account , you will receive an email 
                    with your new phone number , your (username) and your
                     (password).
                  </li>
                  <li>
                    Download Hellasfon app (EVCOFFICE), and log-in
                    using your (username) and (password) 
                    we have send you.
                  </li>
                </ol>
                At the Office:
                <ol>
                  <li>
                    For using the service with a desktop phone device a {" "}
                    <a href="https://shop.hellasfon.com/">VOIP device</a> is needed.
                  </li>
                  <li>
                    All Hellasfon Business subscribers can use the app
                    (EVCOFFICE) app for all their services.
                  </li>
                  <li>
                    Devices bought by HellasFon store come pre-programmed and can be connected with 
                    2 simple steps : by connecting the device in ADSL/VDSL router. (
                    <a href="https://shop.hellasfon.com/"> See how </a>)
                  </li>
                </ol>
                Service is ready for use!
                <ul>
                  <li>
                    For identification and activation of your telephone number,
                    send us an e-mail with attached your ID ( front / backside)
                    and a utility bill. See more details about number identification 
                    here.
                  </li>
                  <li>
                    For easier and shorter use, take a photo of 
                    filled identification form and send us as attachments
                    from FEEDBACK menu of the app.
                  </li>
                  <li>
                    Μέχρι την ολοκλήρωση της ταυτοποίησης ο Ελληνικός αριθμός
                    που σας έχει εκχωρηθεί θα παραμείνει δεσμευμένος για εσάς.
                  </li>
                  <li>
                    While waiting for number identification process to be finished
                    you can use regular your service. At this stage Hellasfon will 
                    temporarily use your mobile number as callerid 
                    for your outbound calls.
                  </li>
                </ul>
                Upon completion of your identification, your number is activated 
                AUTOMATICALLY at your account.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={() => this.handleChange("panel4", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography>
              How can i <b>add / renew</b> my talk time ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel4">
              <b style={{color: 'red'}}>With your subscription bundle </b> – All talk time is renewed automatically.<br />
                Με την αυτόματη εγγραφή η υπηρεσία είναι διαθέσιμη για χρήση
                μέσα σε λίγα λεπτά.
                <ol>
                  <li>From <b>Account</b> menu choose <b> Renew (1)</b> - and then choose your preffered way of payment <b>(2)</b> <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice01.jpg")}
                      title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                  </li>
                 
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel5"}
          onChange={() => this.handleChange("panel5", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography>How can i <b>add / record</b> a pre-answer <b>voice message ?</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel5">
                <ol>
                  <li>From menu <b>My profile</b> choose <b>Greetings</b><br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice03.jpg")}
                      title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                  </li>
                  <li>Press <b>(+)</b> sign at down right corner.<br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice04.jpg")}
                      title="Hellasfon Κινητή, Σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                  </li>
				  <br></br>
                  <li>Give your Greeting a name a choose how you will create it .<br/><br/>
                    <ol>
                      <li>
                        <b>By File</b>. Choose your ready made Greeting from your files.<br/><br/>
                        <CardMedia
                          component="img"
                          alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                          image={require("../../assets/img/faq/faqevcoffice05.jpg")}
                          title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                        />
                      </li>
					  <br></br>
                      <li>
					    <b>RECORD.</b> Record a new Greeting!<br/><br/>
                        <CardMedia
                          component="img"
                          alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                          image={require("../../assets/img/faq/faqevcoffice06.jpg")}
                          title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                        />
                      </li>
					  <br></br>
                      <li>
                        <b>Text to speech</b> - converts anything you type in voice. Select preffered voice and language, and write down the text that you want to be heared.
						Choose convert and then save.<br/><br/>
                        <CardMedia
                          component="img"
                          alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                          image={require("../../assets/img/faq/faqevcoffice07.jpg")}
                          title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                        />
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel6"}
          onChange={() => this.handleChange("panel6", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography>
            How can i <b>add</b> more <b>new users ?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel6">
                <ol>
                  <li>From <b>Company</b> choose  <b>Users (arrow1)</b><br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice08.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                    <br/>
                  </li>
                  <li>Choose <b>‘+’</b> to add more users <b>(arrow2)</b> <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice09.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
					<br/>
					<br/>
                  </li>
                  <li>Fill in all fields and press <b>"Save" (arrow) </b><br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice10.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
					<br/>
					<br/>
                    <b>All users will receive an email with their LOGIN information, account details for using service by pc or EVCOFFICE app.
					For programming desktop phone devices : Check here</b>
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel7"}
          onChange={() => this.handleChange("panel7", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7d-content"
            id="panel7-header"
          >
            <Typography>
             How can i <b>add</b> a <b>(Voicemail?)</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel7">
              <ol>
                  <li>From <b>Company</b> menu press <b>Auto-attentened</b> submenu (arrow1)<br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice11.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                    <br/>
                  </li>
                  <li><b>‘+’</b> Create a scenario for incoming calls <br/></li>
                  <li>Fill all necessary fields , and save it . <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice12.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel8"}
          onChange={() => this.handleChange("panel8", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8d-content"
            id="panel8d-header"
          >
            <Typography>How can i <b>call for FREE</b> using Hellasfon ?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel8">
                Hellasfon is building the biggest network of Free Calls in 
                Greece.
                <p>
                  For first time in Greece, HELLASFON users can 
                  make calls, video chat , send messaged to other Hellasfon
                  users for FREE and FOR EVER!
                </p>
                <br /> Με την συνδρομή σας στην Hellasfon, κατεβάζετε την
                Hellasfon εφαρμογή. <br />Application synchronizes all mobile 
                contacts while recognize other HELLASFON users.
                
                <br />
                By using Hellasfon App you can call / receive calls , in and out of 
                office just like an exteιού σαν μια επέκταση της σταθερής συσκευής σας. Επίσης
                κάνετε δωρεάν chat, και video-κλήσεις με άλλους Hellasfon
                συνδρομητές, για πάντα.
                <br />
                By using your phone device ALL calls to other HELLASFON users are FREE
                for EVER !
                <br />
                Μπορείτε να λαμβάνετε και να πραγματοποιείτε κλήσεις
                χρησιμοποιώντας την Hellasfon εφαρμογή από το κινητό, το tablet
                και από τον υπολογιστή σας. Για να έχετε πρόσβαση σε όλες τις
                δωρεάν προηγμένες υπηρεσίες κάντε log in στο{" "}
                <a href="https://myaccount.hellasfon.com/HF">MYACCOUNT</a>.
                <br />
                <br />
                <p>
                  BUSINESS customers have UNLIMITED intracompany calls 
                  από το κινητό τους και την EVCOFFICE εφαρμογή ανεξαρτήτως
                  πάροχο κινητής τηλεφωνίας. Application is compatible with all 
                  cellurar networks worldwide η
                  ενδοεπικοινωνία σε όλους τους χρήστες. EVCOFFICE app
                  is ready to use and does not need any 
                  παραμετροποίηση. You call / receive calls 
                  δέχεσαι / κάνεις κλήσεις, στέλνεις μηνύματα, και VIDEO, από το
                  κινητό, την συσκευή του γραφείου, και τον υπολογιστή σου. Μια
                  ολοκληρωμένη λύση για κάθε επιχείρηση.
                </p>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel9"}
          onChange={() => this.handleChange("panel9", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9d-content"
            id="panel9d-header"
          >
            <Typography>
              How can i <b>modify my HELLASFON-EVCOFFICE Cloud PBX ?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel9">
                <YouTube
                  videoId="WNzR85Wcf6k"
                  opts={opts}
                  // https://github.com/tjallingt/react-youtube
                />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel10"}
          onChange={() => this.handleChange("panel10", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10d-content"
            id="panel9d-header"
          >
            <Typography>
              How can i <b>programm</b> my <b>Yealink</b> and <b>Grandstream</b> ip phones
              that i already have , to use them in my EVCOFFICE Cloud PBX ? 
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel10">
                <b>All devices sold by HELLASFON, are coming 
                pre-programmed and ready to use. You just plug them 
                to your router.</b><p></p> If you have your own voip phone devices 
                and you want to programm them(provisioning) for use in 
                EVCOFFICE Cloud PBX follow this guide.<br></br><br></br>
                <b>For Yealink ip phones :</b>
                
                <ol>
                  <li>
                    Go to <b>Webinterface</b> of your phone. At <b>Security</b> (arrow 1)
                    , <b>trusted certificates</b> (arrow 2), choose the following options:
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      height="800"
                      image={require("../../assets/img/faq/sec9a.jpg")}
                      title="Contemplative Reptile"
                    />
                  </li>
				  <br></br>
				  <br></br>
                  <li>
                    After this step go "settings" and "auto provision", filling the
                    "server url:
                    <strong>
                      " https://sip3.easyvoipcall.com:442/ps/
                    </strong>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      height="600"
                      image={require("../../assets/img/faq/auto9b.jpg")}
                      title="Contemplative Reptile"
                    />
                  </li>
                </ol>
                <b>For Grandstream ip phones:</b><br></br> From your pc , go to 
                <b>"Settings"</b> tab of your phone . Fill in PROVISIONING
                URL :{" "}
                <strong>https://sip3.easyvoipcall.com:442/ps/ </strong><br></br>
                Complete your <b>MAC ID</b> of your device and make a <b>restart.</b><br></br>
                Call <b>HELLASFON support</b> - Tel. <b>8012221001</b> or 
                Tel. <b>2811300307</b> , and give us your <b>MAC ID</b> for each device you have. 
				<br></br>Your phone will be programmed automatically within a few minutes and will be ready 
                to use!
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel11"}
          onChange={() => this.handleChange("panel11", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11d-content"
            id="panel8d-header"
          >
            <Typography>
              Can I <b>make calls beyond my plan </b>(free-minutes) with EVCOFFICE;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel11">
                (By registering ?) an EVCOFFICE Cloud PBX you have Unilimited Intracompany
                calls, chat and videocalls.
                <br />
                During registration process you can easilly add extra talk time bundles
                for even grater money savings <br /> or 
                ή να επιλέξτε χρονοχρέωση, χωρίς την αγορά πρόσθετου πακέτου. Choice is yours 
                and you can adjust your package according your needs <br />
                All plans are charged monthly το πρόγραμμά βάση των αναγκών σας. Τα συνδρομητικά πακέτα χρόνου
                ομιλίας χρεώνονται μηνιαίως. Για να κάνετε <br />
                κλήσεις πέρα του προγράμματος/πακέτου ομιλίας, θα πρέπει να
                έχετε προσθέσει μονάδες στον λογαριασμό σας. Οι μονάδες <br />
                δεν λήγουν ποτέ και είναι πάντα διαθέσιμες για δική σας χρήση.
                It is an innovative and smart way to keep control of your expenses <br />
                By using EVCOFFICE customer portal you can add 
                να προσθέσετε μονάδες για κλήσει εκτός πακέτου. <br />
                Οι μονάδες πιστώνονται αμέσως χωρίς να ψάχνεις σημεία πώλησης.
                Δεχόμαστε όλες τις πιστωτικές κάρτες και Paypal. <br />
                Πλέον μπορείτε να πραγματοποιήσετε κλήσεις εκτός προγράμματος,
                να εξοφλείτε τον λογαριασμό σας και να κάνετε χρήση <br />
                της σταθερής τηλεφωνίας από το κινητό σας, από οποιοδήποτε
                σημείο στον κόσμο , χωρίς επιπλέον χρεώσεις και περιαγωγή Roaming.{" "}
                <br />
                <br />
                <br />
                <b>Στην Hellasfon οι μονάδες σας δεν λήγουν ποτέ.</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel12"}
          onChange={() => this.handleChange("panel12", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12d-content"
            id="panel9d-header"
          >
            <Typography>
             <b> What is EVCOFFICE CLOUD PBX ?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel12">
                <b>EVCOFFICE Cloud PBX is the most advanced PBX in the market.
                Offers innovative services.</b><br></br> Replace any 
                traditional PBX , providing your company 
                employees availability, flexibility and extra features that contribute 
                in productivity increase and expenses decrease <br />
                EVCOFFICE Cloud PBX offers 100% availability to all users ,
                all calls without restrictions <br />
                All company users can have their own line and call at the
                same time with other colleagues without limiting availability
                of incoming calls <br />
                There are τισερχομένων κλήσεων. Διαθέτει ΔΩΡΕΑΝ εφαρμογές , για
                αδιάλειπτη επικοινωνία και μέγιστη οικονομία σε όλες της
                κλήσεις. <br />
                (X) We combine Landlines and Mobile Lines Ενσωματώνει την σταθερή και κινητή τηλεφωνία, με σκοπό την
                αύξηση της παραγωγικότητας, και τη δραστική μείωση των δαπανών σας.
                <br />
                Choose the plan that suits you best and immediately call and answer 
                through EVCOFFICE app, your pc, your mobile or any desktop phone in φαρμογή, τον
                υπολογιστή σας και από την συσκευή γραφείου. Εντός και εκτός
                γραφείου, από οπιοδήποτε
                <br />
                σημείο στον κόσμο, χωρίς πρόσθετες χρεώσεις. Διαθέτει σε όλους
                τους χρήστες VIDEO τηλεδιάσκεψη με απεριόριστες συνεδριάσεις
                <br />
                <ol>
                  <li>
                    EVCOFFICE user licences with all the advanced PBX features
                    EVCOFFICE by € 0,75/month/licence. CREATE your own PBX
                    the way you want it, fast , economical with all the users 
                    that you wish. ADD extra call time bundles for more economy 
                    or complete your registration by adding credits that never gets
                    expired and out of your monthly plan. Choice is yours and 
                    can be changed anytime!
                    
                  </li>
                  Αντικαθιστά οποιοδήποτε παραδοσιακό κέντρο, παρέχοντας στην
                  επιχείρηση σας επιπλέον δυνατότητες, την μείωση των δαπανών
                  και την αύξηση της παραγωγικότητας.
                  <br />
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel13"}
          onChange={() => this.handleChange("panel13", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13d-content"
            id="panel10d-header"
          >
            <Typography>
              How can I <b>programm (provisioning)</b> all IP phones 
              that i have ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel13">
                All devices sold by Hellasfon, come pre-programmed and 
                ready to use. You just connect them to your router.
                If you have already your own VOIP phones and you wish 
                to programm them (provisioning) for EVCOFFICE Cloud PBX,
                you can call HellasFon support and we can help you on this.
                Process requires a few minutes.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel14"}
          onChange={() => this.handleChange("panel14", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14d-content"
            id="panel14d-header"
          >
            <Typography>
              I <b>dont have Internet</b> access or Wi-Fi singal available , how can i <b>call somebody</b> from my mobile 
              using my Hellasfon account ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel14">
                Don't worry Hellasfon has the solution for you !
                <ol>
                  <li>
                    Even if you DONT have Internet Access or WiFi signal 
                    you can still make a call from your mobile by using 
                    Hellasfon's <b>Enable Call Connection</b> service.
                    
					(Your call takes place using Call Connection service by
					contacting through Automate Attendant.)
                    
                    Application is making a unanswered call to our nodes by your cellurar phone.
                    Right away , you will receive a call by Automate Assistance to connect you directly
                    with the number you desire to connect to .{" "}
                  </li>
                </ol>
                To use Call Connection service , all you must have an 
                acive mobile phone number. <br />
                <br />
                By using Call Connection, there are NO CHARGES to your mobile.
                WE call you at your mobile and WE connect you with your desired number!
                .
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel15"}
          onChange={() => this.handleChange("panel15", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15d-content"
            id="panel15d-header"
          >
            <Typography>
              How can i <b>transfer</b> my <b>existing phone number to Hellasfon ?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel15">
                Transfering your number at Hellasfon is easy.
                Download and fill all the details in {" "}
                <a href="/docs/hellasfon-foritotita-gr.pdf">
                  portability form
                </a>{" "}
                . Follow this {" "}
                <a href="/docs/hellasfon-foritotita-gr-1.jpg">guide</a>. Send us
                the filled form including your identification data 
                by e-mail at {" "}
                <a href="mailto:support@hellasfon.com">
                  support@hellasfon.com, or by Fax 2811300308.
                </a>
                For greater and easier use, take a photo of filled form 
                including your id , and send us as attachments from 
                feedback menu , in HELLASFON app.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FAQEVCOFFICE.propTypes = {
  classes: PropTypes.object,
};

export default FAQEVCOFFICE;
