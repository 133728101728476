import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import Typography from "@material-ui/core/Typography";
import CardMedia from '@material-ui/core/CardMedia';
import ListItem from "@material-ui/core/ListItem";

import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
//import AppSection from "../Sections/AppSection.jsx";
import VisaSection from "../Sections/VisaSection.jsx";


class VideoConfPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  
  render() {
    const { classes,  ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_Networks_Retail.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={9}>
                <h1>Η HELLASFON ανεβάζει τον πήχη στην τηλεδιάσκεψη.<br/><strong>( Φωνή, Βίντεο & Messenger )</strong></h1>
                <h5>
                Solution: Επιχειρήσεις με ανάγκες τηλεδιάσκεψης
                </h5>
                <br />
                <Button
                  color="primary"
                  size="lg"
                  href="#more"
                  rel="noopener noreferrer"
                >
                  περισσότερο
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            ΛΥΣΕΙΣ - Τηλεδιάσκεψη / κανάλια συνεργασίας
          </h1>
          <div id="more"  className={classes.container}>
          <div className={classes.section}> 
          <GridContainer justify="center">
          <ListItem>
            <Typography  variant="h3" color="primary">
            Επιχειρήσεις με ανάγκες τηλεδιάσκεψης
            </Typography> 
          </ListItem>
            <GridItem cs={12} sm={12} md={6}>
                <ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                   <ul>
        <li>Αποκτήστε EVCOFFICE τηλεφωνικό κέντρο με μηδενικό κόστος</li>
<li>Διασφαλίστε 100% διαθεσιμότητα των υπηρεσιών τηλεφωνίας και μια γραμμή για κάθε χρήστη.</li>
<li>Αναβαθμίστε την εικόνα της επιχείρησής σας</li>
<li>Κάθε EVCOFFICE τηλεφωνικό κέντρο προσφέρει απεριόριστα κάναλια συνεργασίας/τηλεδιάσκεψη σε κάθε χρήστη.</li>
<li>Αυξήστε την παραγωγηκότητα στην επειχήρηση.</li>
<li>Δημιουργήστε κανάλια συνεργασίας για κάθε έργο/εργασία.</li>
<li>Καλέστε εξωτερικούς συνεργάτες στο κανάλι και αυξηστε την εποικινωνία </li>
<li>Πάρε το γραφείο μαζι σου με την EVCOFFICE εφαρμογή.</li>
<li>Μην αφήσετε καμία ευκαιρία/κλήση να πάει χαμένη.</li>
<li>Εξοικονομήστε πόρους εκμεταλλευόμενοι τις σημαντικά χαμηλότερες χρεώσεις</li>
<li>Απολαύστε την ανώτερη ποιότητα ήχου</li>
<li>Καταργήστε τα πάγια πολλαπλών γραμμών τηλεφώνου</li>
</ul>
                  </Typography>
                </ListItem>
            </GridItem>
            <GridItem cs={12} sm={12} md={6}>
            <CardMedia
                component="img"
                alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                image="../img/parallax-hellasfon-22.jpg"
                title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
            />
              
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
  
              <Typography component="h4" variant="h4" color="primary">
              ΣΕΝΑΡΙΟ ΧΡΗΣΗΣ
              </Typography>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <Typography
                component="h5"
                variant="body1"
                color="textPrimary"
              >Ολοι οι χρήστες εχουν πρόσβαση στην πύλη πελατών όπου μπορούν να δημιουργήσουν δικά τους κανάλια συνεργασίας / τηλεδιάσκεψης.
               Στο σενάριο που αποτυπώνεται ανοιγουμε ένα κανάλι συνεργασίας για κάθε έργο. 
               Εισάγωντας το email εξωτερικών συνεργατών, το τηλεφωνικό κέντρο τους στέλνει πληροφορίες συμμετοχής στην διάσκεψη / κανάλι συνεργασίας.
               Από την πύλη, μπορείτε να συμμετέχετε στην δίασκεψη με Βίντεο, chat και φωνή.
	       Κρατάει το ιστορικό μέχρι την ολοκλήρωση του έργου και δέν κλείνει μέχρι να την ακυρώσετε. 	      
	</Typography>
            </GridItem>
            <GridItem cs={12} sm={12} md={4}>
            <ListItem>
                  <Typography variant="h6" color="primary">
                  Οικονομία
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1" color="textPrimary">
                  <ul>
        <li>Χωρίς έξοδα για συσκευές και εξοπλισμό τηλεφωνικού κέντρου. Κατεβάστε την δωρεάν EVCoffice εφαρμογή και πάρε το γραφείο στο κινητό σου. Απάντησε/κάλεσε, chat, sms, conference, όλα από το κινητό σου.</li>
<li>Οι επιτραπέζιες συσκευές υποστηρίζονται σε όλα τα τηλ. κέντρα αλλά δεν είναι απαραίτητες. Όλες οι υπηρεσίες του κέντρου υποστηρίζονται από το web portal και την EVCoffice εφαρμογή.</li>
<li>Όλοι οι χρήστες επικοινωνούν μεταξύ τους δωρεάν.</li>
<li>Η υπηρεσία τηλεδιάσκεψης/κανάλια συνεργασίας, περιλαμβάνεται σε όλα τα τηλεφωνικά κέντρα EVCoffice.</li> 
<li>Κάνοντας χρήση της υπηρεσίας, μπορείτε να συνομιλείτε/βίντεο/Chat ταυτόχρονα με τρείς ή και περισσότερους συνεργάτες και πελάτες σας.</li> 
<li>Δεν απαιτείται η αγορά τηλεφωνικού αριθμού για την τηλεδιάσκεψη.</li>
<li>Εμείς έχουμε φροντίσει και σας παρέχουμε ΔΩΡΕΑΝ αριθμό για την χρήση σας.</li>
</ul>
                  </Typography>
                </ListItem>
              
            </GridItem>
            <GridItem cs={12} sm={12} md={4}>
            <ListItem>
                  <Typography variant="h6" color="primary">
                  Ευελιξία
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography  variant="body1" color="textPrimary">
        Πρόσθεστε εξωτερικούς συνεργάτες/πελάτες στο κανάλι συνεργασίας/τηλεδίασκεψη, με ένα Email.
	Ανοίγοντας ένα κανάλι συνεργασίας ( τηλεδιάσκεψη ), προσθέτεις τους χρήστες που θα συμμετέχουν στην διάσκεψη και πιθανόν εξωτερικούς συνεργάτες. 
	<li>Οι χρήστες του κέντρου μπορούν να  συμμετέχουν στην διάσκεψη:</li>
    <ol>
        <li>από την επιτραπέζια συσκευή του γραφείου τους,</li>
        <li>από την EVCoffice εφαρμογή, και </li>
        <li>από τον υπολογιστή ( MY ACCOUNT - Πύλη Πελατών ). </li>
    </ol>
Οι συμετέχοντες θα λάβουν ένα Email με τον κωδικό για συμμετοχή τους στην διάσκεψη, ένα αριθμό πρόσβασης, και ένα σύνδεσμο(link), για συμμέτοχή μέσω WebRTC και τον υπολογιστή.
<li>Οι εξωτερικοί συνεργάτες, συμμετέχουν:</li>
<ol>
        <li> Από το τηλέφωνο: καλώντας τον αριθμό τηλεφωνικής πρόσβασης, και εισάγοντας τον κωδικό που έχουν λάβει.</li>
        <li>	Από τον υπολογιστή: Ακολουθώντας τον σύνδεσμο (link) που τους έχει σταλεί, έχουν πρόσβαση στο κανάλι συνεργασίας, και μπορούν να συμμετέχουν στην διάσκεψη με φωνή, βίντεο και chat.</li>
    </ol>

                  </Typography>
                </ListItem>
              
            </GridItem>
            <GridItem cs={12} sm={12} md={4}>
            <ListItem>
                  <Typography  variant="h6" color="primary">
                  Παραγωγικότητα
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1" color="textPrimary">
Αυξήσετε την παραγωγικότητα στην επιχείρηση, χρησιμοποιώντας τα κανάλια συνεργασίας/Τηλεδιάσκεψη για όλα τα έργα(projects) της εταιρίας.
                  <li>Δυνατότητες πρόσβασης στην τηλεδιάσκεψη/καναλι συνεργασίας:</li>
	<ul>
        <li>Βίντεο τηλεδιάσκεψης.</li>
	<li>Φωνητική Τηλεδιάσκεψη</li>
	<li>Chat και μηνύματα τηλεδιάσκεψης.</li>
    </ul>
Άλλες δυνατότητες τηλεδιάσκεψης:
<ul>
<li>Μοιραστείτε αρχεία , φωτογραφίες, την τοποθεσία σας, με τους συμμετέχοντες της διάσκεψης.</li>
<li>Κάθε κανάλι κρατάει το ιστορικό του, και δεν κλείνει μέχρι να το κλείσετε εσείς.</li>
<li>Ανοίξτε ένα κανάλι συνεργασίας για κάθε έργο(project) που έχετε και ακολουθήστε την πορεία του, από το ιστορικό του καναλιού.</li>
</ul>
                  </Typography>
                </ListItem>
              
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <Typography component="h4" variant="h4" color="primary">
              ΥΠΗΡΕΣΙΕΣ ΓΙΑ ΕΠΙΧΕΙΡΗΣΕΙΣ ΜΕ ΑΝΑΓΚΕΣ ΤΗΛΕΔΙΑΣΚΕΨΗΣ
              </Typography>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
            <Button
              fullWidth
              color="primary"
              href="/cloudpbx"
              round
            >
              EVCOFFICE CLOUD PBX
            </Button>
            </GridItem>
          </GridContainer>
         
      </div>
          
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150} 
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

VideoConfPage.propTypes = {
  classes: PropTypes.object
};


export default withStyles(landingPageStyle)(VideoConfPage);