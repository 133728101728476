import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.jsx";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";
import TuneIcon from "@material-ui/icons/Tune";

import DialerSipIcon from '@material-ui/icons/DialerSip';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import ContactsIcon from '@material-ui/icons/Contacts';
import PanToolIcon from '@material-ui/icons/PanTool';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import BuildIcon from '@material-ui/icons/Build';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PeopleIcon from '@material-ui/icons/People';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import PublicIcon from '@material-ui/icons/Public';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import FaceIcon from '@material-ui/icons/Face';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import CardContent from "@material-ui/core/CardContent";


class Features extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            {"Χαρακτηριστικά EVCOFFICE Τηλεφωνικού Κέντρου"}
          </h2>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}> 
                <Card style={{ height: 330 }}> 
                  <CardContent style={{ textAlign: "center" }}>
                    <FaceIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Φιλικό στον χρήστη
                    </Typography>
					
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Διαχείρηση Κλήσεων με Online παραμετροποίηση.
                      <br />
                      <br />
                      Με το πάτημα ενός κουμπιού σε ένα όμορφο,γραφικό περιβάλλον ρυθμίστε τα πάντα στο τηλ.κέντρο σας.Εύκολα!
					  </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <AllInclusiveIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Απεριόριστα Σενάρια IVR
                    </Typography>
                    
				    <Button href="/faqoffice#ivr" color="primary">
					    ΔΕΙΤΕ ΣΧΕΤΙΚΑ
					 </Button> 
					<br />
                    <Typography variant="body1" color="textSecondary">
                      Ρυθμίστε το κέντρο σας με πολλαπλά μενού επιλογών που θα ακούνε οι πελάτες σας.
                      <br />
                      <br />
                      Βάση ώρας, προς μια ομάδα χρηστών ή σε μεμονωμένους χρήστες σύμφωνα με τις ανάγκες σας.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <AccountTreeIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Υποκαταστήματα
				                     
                    </Typography>
						<br />
                    
                      <Typography variant="body1" color="textSecondary">
                        Δημιουργήστε ξεχωριστά προθέματα για να καλέσετε τους συνάδελφους σας απο άλλα υποκαταστήματα.
                        <br />
						<br />
                        Τώρα η διασύνδεση υποκαταστημάτων είναι μια εύκολη υπόθεση!
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <DialerSipIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Υποστήριξη κάθε IP Συσκευής
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Υποστήριξη όλων των τύπων IP Συσκευών γραφείου.
                      <br />
                      <br />
                      Grandstream,Yealink,Snom,Fanvil κλπ.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PhoneAndroidIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Ενοποίηση με το κινητό 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Το EVCOFFICE περιέχει μια ενοποιημένη εφαρμογή για το κινητό σας (Android ή IOS)
                      <br />
                      <br />
                      Πάρτε μαζί σας το εσωτερικό του γραφείο σας όπου και αν βρίσκεστε!
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <VoicemailIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Φωνητικό Ταχυδρομείο 
                    </Typography>
                    <br />
                
                    <Typography variant="body1" color="textSecondary">
                        Ακούστε τα μηνύματα του τηλεφωνητή σας απο οποιοδήποτε εσωτερικό και αν βρίσκεστε.
						<br />
                      <br />
                      Απλά πληκτρολογήστε τον ειδικό αριθμό κλήσης και το pin σας.
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <AccountBoxIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Εύκολη Κεντρική διαχείρηση 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Διαχειριστείτε εύκολα το τηλεφωνικό σας κέντρο.
                      <br />
                      <br />
                      Προσθέστε χρήστες/γραμμές & αλλάξτε τον τρόπο λειτουργίας του με ένα εύχρηστο περιβάλλον.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <TuneIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Κανάλια Συνεργασίας
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      
                      <br />
                      Κάντε Chat με εως 100 συμμετέχοντες !
					  
                      <br />
                      <br />
					  Όι συνομιλίες Chat γίνονται σε ένα ασφαλές περιβάλλον.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <QuestionAnswerIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Messenger
				                     
                    </Typography>
						<br />
                    
                      <Typography variant="body1" color="textSecondary">
                        Δωρεάν βιντεοκλήσεις με τους χρήστες που επιθυμείτε. 
                        <br />
						<br />
                        Ανταλλαγή εγγράφων, φωτογραφιών, τοποθεσιών καθώς και επαφών.
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <ContactsIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Επαφές Κινητού 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Συγχρονίστε τις επαφές του κινητού σας με το
					  τηλεφωνικό σας κέντρο. 
                      <br />
                      <br />
                      Δεν χρειάζετε να καταχωρήσετε τον τηλεφωνικό κατάλογο σας ξανά.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <CameraFrontIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Caller ID
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Ρυθμίστε εύκολα τον αριθμό που θα εμφανίζετε στις εξερχόμενες κλήσεις σας. 
                      <br />
                      <br />
                      Μπορεί να είναι ο αριθμός του κινητού σας, ένας τηλ.αριθμός που έχετε ήδη ή το κεφαλικό νούμερο της εταίρείας.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PanToolIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Κανόνες Απάντησης
                    </Typography>
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Δυνατότητες : Follow me & Do not Distrurb
                          <br />
						  <br />
                          Μεταφέρετε τις κλήσεις σας στον τηλεφωνητή σας ή στείλτε τις κλήσεις σας σε κάποιο συνάδελφο που είναι διαθέσιμος.
                      </Typography>
                   
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		  		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <QueryBuilderIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Time Conditions
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Ρυθμίστε πως θα δρομολογούνται οι εισερχόμενες κλήσεις σας σε συγκεκριμένες ώρες ή ημέρες.
                      <br />
                      <br />
                      π.χ. Μετά τις 17.00 όλες οι κλήσεις να δρομολογούνται στον τηλεφωνητή.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <BuildIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Provisioning
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Ρυθμίστε τις IP Τηλεφωνικές συσκευές σας απευθείας μέσω του κέντρου σας.
                      <br />
                      <br />
                      Ορίστε softkeys, speedials ή και άλλα εσωτερικά στην ίδια συσκευή.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <InsertCommentIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Greetings
                    </Typography>
					<Button href="/faqoffice#greetings" color="primary">
					    ΔΕΙΤΕ ΣΧΕΤΙΚΑ
					 </Button> 
                    <br />
                    
                      <Typography variant="body1" color="textSecondary">
                       Ηχογραφήστε τους χαιρετισμούς ή ανεβάστε το αρχείο ήχου που θα ακούγεται στους πελάτες σας.					   .
                         <br />
                      <br />
                      Δεν υπάρχει περιορισμός πόσα ηχητικά μηνύματα προαπάντησης θα έχετε !
                      <br />
                      <br />
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		  
		  		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PeopleIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Ομάδες χρηστών 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Στείλτε τις κλήσεις σας σε μια ομάδα χρηστών ταυτόχρονα ή με σειρά.
                      <br />
                      <br />
                      Π.χ. Ομάδα Εξυπηρέτησης πελατών, Πωλήσεων, Τεχνική υποστήριξη κτλ.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <BrightnessAutoIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Αυτόματη Υποδοχή
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Δημιουργήστε απεριόριστες Αυτόματες Προαπαντήσεις για το PBX σας. 
                      <br />
                      <br />
                      Χειριστείτε τις κλήσεις σας επαγγελματικά και με ευελιξία. 
					  Δημιουργήστε όσα σενάρια επιθυμείτε!
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <SettingsVoiceIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Ηχογράφηση Κλήσεων
                    </Typography>
                    
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Ηχογραφήστε τις εισερχόμενες και εξερχόμενες κλήσεις απο και προς τους πελάτες σας.
						<br />
                        <br />
						Ακούστε τις απο το web ή κατεβάστε το mp3 αρχείο ήχου.
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PublicIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Web RTC 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Κάντε κλήσεις απο τον Η/Υ σας χρησιμοποιώντας το ενσωματωμένο τηλέφωνο που υπάρχει σε κάθε λογαριασμό.
					  
                      <br />
                      <br />
                      Το μόνο που χρειάζεστε είναι ακουστικά και ένα μικρόφωνο.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <DoneAllIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Πολλαπλή Χρήση
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Πλέον οι κλήσεις σας δεν χάνονται. Τις λαμβάνετε στην σταθερή IP συσκευή σας , στο κινητό σας αλλά στον Η/Υ σας.
                      <br />
                      <br />
                      Ολες οι κλήσεις και το chat συγχρονίζεται αυτόματα σε όλες τις συσκευές σας.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <SettingsPhoneIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Τηλεφωνικοί Αριθμοί
                    </Typography>
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Με κάθε πρόγραμμα EVCOFFICE σας παρέχουμε <b>ΔΩΡΕΑΝ</b> ένα Ελληνικό Τηλεφωνικό Αριθμό.
                          <br />
						  <br />
                          Αν επιθυμείτε πρόσθετους Ελληνικούς αριθμούς αυτοί στοιχίζουν μόνο 2 €/μήνα.
                      </Typography>
                   
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <GroupAddIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Δωμάτια Διασκέψεων 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Διοργανώστε εύκολα τηλεφωνικά meetings με τους συναδέλφους σας.
                      <br />
                      <br />
                      Αυξήστε την παραγωγικότητα της εταιρίας χωρίς να χρειάζεται να ταξιδέψετε ή να απομακρυνθείτε απο το γραφείο σας.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <InsertDriveFileIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Fax 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Αποστείλτε και λάβετε fax χρησιμοποιώντας την πύλη Πελατών MY ACCOUNT.
                      <br />
                      <br />
                      Υποστηρίζουμε επίσης τις ακόλουθες υπηρεσίες : email-to-fax and fax-to-email.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <LibraryMusicIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Custom Music on Hold
                    </Typography>
                    
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Ανεβάστε την αγαπημένη σας μουσική που θέλετε να ακούν οι πελάτες σας κατά την διάρκεια της αναμονής απάντησης.
						<br />
                        <br />
						Η μουσική αναμονής μπορεί να οριστεί ξεχωριστά και για κάθε χρήστη!
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <HomeWorkIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
															
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Εργασία απο το σπίτι 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Δεχτείτε & Κάντε κλήσεις χρησιμοποιώντας τον εσωτερικό αριθμό σας όπου και αν βρίσκεστε.
					  
                      <br />
                      <br />
                      Η τηλεργασία είναι πραγματικά εύκολη υπόθεση!
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <MarkunreadMailboxIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Voicemail to Email
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Εκτός της δυνατότητας τηλεφωνητή για κάθε εσωτερικό αριθμό σας, με αυτό το χαρακτηριστικό θα λάβετε τα μηνύματα που σας άφησαν στο email σας.
                      <br />
                      <br />
                      Θα λάβετε ένα email με ένα αρχείο ήχου mp3.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <RecordVoiceOverIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      TTS (Text to Speech)
                    </Typography>
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Σας παρέχουμε την δυνατότητα κατασκευής των ηχητικών μηνυμάτων σας στην Αγγλική Γλώσσα.
                          <br />
						  <br />
                          Αν η επιχείρηση σας απευθείνετε σε πελάτες εξωτερικού, θα βρείτε αυτή την δυνατότητα πολύ χρήσιμη!
                      </Typography>
                   
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
          <div className={classes.container}>
		  <div class="container">
				
		</div>
          
		  <GridItem cs={12} sm={12} md={12} style={{ padding: 0 }}>
              <h2 style={{ color: "#005FB4" }}> </h2>
              <Typography variant="body1" align="center" style={{ color: "#005FB4" }}>
                
				<br></br>
				<strong>Η Hellasfon σας προσφέρει τώρα ΔΩΡΕΑΝ την ρύθμιση & προγραμματισμό του Τηλεφωνικού κέντρου EVCOFFICE που θα επιλέξετε ! Εσείς απλά ενημερώστε μας με το σενάριο χρήσης που επιθυμείτε και εμείς θα το προγραμματίσουμε βάση των αναγκών σας παρέχοντας : 
				<p></p>
				<li>HD Ποιότητα ήχου</li>
				<li>Χαμηλότερες χρεώσεις κλήσεων χωρίς μεσάζοντες!</li></strong>
				<br /><p></p> 
				
				<Button
					color="primary"
					size="lg"
					href="../cloudpbx#pricing"
					
				>
				Δείτε όλα τα πακέτα Τηλεφωνικού κέντρου EVCOFFICE
				</Button>
              </Typography>
			  
            </GridItem>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

Features.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Features);
