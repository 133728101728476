import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusiness.jsx";

import CookieConsent from "react-cookie-consent";

class CallCenterPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Λύσεις για Call Centers"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
		  
		  
		  
            <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
              <h2>Διασύνδεση μέσω Sip Trunk</h2>
              <Typography variant="body1">
                <li>Απεριόριστη διαθεσιμότητα (κανάλια φωνής)</li>
				<li>Υψηλή Συνδεσιμότητα</li>
				<li> HD Ποιότητα φωνής</li>
				<li> Υψηλό ποσοστό επιτυχίας κλήσης  </li>
				<li> Υποστηρίζονται όλα τα τηλεφωνικά κέντρα </li>
				<br></br>
				<strong>Η Hellasfon σας παρέχει :  
				<li>HD Ποιότητα ήχου</li>
				<li>Χαμηλότερες χρεώσεις κλήσεων χωρίς μεσάζοντες!</li></strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/contact" color="primary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/callcenter.jpg"
                alt="Λύσεις Hellasfon για call centers"
                title="Hellasfon λύσεις για τηλεφωνικά κέντρα και call centers"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="../img/call_center.jpg"
                alt="Λυσεις για Call Center"
                title="Hellasfon - Λύσεις για Call Centers "
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Διασύνδεση μέσω Τηλεφωνικού Κέντρου</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                Ξεχάστε την Αγορά/Εγκατάσταση/Παραμετροποίηση πανάκριβου Τηλεφωνικού Κέντρου <br></br>καθώς και το αυξημένο μηνιαίο κόστος λειτουργίας του. <br /><p></p> 
				<li> Καταχωρήστε όσους <b>ΤΟΠΙΚΟΥΣ Αριθμούς ή ΑΛΛΗΣ χώρας</b> επιθυμείτε είτε για να δέχεστε <b>Eισερχόμενες κλήσεις</b> είτε για να τρέξετε καμπάνιες με <b>Eξερχόμενες κλήσεις</b>.</li>
				<li> Δημιουργήστε τις <b>Ομάδες Χρηστών σας.</b></li>
				<li> <b>Ηχογραφήστε τα ηχητικά μηνύματα</b> που θα ακούνε οι πελάτες σας και <b>ορίστε πως θα διαχειρίζονται οι κλήσεις σας</b></li>
				
				<br></br>Εξοικονομήστε εως 75% σε όλες τις κλήσεις σας.
				<br /><p></p> <br></br>
				<strong>Θέλετε να προσθέσετε ΝΕΟΥΣ χρήστες ? ΕΥΚΟΛΑ μόνο με 0,75 €/μήνα ανά χρήστη! </strong>
				<br></br>
				
                <Button href="/contact" color="secondary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
			  <Button href="/didnumbers" color="warning">
                  ΔΙΑΘΕΣΙΜΟΙ ΑΡΙΘΜΟΙ
              </Button>
              </Typography>
			 
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 30 }}>
              <h2>Δυνατότητα Ηχογράφησης Κλήσεων</h2>
              <Typography variant="body1">
			    <li> Σε κάθε Τηλεφωνικό κέντρο EVCOFFICE έχουμε την δυνατότητα να ενεργοποιήσουμε την ηχητική καταγραφή των κλήσεων για την καλύτερη εξυπηρέτηση του πελάτη.</li>
                <li> Επιλογή χρηστών που θα καταγράφεται η συνομιλία.</li>
                <li> Μηνιαίο κόστος 12 €/μήνα και ανεξαρτήτως αριθμού χρηστών. </li>
                <br></br>
				<strong>ΟΙΚΟΝΟΜΙΚΑ ΚΑΙ ΧΩΡΙΣ ΤΗΝ ΑΝΑΓΚΗ ΑΓΟΡΑΣ ΠΡΟΣΘΕΤΟΥ ΕΞΟΠΛΙΣΜΟΥ </strong>
				<br></br>
				<h3><strong>Η εξυπηρέτηση των πελατών σας είναι προσωπική μας υπόθεση!</strong></h3>
				<Button href="/contact" color="primary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
			  <Button href="/didnumbers" color="secondary">
                ΔΙΑΘΕΣΙΜΟΙ ΑΡΙΘΜΟΙ
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/voicerec.jpg"
                alt="Hellasfon Voice Recording Services"
                title="Hellasfon Υπηρεσίες καταγραφής ομιλιών για Call Centers"
              />
            </GridItem>
						
		  
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  
		  <AppSection />
		  </GridContainer>
		  
		  <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών σας.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

CallCenterPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(CallCenterPage);
