import React from "react";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import AddressFormBusiness from "./Register/AddressFormBusiness";
import PhoneBusiness from "./Register/PhoneBusiness";
import ReviewBusiness from "./Register/ReviewBusiness";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const checkoutStyle = theme => ({
  paper: {
    marginTop: "0",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root"
    }
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
});

const steps = [
  "Προσωπικά στοιχεία",
  "Τηλεφωνικό νούμερο",
  "Ελέγξτε την παραγγελία σας"
];

class CheckoutBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      company: "",
      vat: "",
      firstName: "",
      lastName: "",
      email: "",
      check_email: "",
      phonemobile: "",
      phone: "",
      address: "",
      tk: "",
      accept: false,
      city: "",
      aoy: "",
      verify_mobile: "",
      local_area: 1
    };
    this.getStepContent = this.getStepContent.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleNextReview = this.handleNextReview.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  getStepContent(step, steps) {
    switch (step) {
      case 0:
        return (
          <AddressFormBusiness
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            company={this.state.company}
            vat={this.state.vat}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            phone={this.state.phone}
            address={this.state.address}
            tk={this.state.tk}
            accept={this.state.accept}
            city={this.state.city}
            aoy={this.state.aoy}
          />
        );
      case 1:
        return (
          <PhoneBusiness
            activeStep={step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            company={this.state.company}
            vat={this.state.vat}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            phone={this.state.phone}
            address={this.state.address}
            tk={this.state.tk}
            accept={this.state.accept}
            city={this.state.city}
            aoy={this.state.aoy}
          />
        );
      case 2:
        return (
          <ReviewBusiness
            activeStep={step}
            steps={steps}
            handleNext={this.handleNext}
            handleNextReview={this.handleNextReview}
            handleBack={this.handleBack}
            company={this.state.company}
            vat={this.state.vat}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            phone={this.state.phone}
            address={this.state.address}
            tk={this.state.tk}
            accept={this.state.accept}
            city={this.state.city}
            aoy={this.state.aoy}
            local_area={this.state.local_area}
          />
        );
      default:
        return (
          <AddressFormBusiness
            activeStep={step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
          />
        );
    }
  }

  handleNextReview(verify_mobile) {
    window.scrollTo(0, 0);
    this.setState({
      activeStep: this.state.activeStep + 1,
      verify_mobile: verify_mobile
    });
  }

  handleNext(
    company,
    vat,
    firstName,
    lastName,
    email,
    phonemobile,
    phone,
    address,
    tk,
    accept,
    verify_mobile,
    city,
    aoy
  ) {
    this.setState({
      activeStep: this.state.activeStep + 1,
      company: company,
      vat: vat,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phonemobile: phonemobile,
      phone: phone,
      address: address,
      tk: tk,
      accept: accept,
      city: city,
      aoy: aoy
    });
  }

  handleBack(
    company,
    vat,
    firstName,
    lastName,
    email,
    phonemobile,
    phone,
    address,
    tk,
    accept,
    city,
    aoy
  ) {
    this.setState({
      activeStep: this.state.activeStep - 1,
      company: company,
      vat: vat,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phonemobile: phonemobile,
      phone: phone,
      address: address,
      tk: tk,
      accept: accept,
      city: city,
      aoy: aoy
    });
  }
  render() {
    const { classes, plan_pack } = this.props;
    const { activeStep, verify_mobile } = this.state;
    return (
      <GridContainer justify="center" style={{marginTop: "0"}}>
        <GridItem xs={12} sm={12} md={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {activeStep + 1 + ". " + plan_pack.name}
            </Typography>
            <Typography component="h5" align="center">
              {plan_pack.description}
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Ευχαριστούμε για την παραγγελία σας.
                </Typography>
                <Typography variant="subtitle1">
                  Ο αριθμός παραγγελίας σας είναι #{verify_mobile.order_id}.
                  Σας έχουμε στείλει ένα email επιβεβαίωσης σχετικά με την
                  παραγγελία σας. Θα λάβετε επιπλέον ενημέρωση
                  όταν η παραγγελία σας έχει αποσταλθεί.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.getStepContent(activeStep, steps)}
              </React.Fragment>
            )}
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }
}
CheckoutBusiness.propTypes = {
  classes: PropTypes.object
};

export default withStyles(checkoutStyle)(CheckoutBusiness);
