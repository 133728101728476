/*eslint-disable*/
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

import ReactCountryFlag from "react-country-flag";

function HeaderTopLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.listTop}>
      <ListItem className={classes.listTopItem}>
        <CustomDropdown
          noLiPadding
          buttonText={<ReactCountryFlag code="GB" svg />}
          buttonProps={{
            className: classes.navTopLink,
            color: "transparent"
          }}
          dropdownList={[
            <Link to={window.location.pathname.substring(3)}  className={classes.dropdownLink}>
              <ReactCountryFlag code="GR" svg /> Ελληνικά
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listTopItem}>
          <Link to="/en/retail" className={classes.navTopLink}>
          Individuals
          </Link>
      </ListItem>
      <ListItem className={classes.listTopItem}>
        <CustomDropdown
          noLiPadding
          buttonText="BUSINESS-COMPANIES"
          buttonProps={{
            className: classes.navTopLink,
            color: "transparent"
          }}
          dropdownList={[
            <Link to="/en/siptrunk" className={classes.dropdownLink}>
              Phone lines
            </Link>,
            <Link to="/en/cloudpbx" className={classes.dropdownLink}>
              PBX/Call centers
            </Link>,
			
          ]}
        />
      </ListItem>
      <ListItem className={classes.listTopItem}>
        <Link  className={classes.navTopLink}
          to=""
          onClick={() => {window.location.href = "https://shop.hellasfon.com"}}
        >
          e-shop
        </Link>
      </ListItem>

    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderTopLinks);
