import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import ListItem from "@material-ui/core/ListItem";
import CookieConsent from "react-cookie-consent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";

class HomePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_B2B_services.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{paddingTop: '70px' }}>
                <h3>
                  <strong>HELLASFON - Τηλεπικοινωνιακές λύσεις για επιχειρήσεις</strong>
                </h3>
				<h5><b>B2B Οικονομικές Λύσεις </b> για την επιχείρηση σου</h5>
				<ul>
                  
				  <li>Επεκτάσιμες λύσεις για κάθε Eπιχείρηση </li> 
				  <li>Λύσεις για κάθε διαθέσιμο Budget </li>
				  <li>Εύκολη Υλοποίηση - για κάθε Επιχειρηματικό Project </li> 
				  <li>Χωρίς Δεσμεύσεις ή Συμβόλαια</li>
				  <li><strong>Εγγύηση Χαμηλότερης Τιμής !</strong></li>
				</ul>  
                               
				<h4 style={{ color: 'lime' }}><b> | <a href="/retail">Home Telephony</a> | <a href="/siptrunk">Business Telephony - Sip Trunks  </a>|  <a href="/cloudpbx">Cloud PBX  </a> |  <a href="/iotsim">IoT </a> | </b></h4> 
				
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            Hellasfon Telephony
          </h1>
          <div>
            <div className={classes.container}>
              <GridContainer id="pricing">
                <ListItem>
                  <Typography
                    component="h4"
                    variant="h4"
                    color="primary"
                  ></Typography>
                </ListItem>
                <ListItem>
                  <Typography
				    
                    component="h4"
                    variant="body1"
                    color="textPrimary"
                  >
                    Η σταθερή τηλεφωνία αλλάζει ! Η <b>HELLASFON</b> πρωτοπορεί
                    σε αυτές τις εξελίξεις, προσφέροντας ευέλικτα προγράμματα
                    για ιδιώτες και επιχειρήσεις με εφαρμογές τελευταίας γενιάς.
                    Συνδυάζουμε το οικονομικό όφελος της σταθερής τηλεφωνίας με
                    την ευελιξία της κινητής. Σταθερός αριθμός και τηλεφωνία απο το κινητό. Όλα
                    τα προγράμματα είναι <b>χωρίς δέσμευση</b>, με{" "}
                    <b>ONLINE εγγραφή</b> και <b>διαθέσιμα την ίδια μέρα!</b>
                  </Typography>
                </ListItem>
              </GridContainer>
            </div>
            
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>BUSINESS – Τηλεφ. κέντρα EVCOFFICE</h2>
                <Typography variant="body1">
                  <b>CLOUD</b> Τηλεφωνικά κέντρα τελευταίας γενιάς, έτοιμα για χρήση
                  την ίδια μέρα εγγραφής.
                  <li>Εύκολη διαχείριση DIY.</li>
                  <li>
                    Ενσωματωμένες υπηρεσίες απομακρυσμένης εργασίας από το
                    ΚΙΝΗΤΟ και τον υπολογιστή του κάθε χρήστη.
                  </li>
                  <li>
                    <b>Δωρεάν εξωτερικές γραμμές</b> για κάθε χρήστη
                    προσφέροντας εως 100% διαθεσιμότητα
                  </li>
                  <li>  
					Απόκτησε το σήμερα με ONLINE εγγραφή και χωρίς δεσμεύσεις.
                  </li>
                </Typography>
                <br />
                <Button fullWidth href="/cloudpbx" color="primary">
                  Δείτε πώς
                </Button>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  component="img"
                  image="img/coronavirus-hellasfon-06.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="img/coronavirus_PBX-01.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  Τηλεφωνικές γραμμές και SIP TRUNK
                </h2>
                <Typography variant="body1" style={{ color: "#fff" }}>
                  Προσθέστε τηλεφωνικές γραμμές στην επιχείρηση σας από 9,50 €/μήνα
                  με απεριόριστες κλήσεις προς σταθερά.
                  <li>
                    Χαμηλώστε τα έξοδα σας και αυξήστε την παροχή των υπηρεσιών σας με
                    τα προγράμματα ομιλίας της HELLASFON.
                  </li>
                  <li>
                    Μειώστε έως -30% τον λογαριασμό του γραφείου σας με εγγύηση
                    χαμηλότερης τιμής και χωρίς δεσμεύσεις.
                  </li>
                </Typography>
                <br />
                <Button fullWidth href="/siptrunk" color="rose">
                  Δείτε πώς
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>Wholesale - Διασύνδεση με άλλους Παρόχους</h2>
                <Typography variant="body1">
                  Είμαστε Πάροχος Χονδρικής Πώλησης Τερματισμού κλήσεων πρός άλλους Παρόχους.
				  <br></br>
				  <br></br>
                  <li>
                    Η Hellasfon είναι ένας TIER1 Τηλεπικοινωνιακός Πάροχος στην Ελλάδα , ο οποίος προσφέρει απευθείας CLI
                    routes πρός την Ευρώπη και τον υπόλοιπο κόσμο.
                  </li>
                  <li>
                    Απευθείας routes για όλους τους Δορυφορικούς προορισμούς, 
                    προσφέροντας ανταγωνιστικές τιμές πρός όλα τα τηλεπικοινωνιακά δίκτυα.
                  </li>
				  <br></br>
				  <Button fullWidth href="/contact" color="rose">
                  Επικοινωνήστε μαζί μας και στείλτε το αίτημα σας 
				  </Button>
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  style={{
                    paddingTop: 100,
                    paddingRight: 100,
                    paddingBottom: 100,
                  }}
                  component="img"
                  image="img/coronavirus_PBX-03.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="img/coronavirus-hellasfon-01.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  IOT – M2M – Travelsim mobile services 
                </h2>
                <Typography
                  variant="body1"
                  style={{
                    color: "#fff",
                  }}
                >
                  <li>
                    Η HELLAFON ειναι ο αποκλειστικός αντιπρόσωπος της{" "}
                    <a
                      className={classes.linkblue}
                      href="https://www.360roaming.com"
                    >
                      360ROAMING
                    </a>{" "}
                    στην Ελλάδα.
                  </li>
                  <li>
                    <a
                      className={classes.linkblue}
                      href="https://360roaming.com/360travelsim/"
                    >
                      360Travelsim
                    </a>{" "}
                    - SIM ιδανική για επαγγελματικά ταξίδια και ταξίδια αναψυχής.
                    Χωρίς περιαγωγή Roaming σε πάνω απο 200 χώρες.
                  </li>
                  <li>
                    <a
                      className={classes.linkblue}
                      href="https://360roaming.com/360iotsim/"
                    >
                      360IOTsim
                    </a>{" "}
                    - Multi Vendor SIM με παγκόσμια κάλυψη.
                  </li>
                </Typography>
              </GridItem>
            </GridContainer>
			
			<GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
			    <br></br><br></br><br></br>
                <h2>Hellasfon Home</h2>
				<Typography variant="body1" style={{ color: "#000000" }}>
                  Εύκολα, γρήγορα, ευέλικτο και πάνω από όλα οικονομικό. <br></br>
				  Κάνε <a className={classes.linkrose} href="https://www.hellasfon.com/reg/retail/191">ΕΓΓΡΑΦΗ</a> και απόκτησε το νέο σου ΣΤΑΘΕΡΟ αριθμό στο κινητό σου !{" "}
                  <br></br><br></br>
                  Σταθερός αριθμός χωρίς δεσμεύσεις. Καλείς από την <b>Hellasfon εφαρμογή</b> χωρίς πρόσθετες χρεώσεις και περιαγωγή Roaming.
				  <br></br>
				  <br></br>
                  
				  <br></br>
				  <br></br>
				  <Button fullWidth href="/retail" color="rose">
                  ΔΕΙΤΕ ΠΩΣ !
				  </Button>
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  style={{
                    paddingTop: 100,
                    paddingRight: 100,
                    paddingBottom: 20,
                  }}
                  component="img"
                  image="img/coronavirus-hellasfon-03.jpg"
                  alt="hellasfon home service"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
			
			
			
          </div>
          <VisaSection />
          <Footer />
          <ScrollUpButton />
        </div>
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(HomePage);
