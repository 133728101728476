import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import CardMedia from "@material-ui/core/CardMedia";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    backgroundColor: "#005fb4",
    margin: 0,
    padding: theme.spacing(2),
    color: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogAdvertisment(props) {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Work from home - Teleworking:
        </DialogTitle>
        <DialogContent dividers>
          <CardMedia
            component="img"
            alt="Hellasfon Κινητή, Σταθερή Voip τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
            image="img/home_office.png"
            title="Hellasfon Κινητή, Σταθερή Voip τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
          />
          <h5>
            <strong>
              By using HELLASFON Cloud PBX , working from HOME 
              is easy !
            </strong>
            <br />
            <ul>
              <li>
                NO NEED of additional expenses for devices or other equipment, you call / received calls
                at your mobile and your computer when you are HOME or ON THE GO.
              </li>
              <li>
                Each user may communicate with his company/partners
                by video, messages and phonecalls directly by his
                pc and mobile phone.
              </li>
              <li>
                Teleworking / Teleconference Service is included in ALL EVCOFFICE Cloud PBX, 
                and for ALL Users! No Extra Fees!
              </li>
              <li>Easy to use - PLUG and PLAY.</li>
            </ul>
            <strong>Ready in 5 minutes , in just 3 steps!</strong>
          </h5>
        </DialogContent>
        <DialogActions>
          <Button href="#pricing" onClick={handleClose} color="primary">
          Register
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
