import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
//import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
//import PlansTable from "./Sections/PlansTableSection.jsx";
//import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import ImageSection from "./Sections/ImageSection.jsx";
//import PriceTable from "./Sections/PriceTableSection.jsx";
//import YouTube from "react-youtube";
//import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import { getRetailPlanPacks, getPlans } from "actions/retailActions";

//import DialogAdvertisment from "./DialogAdvertisment";

class RetailPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      value_radio: "device",
      loading_plan_pack: true,
    };
  }

  async componentDidMount() {
    await this.props.getRetailPlanPacks("no_device");
    await this.props.getPlans("retail");
    this.setState({
      loading_plan_pack: false,
    });
    setTimeout(() => {
      this.setState({ open: true });
    }, 1000);
  }
  handleChange = async (e) => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true,
    });
    await this.props.getRetailPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false,
    });
    if (this.props.retail_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "Δεν υπάρχουν σχέδια προς το παρόν",
      });
    }
  };
  selectPlan = async () => {
    return;
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, retail_plan_packs, plans, ...rest } = this.props;
    //const { loading_plan_pack, classSnackbar, response } = this.state; 
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/travelgirl.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{paddingTop: '80px' }}>
                <h5>
                  <strong>
                    360TravelSIM : ΜΙΑ eSIM για ΟΛΕΣ τις χώρες του κόσμου!
                  </strong>
                  <br />
                  <ul>
                    <li>Κάλυψη για <b> 200+ χώρες </b></li>
                    <li>Κατάλληλη για χρήση δεδομένων <b> (Data) </b> </li>
                    <li><b> XΩΡΙΣ ΠΕΡΙΑΓΩΓΗ (Roaming) </b> </li>
					<li><b>ΟΛΟΙ</b> οι πάροχοι κινητής τηλεφωνίας στη διάθεση σας </li> 
					<li><b>ΑΜΕΣΗ ΕΝΕΡΓΟΠΟΙΗΣΗ </b>  </li>
                  </ul>
                  <strong>ΜΙΑ eSIM για χρήση ΠΑΝΤΟΥ !</strong>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="https://www.360travelsim.com/el/%CE%BA%CE%B1%CE%BB%CE%B1%CE%B8%CE%B9"
                  rel="noopener noreferrer"
                >
                  Αγορά
                </Button>
				
				<Button
                  color="secondary"
                  size="lg"
                  href="https://www.360travelsim.com/el/"
                  rel="noopener noreferrer"
                >
                  Πληροφορίες
                </Button>
				
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            360TravelSIM
          </h1>
          <div className={classes.container}>
            <GridContainer id="pricing">
              
              
            </GridContainer>
            <SnackbarContent
              //className={classSnackbar}
              aria-describedby="client-snackbar"
              //message={response}
            />
            
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="/img/test1.jpg"
				headText="Μια eSIM για όλες τις χώρες του κόσμου!"
                text1={[
                  <strong> - Λειτουργεί σε 200 + Χώρες. </strong>,
				  <br />,
                  " Επικοινωνήστε οπουδήποτε και αν βρίσκεστε χρησιμοποιώντας 550 παρόχους τηλεπικοινωνιών. Μείνετε πάντα διαθέσιμος με πακέτα που αυξομειώνονται βάσει των αναγκών σας, προσωπικών ή της επιχείρησης σας. ",
                  <br />,
				  <br />,
                  <strong>
                    {" "}
                    - Εξοικονομήστε εως 85 % στα έξοδα περιαγωγής Roaming.{" "}
                  </strong>,
                  "  Χρησιμοποιήστε την online πλατφόρμα για να δείτε την υπάρχον τηλεπικοινωνιακή χρήση σας καθώς και την οικονομία που θα επιτύχετε χάρη στην χρήση της.  ",
				                   
				  <br />,
				  <br />,
				  <strong> - Γρήγορη & Απλή Διαδικασία.</strong>,
				  <br></br>,
				  " Η νέα eSIM σας θα ενεργοποιηθεί μέσα σε 3 λεπτά. Εσείς το μόνο που θα χρειαστεί να κάνετε είναι : 1) να επιλέξετε την χώρα που θέλετε, 2) να διαλέξετε το πακέτο της επιλογής σας και 3) να καταχωρήσετε online τα στοιχεία σας. Η 360travelsim θα σας αποσταλλεί μέσω email και είναι έτοιμη πρός ενεργοποίηση !   ",
				  
                ]}
              />
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="img/luggages_sign.jpg"
                headText="Γιατί να επιλέξετε την 360travelsim : "
                text1={[
                  <strong>Εύκολα και γρήγορα !</strong>,
				  <br />,
                  " - Ο ευκολότερος τρόπος για να ταξιδέψετε ανα πάσα στιγμή σε οποιοδήποτε σημείο του πλανήτη χωρίς να μείνετε λεπτό χωρίς επικοινωνία ή να χρειάζεστε να αγοράζετε νέες κάρτες sim για κάθε μέρος που επισκέφτεστε.",
				  <br />,
                  <br />,
                  " ",
                  <strong>Ασφάλεια συνδεσιμότητας - ΟΛΟΙ οι πάροχοι κινητής σε μια eSIM !</strong>,
                  <br />,
                  "- Χρησιμοποιώντας την 360travelsim σας δίνεται η δυνατότητα να χρησιμοποιήστε οποιοδήποτα πάροχο εσείς επιθυμείτε και έχει το καλύτερο σήμα και παροχή δεδομένων (αυτόματα ή χειροκίνητα). ",
				  <br />,
                  <br />,
				  <strong>Ένα πακέτο - Κάλυψη σε πολλές χώρες και προορισμούς !</strong>,
				  " ",
				  <br />,
                  
				  "- Και το καλύτερο απο όλα ? Επιλέγοντας ένα πακέτο για την 360travelsim σας δίνουμε κάλυψη και σε άλλες χώρες και προορισμούς πέρα απο αυτόν που επιλέξατε αρχικά! Χρησιμοποιήστε την 360travelsim για να καλύψετε όλες τις ανάγκες συνδεσιμότητας σας είτε είστε ταξιδιώτης αναψυχής είτε επειδή επισκέπτεστε το εξωτερικό συχνά για επαγγελματικούς λόγους.",
                  
                ]}
				
                
              />
			  <Button fullWidth href="https://360travelsim.com/el" color="secondary" size="lg" >
                  ΜΑΘΕΤΕ ΠΕΡΙΣΣΟΤΕΡΑ ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ 360travel eSIM & ΤΑ ΟΦΕΛΗ ΤΗΣ 
                </Button>
            </div>
			
          </div>
          <AppSection />
          <VisaSection />
          <Footer />

          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
            εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
              Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
              συγκατάθεσή σας για να ορίσουμε cookies.{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                Περισσότερες πληροφορίες
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

RetailPage.propTypes = {
  classes: PropTypes.object,
  retail_plan_packs: PropTypes.array.isRequired,
  getRetailPlanPacks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  retail_plan_packs: state.retail_reducer.retail_plan_packs,
  plans: state.retail_reducer.plans,
});

export default connect(
  mapStateToProps,
  { getRetailPlanPacks, getPlans }
)(withStyles(landingPageStyle)(RetailPage));
