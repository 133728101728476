import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import scrollIntoView from "scroll-into-view-if-needed";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import {
  getlocalAreas,
  verifyMobile,
  removeOrder,
  verifyCode,
} from "actions/retailActions";

class PhoneForm extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes, local_area, phonemove, changePhone } = props;
    this.state = {
      changePhone: changePhone,
      phonemove: phonemove,
      local_area: local_area,
      classSnackbar: classes.hideSnackbar,
      response: "",
      code: "",
      loading_verifyMobile: false,
      errors: {
        phonemove: false,
        code: false,
      },
    };
  }

  async componentDidMount() {
    if (!this.state.loading_verifyMobile) {
      this.setState({ loading_verifyMobile: true });
      await this.props.getlocalAreas();
    }
    this.setState({ loading_verifyMobile: false });
  }

  onClick = async (e) => {
    e.preventDefault();

    const {
      company,
      vat,
      classes,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      verify_mobile,
      city,
      aoy,
    } = this.props;

    const { changePhone, phonemove, local_area } = this.state;

    if (verify_mobile.order_id !== undefined) {
      if (!this.state.loading_verifyMobile) {
        this.setState({ loading_verifyMobile: true });
        const res_back = await this.props.removeOrder(
          verify_mobile.order_id,
          verify_mobile.dsfsafw352
        );
        if (res_back) {
          // display error mesage
          this.setState({
            loading_verifyMobile: false,
            classSnackbar: classes.errorSnackbar,
            response: "Κάτι πήγε στραβά πάλι.",
          });
        }
      }
    }
    this.setState({ loading_verifyMobile: false });
    this.props.handleBack(
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      changePhone,
      phonemove,
      local_area,
      city,
      aoy
    );
  };
  onClickChnagePhone = () =>
    this.setState({
      changePhone: !this.state.changePhone,
    });
  onChange = (e) => {
    this.setState(
      e.target.id === "changePhone"
        ? {
            [e.target.id]: e.target.checked,
          }
        : (e.target.value === "" && e.target.id === "phonemove") ||
          (e.target.value.length < 10 && e.target.id === "phonemove") ||
          (e.target.value.length > 10 && e.target.id === "phonemove") ||
          (e.target.id === "phonemove" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "code") ||
          (e.target.value.length < 5 && e.target.id === "code") ||
          (e.target.value.length > 5 && e.target.id === "code") ||
          (e.target.id === "code" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : e.target.name === "local_area"
        ? {
            [e.target.name]: e.target.value,
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: false,
            },
          }
    );
  };
  onBlur = (e) => {
    this.setState(
      (e.target.value === "" && e.target.id === "phonemove") ||
        (e.target.value.length < 10 && e.target.id === "phonemove") ||
        (e.target.value.length > 10 && e.target.id === "phonemove") ||
        (e.target.id === "phonemove" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            errors: {
              phonemove: true,
            },
          }
        : (e.target.value === "" && e.target.id === "code") ||
          (e.target.value.length < 5 && e.target.id === "code") ||
          (e.target.value.length > 5 && e.target.id === "code") ||
          (e.target.id === "code" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : {
            errors: {
              phonemove: false,
            },
          }
    );
  };
  onVerify = async (e) => {
    e.preventDefault();

    const {
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      classes,
      retail_plan_pack,
      city,
      aoy,
    } = this.props;

    const {
      phonemove,
      changePhone,
      local_area,
      loading_verifyMobile,
    } = this.state;

    // Check For Errors
    if (
      (changePhone && phonemove === "") ||
      (changePhone && phonemove.length < 10) ||
      (changePhone && phonemove.length > 10) ||
      (changePhone && !phonemove.match(/^[0-9\b]+$/))
    ) {
      this.setState({ errors: { phonemove: true } });
      const node = document.getElementById("phonemove");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
      return;
    }

    if (!loading_verifyMobile) {
      this.setState({ loading_verifyMobile: true });
      // sent axios loading
      const res = await this.props.verifyMobile(
        company,
        vat,
        firstName,
        lastName,
        email,
        phonemobile,
        phone,
        address,
        tk,
        phonemove,
        local_area,
        changePhone,
        retail_plan_pack,
        city,
        aoy
      );
      if (res) {
        // display error mesage
        this.setState({
          loading_verifyMobile: false,
          classSnackbar: classes.errorSnackbar,
          response: "Κάτι πήγε πάλι λάθος. Ελέγξτε το κινητό σας τηλέφωνο.",
        });
      }
    }

    this.setState({ loading_verifyMobile: false });
    if (this.props.verify_mobile.verify === true) {
      // response with veryfication code true - enter veryfication code Input
      this.setState({
        classSnackbar: classes.successSnackbar,
        response: "Το μήνυμα SMS αποστάλθηκε με επιτυχία.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
    } else {
      // display error mesage
      this.setState({
        classSnackbar: classes.errorSnackbar,
        response: "Κάτι πήγε πάλι λάθος. Ελέγξτε το κινητό σας τηλέφωνο.",
      });
    }
    return;
  };
  onSubmit = async (e) => {
    e.preventDefault();

    const {
      classes,
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      verify_mobile,
      city,
      aoy,
    } = this.props;

    const {
      phonemove,
      changePhone,
      code,
      loading_verifyMobile,
      local_area,
    } = this.state;

    // Check For Errors
    if (
      (changePhone && phonemove === "") ||
      (changePhone && phonemove.length < 10) ||
      (changePhone && phonemove.length > 10) ||
      (changePhone && !phonemove.match(/^[0-9\b]+$/))
    ) {
      this.setState({ errors: { phonemove: true } });
      const node = document.getElementById("phonemove");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
      return;
    }
    if (
      code === "" ||
      code === undefined ||
      (code && code.length < 5) ||
      (code && code.length > 5) ||
      (code && !code.match(/^[0-9\b]+$/))
    ) {
      this.setState({
        errors: { code: true },
        classSnackbar: classes.errorSnackbar,
        response: "Ο κωδικός επαλήθευσης SMS που πληκτρολογήσατε δεν είναι σωστός.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
      return;
    }
    if (verify_mobile.order_id !== undefined) {
      if (!loading_verifyMobile) {
        this.setState({ loading_verifyMobile: true });
        const res = await this.props.verifyCode(
          verify_mobile.order_id,
          verify_mobile.dsfsafw352,
          code,
          phonemove,
          local_area,
          changePhone
        );
        if (res) {
          // display error mesage
          this.setState({
            loading_verifyMobile: false,
            classSnackbar: classes.errorSnackbar,
            response: "Ο κωδικός επαλήθευσης SMS που πληκτρολογήσατε δεν είναι σωστός.",
          });
        }
      }
    }
    this.setState({ loading_verifyMobile: false });
    if (this.props.verify_code === true) {
      this.setState({
        classSnackbar: classes.successSnackbar,
        response: "Ο αριθμός του κινητού σας έχει επιβεβαιωθεί",
      });
      // handle next
      this.props.handleNext(
        company,
        vat,
        firstName,
        lastName,
        email,
        phonemobile,
        phone,
        address,
        tk,
        accept,
        changePhone,
        phonemove,
        verify_mobile,
        local_area,
        city,
        aoy
      );
    } else {
      // display error mesage
      this.setState({
        classSnackbar: classes.errorSnackbar,
        response: "Ο κωδικός επαλήθευσης SMS που πληκτρολογήσατε δεν είναι σωστός.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
    }

    return;
  };
  render() {
    const {
      activeStep,
      classes,
      phonemobile,
      loading_phoneForm,
      local_areas,
      verify_mobile,
    } = this.props;
    const {
      changePhone,
      phonemove,
      errors,
      local_area,
      classSnackbar,
      response,
      code,
      loading_verifyMobile,
    } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            <center><b>Επάληθευση Κινητού</b></center>
          </Typography>
          <Grid container>
            <GridItem xs={12}>
              <Typography align="center">
                Για λόγους ασφάλειας η Hellasfon πρέπει να επαληθεύσει τον
                αριθμό του κινητού σας, πρίν την εγγραφή σας. Ελέγξτε αν
                εμφανίζεται σωστά το νούμερο σας και στην συνέχεια πατήστε το κουμπί ΕΠΑΛΗΘΕΥΣΗ ΚΙΝΗΤΟΥ. Διαφορετικά επιστρέψτε στο προηγούμενο βήμα!
              </Typography>
              <CustomInput align="center"
                type="text"
                labelText="- ΑΡΙΘΜΟΣ ΚΙΝΗΤΟΥ -"
                id="phonemobile"
                name="phonemobile"
                value={phonemobile}
                onChange={this.onChange}
                disabled={true}
                /*helperText="Συμπληρώστε τον αριθμό του κινητού σας, προσθέτοντας στην αρχή τον κωδικό χώρας π.χ 306988123412 ή 447578754922"*/
                formControlProps={{
                  fullWidth: true,
                }}
                /*inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+30</InputAdornment>
                  )
                }}*/
              />
              {verify_mobile.verify && (
                <CustomInput
                  error={errors.code ? true : false}
                  type="text"
                  labelText="Kωδικός Eπιβεβαίωσης"
                  id="code"
                  name="code"
                  value={code}
                  onChange={this.onChange}
                  onFocus={this.onBlur}
                  onBlur={this.onBlur}
                  disabled={loading_verifyMobile}
                  helperText="Ελέγξτε το κινητό σας ! Σας έχουμε στείλει ένα SMS με κωδικό πρόσβασης"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            </GridItem>
          </Grid>
          <Typography variant="h6" gutterBottom>
            Επιλέξτε τηλεφωνικό αριθμό
          </Typography>
          <Grid container>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    id="changePhone"
                    name="changePhone"
                    disabled={loading_verifyMobile}
                    checked={changePhone}
                    onClick={this.onClickChnagePhone}
                    onChange={this.onChange}
                  />
                }
                label={
                  <Typography className={classes.labelCheckbox}>
                    Θέλω να κρατήσω τον αριθμό μου (Κόστος φορητότητας εφάπαξ 20€)
                  </Typography>
                }
              />
			  
            </GridItem>
			
            <GridItem xs={12} sm={12} md={12}>
              {changePhone ? (
                <CustomInput
                  error={errors.phonemove ? true : false}
                  required
                  type="phonemove"
                  labelText="Αριθμός Σταθερού"
                  id="phonemove"
                  name="phonemove"
                  value={phonemove}
                  onChange={this.onChange}
                  onFocus={this.onBlur}
                  onBlur={this.onBlur}
                  disabled={loading_verifyMobile}
                  helperText="Συμπληρώστε τον αριθμό του σταθερού σας που θέλετε να μεταφέρετε π.χ 2818123412"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+30</InputAdornment>
                    ),
                  }}
                />
              ) : (
                <React.Fragment>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Θέλω ΝΕΟ Αριθμό
                  </Typography>
                  <FormControl disabled={loading_verifyMobile}>
                    <InputLabel htmlFor="local_area">
                      Κωδ.Περιοχής
                    </InputLabel>
                    <Select
                      value={local_area}
                      onChange={this.onChange}
                      inputProps={{
                        name: "local_area",
                        id: "local_area",
                      }}
                    >
                      {local_areas.map((area) => (
                        <MenuItem key={area.id} value={area.id}>
                          {area.name} 
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      
                    </FormHelperText>
                  </FormControl>
                </React.Fragment>
              )}
            </GridItem>
          </Grid>
          <SnackbarContent
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
          <GridContainer className={classes.buttons}>
            <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
              {activeStep !== 0 && (
                <Button
                  onClick={this.onClick}
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  Πίσω
                </Button>
              )}
              {!verify_mobile.verify ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onVerify}
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  {"Επαλήθευση κινητού"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  {"ΕΠΟΜΕΝΟ"}
                </Button>
              )}
              {loading_phoneForm && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {loading_verifyMobile && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </GridItem>
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

PhoneForm.propTypes = {
  classes: PropTypes.object,
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};

const mapStateToProps = (state) => ({
  local_areas: state.retail_reducer.local_areas,
  verify_mobile: state.retail_reducer.verify_mobile,
  verify_code: state.retail_reducer.verify_code,
  retail_plan_pack: state.retail_reducer.retail_plan_pack,
});

export default connect(
  mapStateToProps,
  { getlocalAreas, verifyMobile, removeOrder, verifyCode }
)(withStyles(workStyle)(PhoneForm));
