import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";
//import SolutionSection from "./Sections/SolutionSection.jsx";
import AppSection from "../Sections/AppSectionBusiness.jsx";

import CookieConsent from "react-cookie-consent";

class StartupPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Start Up"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
              <h2>ΔΩΡΕΑΝ Ψηφιακό Τηλεφωνικό Κέντρο σε 5'</h2>
              <Typography variant="body1">
                H επικοινωνία παίζει σημαντικό ρόλο στην επιτυχία μιας
                επιχείρησης και ο χρόνος από την σύσταση μέχρι την έναρξη
                λειτουργίας της είναι καθοριστικός παράγοντας.
                <br />
                Οι νέες εποχές απαιτούν καινοτόμες λύσεις προσαρμοσμένες
                στις σημερινές ανάγκες.<br></br>
                <br />
                Η Hellasfon προσφέρει έτοιμες λύσεις επικοινωνίας για κάθε
                επιχείρηση.<br></br> Συνδυάζουμε την κινητή και την σταθερή τηλεφωνία με
                ευέλικτα οικονομικά προγράμματα και παραγωγικά εργαλεία, που
                βοηθούν στην γρήγορη εκκίνηση και ανάπτυξη της εταιρείας σας.
                <br /><br></br>
                <b>Φτιάξε το δικό σου τηλεφωνικό κέντρο, και ξεκίνησε την
                επιχείρησή σου μέσα σε λίγα λεπτά!</b><br></br><br></br>
				<Button fullWidth href="/cloudpbx#pricing" color="primary">
                ΕΓΓΡΑΦΗ
              </Button>
              </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/hellasfon_startup_solution_03.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="../img/coronavirus-hellasfon-03.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Χωρίς Δέσμευση:</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                Χωρίς συμβόλαια και δέσμευσεις – προσθέτεις / αφαιρείς χρήστες
                στο τηλεφωνικό κέντρο σου με €0,75/χρήστη/μήνα. <br /><p></p>
                <li>
                  Κάθε χρήστης έχει και την δική του <b>εξωτερική</b> γραμμή.
                </li>
                <li>Προσφέροντας στην επιχείρηση 100% διαθεσιμότητα.</li>
                <li>
                  10 χρήστες, 10 εξωτερικές γραμμές, 10 ταυτόχρονες κλήσεις.
                </li><br></br><br></br>
				<Button fullWidth href="/cloudpbx#pricing" color="secondary">
                ΕΓΓΡΑΦΗ
              </Button>
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>Χαμηλό Κόστος</h2>
              <Typography variant="body1">
			  <li>
                  Δωρεάν ψηφιακό τηλεφωνικό κέντρο με 3 άδειες χρήσης / 3 κανάλια φωνής
                </li>
                <li>
                  Παραπάνω χρήστες? Με € 0,75/άδεια/μήνα προσθέτεις όσους θέλεις
                  στην στιγμή.
                </li>
                <li>
                  Χωρίς έξοδα για συσκευές και εξοπλισμό. Κατεβάστε την δωρεάν
                  EVCOFFICE εφαρμογή και πάρε το γραφείο στο κινητό σου!
                    Απάντησε / κάλεσε, chat, sms, conference, ΟΛΑ από το κινητό σου              </li>
                <li>
                  Οι επιτραπέζιες συσκευές υποστηρίζονται σε όλα τα τηλεφ. κέντρα αλλά
                  δεν είναι απαραίτητες. Οι υπηρεσίες CALL-CHAT & VIDEO
                  υποστηρίζονται από το web portal και την EVCOFFICE εφαρμογή.
                </li>
                <li>
                  Για VOIP συσκευές δείτε το{" "}
                  <a href="https://shop.hellasfon.com/">E-SHOP</a> μας.
                </li><br></br>
				<Button fullWidth href="/cloudpbx#pricing" color="primary">
                ΕΓΓΡΑΦΗ
              </Button>
              </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/coronavirus-hellasfon-06.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="../img/coronavirus_PBX-01.png"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Εύκολη Xρήση – Plug and Play</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                <li>
                  Η EVCOFFICE εφαρμογή είναι μέρος του τηλεφ. κέντρου και δεν
                  χρειάζεται παραμετροποίηση. Απλά εισάγετε το όνομα χρήστη (username) και
                  συνθηματικό (password).
                </li><br></br>
                <li>
                  Συγχρονίζει με τις επαφές της συσκευής σας για παραπάνω ευκολία
                  στην χρήση.
                </li><br></br>
                <li>
                  Οι χρήστες κατεβάζουν την εφαρμογή και αμέσως συνδέονται στο
                  τηλεφωνικό κέντρο σας. Με αυτή την δυνατότητα, η επιχείρηση μπορεί
                  να αξιοποιεί συνεργάτες και πωλητές σε άλλες πόλεις/εκτός
                  γραφείου, γρήγορα, την ίδια μέρα και χωρίς κόστος εξοπλισμού!
                </li><br></br>
				<Button fullWidth href="/cloudpbx#pricing" color="secondary">
                ΕΓΓΡΑΦΗ
              </Button>
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>Φιλικό στο χρήστη</h2>
              <Typography variant="body1">
                <li>
                  Χωρίς έξοδα για τεχνικό, μπορείς να το στήσεις μόνος σου.
                </li><br></br>
                <li>Διαθέτει εύκολη διαχείριση, με απλές κινήσεις.</li><br></br>
                <li>
                  Δέν είστε τύπος "Κάν'το Μόνος Σου" ?, με την δική σας εντολή Θα
                  το αναλάβουμε εμείς!
                </li>
				<br /> 
				<Button fullWidth href="/cloudpbx#pricing" color="primary">
                ΕΓΓΡΑΦΗ
              </Button>
              </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                style={{
                  paddingTop: 100,
                  paddingRight: 100,
                  paddingBottom: 100,
                }}
                component="img"
                image="../img/coronavirus_PBX-03.png"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="../img/hellasfon_startup_solution_01.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Ανάπτυξη</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                <li>
                  Τα EVCOFFICE τηλεφωνικά κέντρα αναπτύσσονται εύκολα, με τις
                  ανάγκες της εταιρίας.
                </li><br></br>
                <li>
                  Χωρίς δέσμευση προσθέτεις/αφαιρείς χρήστες ανάλογα με τις
                  ανάγκες σου με κόστος <br />€ 0,75/χρήστη/μήνα.
                </li><br></br>
				<Button fullWidth href="/cloudpbx#pricing" color="secondary">
                ΕΓΓΡΑΦΗ
              </Button>
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>Εργασία Εκτός Γραφείου</h2>
              <Typography variant="body1">
                Εύκολα, κάθε χρήστης μπορεί να εργαστεί εκτός γραφείου
                χρησιμοποιώντας τα ΔΩΡΕΑΝ εργαλεία για τον Η/Υ και το κινητό που
                προσφέρει η HELLASFON και το EVCOFFICE τηλεφωνικό κέντρο.
                <br /><br></br>
                <li>
                  Καλεί/απαντά και εξυπηρετεί πελάτες με τον αριθμό αναγνώρισης
                  της εταιρείας του (callerid), σαν να είναι στο γραφείο του. <br />
                </li><br></br>
                <li>
                  Επικοινωνεί με την εταιρία του εντελώς ΔΩΡΕΑΝ. ( CALL + CHAT +
                  VIDEOκλήσεις ).
                </li><br></br>
              </Typography>
              <br />
              <Button fullWidth href="/cloudpbx#pricing" color="primary">
                ΕΓΓΡΑΦΗ
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/hellasfon_startup_solution_02.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
            <AppSection />
          </GridContainer>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών σας.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

StartupPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(StartupPage);
