import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Typography from "@material-ui/core/Typography";

import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class Privacy extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            {"Πολιτική Απορρήτου"}
          </h1>
          <div className={classes.container}>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Συλλογή / Χρήση Πληροφοριών</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon Networks SA ("Hellasfon") δεν θα εμπορεύεται, δεν θα
              πωλεί ούτε θα αποκαλύπτει σε τρίτους οποιαδήποτε μορφή πληροφοριών
              αναγνωρίσιμων από τον πελάτη χωρίς τη συγκατάθεση του πελάτη (εκτός εάν απαιτείται από κλήση,
              ένταλμα έρευνας ή άλλη νομική διαδικασία ή στην περίπτωση που η μη
              κοινοποίηση των πληροφοριών θα οδηγήσει σε άμεση βλάβη στον πελάτη ή σε άλλους). Αυτό
              περιλαμβάνει πληροφορίες που προέρχονται από την εγγραφή, τη
              συνδρομή και τη χρήση της υπηρεσίας Hellasfon.
              <br />
			  Κατά την εγκατάσταση της Hellasfon εφαρμογής, θα σας ζητηθεί να μας επιτρέψετε να αποκτήσουμε πρόσβαση στο βιβλίο διευθύνσεων της 
        κινητής σας συσκευής.Ένα αντίγραφο των αριθμών τηλεφώνου και των ονομάτων όλων των επαφών σας θα συλλεχθεί και θα αποθηκευτεί στους 
        διακομιστές μας.
        Συλλέγουμε το ελάχιστο των πληροφοριών που χρειάζονται.Αυτό ζητείται για να μπορέσουμε να σας επιτρέψουμε να συνδεθείτε εσείς 
        και οι επαφές σας.Η Hellasfon δεν διαβάζει τα μηνύματα ή ακούει τις συνομιλίες σας που γίνονται με την χρήση της εφαρμογής Hellasfon.
        Οταν τα μηνύματα σας έχουν παραδoθεί στον προορισμό τους (που χρειάζονται λιγότερο απο 1 δευτερόλεπτο) , αυτόματα διαγράφονται απο τους 
        server μας.
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Ασφάλεια</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon έχει αναπτύξει μια μεγάλη ποικιλία τεχνολογιών και
              χαρακτηριστικών ασφαλείας για να διασφαλίσει την ιδιωτικότητα των
              πληροφοριών των πελατών στο δίκτυό της.
              <br />
              Επιπλέον, η Hellasfon έχει εφαρμόσει αυστηρές οδηγίες λειτουργίας
              για να διασφαλίσει ότι η προστασία της ιδιωτικής ζωής των πελατών
              διασφαλίζεται σε κάθε επίπεδο της οργάνωσής της. Η Hellasfon θα συνεχίσει να αναθεωρεί
              τις πολιτικές και να εφαρμόζει πρόσθετα χαρακτηριστικά ασφαλείας
              καθώς διατίθενται νέες τεχνολογίες.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Επικοινωνίες</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon δεν θα διαβάσει, ακούσει ή αποκαλύψει σε τρίτους
              ιδιωτικά μηνύματα ηλεκτρονικού ταχυδρομείου, συνομιλίες ή άλλες
              επικοινωνίες που μεταδίδονται μέσω υπηρεσιών Hellasfon, εκτός εάν απαιτείται για την εξασφάλιση της
              σωστής λειτουργίας των υπηρεσιών ή όπως άλλως επιτρέπεται από το
              νόμο.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Παράνομη δραστηριότητα</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon μπορεί να χρησιμοποιεί πληροφορίες που προσδιορίζουν
              τα στοιχεία των πελατών για να διερευνήσουν και να βοηθήσουν στην
              πρόληψη δυνητικά παράνομων δραστηριοτήτων ή δραστηριοτήτων που απειλούν την ακεραιότητα της
              υπηρεσίας ή της ακεραιότητας του δικτύου ή παραβιάζουν με άλλον
              τρόπο τη συμφωνία παροχής υπηρεσιών της Hellasfon.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Πληροφορίες λογαριασμού</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon θα καταβάλει κάθε προσπάθεια για να ικανοποιήσει τα
              αιτήματα των πελατών για πληροφορίες σχετικά με το λογαριασμό, για
              παράδειγμα, όνομα, διεύθυνση, εταιρεία ή στοιχεία χρέωσης. Ο πελάτης είναι υπεύθυνος για τη
              διασφάλιση ότι οι πληροφορίες που περιέχονται στο αρχείο με την
              Hellasfon είναι τρέχουσες και ακριβείς.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Πολιτική απορρήτου παιδιών</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon δεν πωλεί προϊόντα ή υπηρεσίες για αγορά από παιδιά. Η
              Hellasfon δεν προειδοποιεί ή συλλέγει αναγνωρίσιμες πληροφορίες
              πελατών από παιδιά ή εφήβους ηλικίας κάτω των δεκαοκτώ ετών. Επιπλέον, η Hellasfon δεν θα
              συνδέσει συνειδητά με οποιονδήποτε τρίτο ιστότοπο που ζητά ή
              συλλέγει αναγνωρίσιμες πληροφορίες πελατών από ανηλίκους. Εάν πιστεύετε ότι κάποιος ανήλικος έχει
              αποκαλύψει στοιχεία προσωπικής ταυτοποίησης στην Hellasfon, κάντε
              κλικ εδώ για να επικοινωνήσετε μαζί μας, ώστε να καταργηθούν οι πληροφορίες.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Πολιτική Spam Hellasfon</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon έχει μηδενική ανοχή για το spam. Οι καταγγελίες για
              ανεπιθύμητα μηνύματα θα αντιμετωπιστούν σοβαρά και ενδέχεται να
              οδηγήσουν σε απώλεια προνομίων Hellasfon.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>ENGLISH - Privacy Policy</b>
            </h2>
            <br />
            <h2 style={{ color: "#3f51b5" }}>
              <b>Collection / Use of Information</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Hellasfon Networks S.A. ("Hellasfon") will not trade, sell, or
              disclose to any third party any form of customer identifiable
              information without the consent of the customer.
              (except as required by subpoena, search warrant or other legal
              process or in the case where failure to disclose information will
              lead to imminent harm to the customer or others). This includes information derived from registration,
              subscription, and use of the Hellasfon service. When installing the Hellasfon App, you’ll be asked to allow us access your mobile 
              device’s address book. A copy of the phone numbers and names of all your contacts will be collected and stored on our servers.
              We collect the minimum information required.This is requested in order for us to be able to enable you and your contacts to connect.
              Hellasfon does not read or listen to the content of your messages and/or calls made via Hellasfon App.  Once the messages have been
              delivered to their destination (which on average takes less than one second), they are automatically deleted from our servers.<br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Security</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Hellasfon has deployed a wide variety of technology and security
              features to ensure the privacy of customer information on its
              network. In addition, Hellasfon has implemented strict operations guidelines to ensure customer
              privacy is safeguarded at every level of its organization.
              Hellasfon will continue to revise policies and implement additional security features as new technologies becomes
              available.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Communications</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Hellasfon will not read, listen to or disclose to any third
              parties private e-mail, conversations, or other communications
              that are transmitted using Hellasfon services except
              as required to ensure proper operation of services or as otherwise
              authorized by law.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Unlawful Activity</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Hellasfon may use customer identifiable information to investigate
              and help prevent potentially unlawful activity or activities that
              threaten the integrity of service or network
              integrity or otherwise violate Hellasfon's Service Level
              Agreement.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Account Information</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Hellasfon will do its best to honor requests from customers for
              account information, for example, name, address, company, or
              billing information. The customer is
              responsible for ensuring that the information on file with
              Hellasfon is current and accurate.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Children's Privacy Policy</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Hellasfon does not sell products or services for purchase by
              children. Hellasfon does not knowingly solicit or collect customer
              identifiable information from children or
              teenagers under the age of eighteen. In addition, Hellasfon will
              not knowingly link to any third party web site that solicits or
              collects customer identifiable information from
              minors. If you believe that a minor has disclosed personally
              identifiable information to Hellasfon, please click here to
              contact us at so that the information can be removed.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Hellasfon Spam Policy</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Hellasfon has zero tolerance for spam. Spam complaints will be
              dealt with seriously and can result in losing Hellasfon
              privileges.
              <br />
            </Typography>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy-policy">Περισσότερες πληροφορίες</a>
            </span>

        </CookieConsent>
      </div>
    );
  }
}

Privacy.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Privacy);
