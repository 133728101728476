import React from "react";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhoneIcon from "@material-ui/icons/Phone";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ReactCountryFlag from "react-country-flag";
import axios from "axios";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

class RateSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      landline: "",
      mobile: "",
      sms: "",
      label: "Greece (30)",
      countryCode: "GR",
      data: [
        { country_code: "AD", description: "Andorra", prefix: "376" },
        {
          country_code: "AE",
          description: "United Arab Emirates",
          prefix: "971"
        },
        { country_code: "AF", description: "Afghanistan", prefix: "93" },
        {
          country_code: "AG",
          description: "Antigua and Barbuda",
          prefix: "1268"
        },
        { country_code: "AI", description: "Anguilla", prefix: "1264" },
        { country_code: "AL", description: "Albania", prefix: "355" },
        { country_code: "AM", description: "Armenia", prefix: "374" },
        { country_code: "AO", description: "Angola", prefix: "244" },
        { country_code: "AQ", description: "Antarctica", prefix: "672" },
        { country_code: "AR", description: "Argentina", prefix: "54" },
        { country_code: "AS", description: "American Samoa", prefix: "1684" },
        { country_code: "AT", description: "Austria", prefix: "43" },
        { country_code: "AU", description: "Australia", prefix: "61" },
        { country_code: "AW", description: "Aruba", prefix: "297" },
        { country_code: "AX", description: "Alland Islands", prefix: "358" },
        { country_code: "AZ", description: "Azerbaijan", prefix: "994" },
        {
          country_code: "BA",
          description: "Bosnia and Herzegovina",
          prefix: "387"
        },
        { country_code: "BB", description: "Barbados", prefix: "1246" },
        { country_code: "BD", description: "Bangladesh", prefix: "880" },
        { country_code: "BE", description: "Belgium", prefix: "32" },
        { country_code: "BF", description: "Burkina Faso", prefix: "226" },
        { country_code: "BG", description: "Bulgaria", prefix: "359" },
        { country_code: "BH", description: "Bahrain", prefix: "973" },
        { country_code: "BI", description: "Burundi", prefix: "257" },
        { country_code: "BJ", description: "Benin", prefix: "229" },
        { country_code: "BL", description: "Saint Barthelemy", prefix: "590" },
        { country_code: "BM", description: "Bermuda", prefix: "1441" },
        { country_code: "BN", description: "Brunei Darussalam", prefix: "673" },
        { country_code: "BO", description: "Bolivia", prefix: "591" },
        { country_code: "BR", description: "Brazil", prefix: "55" },
        { country_code: "BS", description: "Bahamas", prefix: "1242" },
        { country_code: "BT", description: "Bhutan", prefix: "975" },
        { country_code: "BV", description: "Bouvet Island", prefix: "47" },
        { country_code: "BW", description: "Botswana", prefix: "267" },
        { country_code: "BY", description: "Belarus", prefix: "375" },
        { country_code: "BZ", description: "Belize", prefix: "501" },

        { country_code: "CA", description: "Canada", prefix: "1" },

        {
          country_code: "CC",
          description: "Cocos (Keeling) Islands",
          prefix: "61"
        },
        {
          country_code: "CD",
          description: "Congo, Republic of the",
          prefix: "242"
        },
        {
          country_code: "CF",
          description: "Central African Republic",
          prefix: "236"
        },
        {
          country_code: "CG",
          description: "Congo, Democratic Republic of the",
          prefix: "243"
        },
        { country_code: "CH", description: "Switzerland", prefix: "41" },
        { country_code: "CI", description: "Cote d'Ivoire", prefix: "225" },
        { country_code: "CK", description: "Cook Islands", prefix: "682" },
        { country_code: "CL", description: "Chile", prefix: "56" },
        { country_code: "CM", description: "Cameroon", prefix: "237" },
        { country_code: "CN", description: "China", prefix: "86" },
        { country_code: "CO", description: "Colombia", prefix: "57" },
        { country_code: "CR", description: "Costa Rica", prefix: "506" },
        { country_code: "CU", description: "Cuba", prefix: "53" },
        { country_code: "CV", description: "Cape Verde", prefix: "238" },
        { country_code: "CW", description: "Curacao", prefix: "599" },
        { country_code: "CX", description: "Christmas Island", prefix: "61" },
        { country_code: "CY", description: "Cyprus", prefix: "357" },
        { country_code: "CZ", description: "Czech Republic", prefix: "420" },
        { country_code: "DE", description: "Germany", prefix: "49" },
        { country_code: "DJ", description: "Djibouti", prefix: "253" },
        { country_code: "DK", description: "Denmark", prefix: "45" },
        { country_code: "DM", description: "Dominica", prefix: "1767" },
        {
          country_code: "DO",
          description: "Dominican Republic",
          prefix: "1809"
        },
        { country_code: "DZ", description: "Algeria", prefix: "213" },
        { country_code: "EC", description: "Ecuador", prefix: "593" },
        { country_code: "EE", description: "Estonia", prefix: "372" },
        { country_code: "EG", description: "Egypt", prefix: "20" },
        { country_code: "EH", description: "Western Sahara", prefix: "212" },
        { country_code: "ER", description: "Eritrea", prefix: "291" },
        { country_code: "ES", description: "Spain", prefix: "34" },
        { country_code: "ET", description: "Ethiopia", prefix: "251" },
        { country_code: "FI", description: "Finland", prefix: "358" },
        { country_code: "FJ", description: "Fiji", prefix: "679" },
        {
          country_code: "FK",
          description: "Falkland Islands (Malvinas)",
          prefix: "500"
        },
        {
          country_code: "FM",
          description: "Micronesia, Federated States of",
          prefix: "691"
        },
        { country_code: "FO", description: "Faroe Islands", prefix: "298" },
        { country_code: "FR", description: "France", prefix: "33" },
        { country_code: "GA", description: "Gabon", prefix: "241" },
        { country_code: "GB", description: "UK", prefix: "44" },
        { country_code: "GD", description: "Grenada", prefix: "1473" },
        { country_code: "GE", description: "Georgia", prefix: "995" },
        { country_code: "GF", description: "French Guiana", prefix: "594" },
        { country_code: "GG", description: "Guernsey", prefix: "44" },
        { country_code: "GH", description: "Ghana", prefix: "233" },
        { country_code: "GI", description: "Gibraltar", prefix: "350" },
        { country_code: "GL", description: "Greenland", prefix: "299" },
        { country_code: "GM", description: "Gambia", prefix: "220" },
        { country_code: "GN", description: "Guinea", prefix: "224" },
        { country_code: "GP", description: "Guadeloupe", prefix: "590" },
        { country_code: "GQ", description: "Equatorial Guinea", prefix: "240" },
        { country_code: "GR", description: "Greece", prefix: "30" },
        {
          country_code: "GS",
          description: "South Georgia and the South Sandwich Islands",
          prefix: "500"
        },
        { country_code: "GT", description: "Guatemala", prefix: "502" },
        { country_code: "GU", description: "Guam", prefix: "1671" },
        { country_code: "GW", description: "Guinea-Bissau", prefix: "245" },
        { country_code: "GY", description: "Guyana", prefix: "592" },
        { country_code: "HK", description: "Hong Kong", prefix: "852" },
        {
          country_code: "HM",
          description: "Heard Island and McDonald Islands",
          prefix: "672"
        },
        { country_code: "HN", description: "Honduras", prefix: "504" },
        { country_code: "HR", description: "Croatia", prefix: "385" },
        { country_code: "HT", description: "Haiti", prefix: "509" },
        { country_code: "HU", description: "Hungary", prefix: "36" },
        { country_code: "ID", description: "Indonesia", prefix: "62" },
        { country_code: "IE", description: "Ireland", prefix: "353" },
        { country_code: "IL", description: "Israel", prefix: "972" },
        { country_code: "IM", description: "Isle of Man", prefix: "44" },
        { country_code: "IN", description: "India", prefix: "91" },
        {
          country_code: "IO",
          description: "British Indian Ocean Territory",
          prefix: "246"
        },
        { country_code: "IQ", description: "Iraq", prefix: "964" },
        {
          country_code: "IR",
          description: "Iran, Islamic Republic of",
          prefix: "98"
        },
        { country_code: "IS", description: "Iceland", prefix: "354" },
        { country_code: "IT", description: "Italy", prefix: "39" },
        { country_code: "JE", description: "Jersey", prefix: "44" },
        { country_code: "JM", description: "Jamaica", prefix: "1876" },
        { country_code: "JO", description: "Jordan", prefix: "962" },
        { country_code: "JP", description: "Japan", prefix: "81" },
        { country_code: "KE", description: "Kenya", prefix: "254" },
        { country_code: "KG", description: "Kyrgyzstan", prefix: "996" },
        { country_code: "KH", description: "Cambodia", prefix: "855" },
        { country_code: "KI", description: "Kiribati", prefix: "686" },
        { country_code: "KM", description: "Comoros", prefix: "269" },
        {
          country_code: "KN",
          description: "Saint Kitts and Nevis",
          prefix: "1869"
        },
        {
          country_code: "KP",
          label: "Korea, Democratic People's Republic of",
          prefix: "850"
        },
        { country_code: "KR", description: "Korea, Republic of", prefix: "82" },
        { country_code: "KW", description: "Kuwait", prefix: "965" },
        { country_code: "KY", description: "Cayman Islands", prefix: "1345" },
        { country_code: "KZ", description: "Kazakhstan", prefix: "7" },
        {
          country_code: "LA",
          label: "Lao People's Democratic Republic",
          prefix: "856"
        },
        { country_code: "LB", description: "Lebanon", prefix: "961" },
        { country_code: "LC", description: "Saint Lucia", prefix: "1758" },
        { country_code: "LI", description: "Liechtenstein", prefix: "423" },
        { country_code: "LK", description: "Sri Lanka", prefix: "94" },
        { country_code: "LR", description: "Liberia", prefix: "231" },
        { country_code: "LS", description: "Lesotho", prefix: "266" },
        { country_code: "LT", description: "Lithuania", prefix: "370" },
        { country_code: "LU", description: "Luxembourg", prefix: "352" },
        { country_code: "LV", description: "Latvia", prefix: "371" },
        { country_code: "LY", description: "Libya", prefix: "218" },
        { country_code: "MA", description: "Morocco", prefix: "212" },
        { country_code: "MC", description: "Monaco", prefix: "377" },
        {
          country_code: "MD",
          description: "Moldova, Republic of",
          prefix: "373"
        },
        { country_code: "ME", description: "Montenegro", prefix: "382" },
        {
          country_code: "MF",
          description: "Saint Martin (French part)",
          prefix: "590"
        },
        { country_code: "MG", description: "Madagascar", prefix: "261" },
        { country_code: "MH", description: "Marshall Islands", prefix: "692" },
        {
          country_code: "MK",
          description: "Macedonia, the Former Yugoslav Republic of",
          prefix: "389"
        },
        { country_code: "ML", description: "Mali", prefix: "223" },
        { country_code: "MM", description: "Myanmar", prefix: "95" },
        { country_code: "MN", description: "Mongolia", prefix: "976" },
        { country_code: "MO", description: "Macao", prefix: "853" },
        {
          country_code: "MP",
          description: "Northern Mariana Islands",
          prefix: "1670"
        },
        { country_code: "MQ", description: "Martinique", prefix: "596" },
        { country_code: "MR", description: "Mauritania", prefix: "222" },
        { country_code: "MS", description: "Montserrat", prefix: "1664" },
        { country_code: "MT", description: "Malta", prefix: "356" },
        { country_code: "MU", description: "Mauritius", prefix: "230" },
        { country_code: "MV", description: "Maldives", prefix: "960" },
        { country_code: "MW", description: "Malawi", prefix: "265" },
        { country_code: "MX", description: "Mexico", prefix: "52" },
        { country_code: "MY", description: "Malaysia", prefix: "60" },
        { country_code: "MZ", description: "Mozambique", prefix: "258" },
        { country_code: "NA", description: "Namibia", prefix: "264" },
        { country_code: "NC", description: "New Caledonia", prefix: "687" },
        { country_code: "NE", description: "Niger", prefix: "227" },
        { country_code: "NF", description: "Norfolk Island", prefix: "672" },
        { country_code: "NG", description: "Nigeria", prefix: "234" },
        { country_code: "NI", description: "Nicaragua", prefix: "505" },
        { country_code: "NL", description: "Netherlands", prefix: "31" },
        { country_code: "NO", description: "Norway", prefix: "47" },
        { country_code: "NP", description: "Nepal", prefix: "977" },
        { country_code: "NR", description: "Nauru", prefix: "674" },
        { country_code: "NU", description: "Niue", prefix: "683" },
        { country_code: "NZ", description: "New Zealand", prefix: "64" },
        { country_code: "OM", description: "Oman", prefix: "968" },
        { country_code: "PA", description: "Panama", prefix: "507" },
        { country_code: "PE", description: "Peru", prefix: "51" },
        { country_code: "PF", description: "French Polynesia", prefix: "689" },
        { country_code: "PG", description: "Papua New Guinea", prefix: "675" },
        { country_code: "PH", description: "Philippines", prefix: "63" },
        { country_code: "PK", description: "Pakistan", prefix: "92" },
        { country_code: "PL", description: "Poland", prefix: "48" },
        {
          country_code: "PM",
          description: "Saint Pierre and Miquelon",
          prefix: "508"
        },
        { country_code: "PN", description: "INMARSAT - AERO", prefix: "8705" },
        {
          country_code: "PN",
          description: "INMARSAT - BGAN/FBB/SBB HSD",
          prefix: "87078"
        },
        { country_code: "PN", description: "INMARSAT - M", prefix: "8706" },
        {
          country_code: "PN",
          description: "INMARSAT BGAN SNAC",
          prefix: "87077"
        },
        {
          country_code: "PN",
          description: "INMARSAT Fleet/swift HSD",
          prefix: "87060"
        },
        {
          country_code: "PN",
          description: "INMARSAT MINI-M (SNAC) EXCEPT PACIFIC",
          prefix: "87076"
        },
        { country_code: "PR", description: "Puerto Rico", prefix: "1787" },
        { country_code: "PR", description: "Puerto Rico", prefix: "1939" },
        {
          country_code: "PS",
          description: "Palestine, State of",
          prefix: "970"
        },
        { country_code: "PT", description: "Portugal", prefix: "351" },
        { country_code: "PW", description: "Palau", prefix: "680" },
        { country_code: "PY", description: "Paraguay", prefix: "595" },
        { country_code: "QA", description: "Qatar", prefix: "974" },
        { country_code: "RE", description: "Reunion", prefix: "262" },
        { country_code: "RO", description: "Romania", prefix: "40" },
        { country_code: "RS", description: "Serbia", prefix: "381" },
        { country_code: "RU", description: "Russian Federation", prefix: "7" },
        { country_code: "RW", description: "Rwanda", prefix: "250" },
        { country_code: "SA", description: "Saudi Arabia", prefix: "966" },
        { country_code: "SB", description: "Solomon Islands", prefix: "677" },
        { country_code: "SC", description: "Seychelles", prefix: "248" },
        { country_code: "SD", description: "Sudan", prefix: "249" },
        { country_code: "SE", description: "Sweden", prefix: "46" },
        { country_code: "SG", description: "Singapore", prefix: "65" },
        { country_code: "SH", description: "Saint Helena", prefix: "290" },
        { country_code: "SI", description: "Slovenia", prefix: "386" },
        {
          country_code: "SJ",
          description: "Svalbard and Jan Mayen",
          prefix: "47"
        },
        { country_code: "SK", description: "Slovakia", prefix: "421" },
        { country_code: "SL", description: "Sierra Leone", prefix: "232" },
        { country_code: "SM", description: "San Marino", prefix: "378" },
        { country_code: "SN", description: "Senegal", prefix: "221" },
        { country_code: "SO", description: "Somalia", prefix: "252" },
        { country_code: "SR", description: "Suriname", prefix: "597" },
        { country_code: "SS", description: "South Sudan", prefix: "211" },
        {
          country_code: "ST",
          description: "Sao Tome and Principe",
          prefix: "239"
        },
        { country_code: "SV", description: "El Salvador", prefix: "503" },
        {
          country_code: "SX",
          description: "Sint Maarten (Dutch part)",
          prefix: "1721"
        },
        {
          country_code: "SY",
          description: "Syrian Arab Republic",
          prefix: "963"
        },
        { country_code: "SZ", description: "Swaziland", prefix: "268" },
        {
          country_code: "TC",
          description: "Turks and Caicos Islands",
          prefix: "1649"
        },
        { country_code: "TD", description: "Chad", prefix: "235" },
        {
          country_code: "TF",
          description: "French Southern Territories",
          prefix: "262"
        },
        { country_code: "TG", description: "Togo", prefix: "228" },
        { country_code: "TH", description: "Thailand", prefix: "66" },
        { country_code: "TJ", description: "Tajikistan", prefix: "992" },
        { country_code: "TK", description: "Tokelau", prefix: "690" },
        { country_code: "TL", description: "Timor-Leste", prefix: "670" },
        { country_code: "TM", description: "Turkmenistan", prefix: "993" },
        { country_code: "TN", description: "Tunisia", prefix: "216" },
        { country_code: "TO", description: "Tonga", prefix: "676" },
        { country_code: "TR", description: "Turkey", prefix: "90" },
        {
          country_code: "TT",
          description: "Trinidad and Tobago",
          prefix: "1868"
        },
        { country_code: "TV", description: "Tuvalu", prefix: "688" },
        {
          country_code: "TW",
          description: "Taiwan, Province of China",
          prefix: "886"
        },
        {
          country_code: "TZ",
          description: "United Republic of Tanzania",
          prefix: "255"
        },
        { country_code: "UA", description: "Ukraine", prefix: "380" },
        { country_code: "UG", description: "Uganda", prefix: "256" },
        { country_code: "US", description: "United States", prefix: "1" },
        { country_code: "UY", description: "Uruguay", prefix: "598" },
        { country_code: "UZ", description: "Uzbekistan", prefix: "998" },
        {
          country_code: "VA",
          description: "Holy See (Vatican City State)",
          prefix: "379"
        },
        {
          country_code: "VC",
          description: "Saint Vincent and the Grenadines",
          prefix: "1784"
        },
        { country_code: "VE", description: "Venezuela", prefix: "58" },
        {
          country_code: "VG",
          description: "British Virgin Islands",
          prefix: "1284"
        },
        {
          country_code: "VI",
          description: "US Virgin Islands",
          prefix: "1340"
        },
        { country_code: "VN", description: "Vietnam", prefix: "84" },
        { country_code: "VU", description: "Vanuatu", prefix: "678" },
        { country_code: "WF", description: "Wallis and Futuna", prefix: "681" },
        { country_code: "WS", description: "Samoa", prefix: "685" },
        { country_code: "XK", description: "Kosovo", prefix: "383" },
        { country_code: "YE", description: "Yemen", prefix: "967" },
        { country_code: "YT", description: "Mayotte", prefix: "262" },
        { country_code: "ZA", description: "South Africa", prefix: "27" },
        { country_code: "ZM", description: "Zambia", prefix: "260" },
        { country_code: "ZW", description: "Zimbabwe", prefix: "263" }
      ]
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { data } = this.state;
    try {
      this.setState({
        data: data.map(d => ({
          label: d.description + " (" + d.prefix + ")",
          prefix: d.prefix,
          country_code: d.country_code,
          description: d.description
        }))
      });
      try {
        const res = await axios.post(
          `https://sip1.easyvoipcall.com/pbxres/api/get_rates`,
          { prefix: "30" }
        );
        let mob = res.data.filter(d => d.description.indexOf("MOBILE") > -1);
        let sms = "";
        if (mob[0] === undefined) {
          mob = "";
        } else {
          sms = mob[0].sms;
          mob = mob[0].voice_rate; 
        }
        let land = res.data.filter(d => d.prefix === "302");
        if (land[0] === undefined) {
          land = "";
        } else land = land[0].voice_rate;
        this.setState({
          loading: false,
          landline: land,
          mobile: mob,
          sms: sms,
          label: "Greece (30)"
        });
      } catch (e) {
        this.setState({ loading: false, label: "Greece (30)" });
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
  }

  onChange = async (e, value) => {
    if (value === null) {
      return;
    } else {
      this.setState({ loading: true });
      try {
        const res = await axios.post(
          `https://sip1.easyvoipcall.com/pbxres/api/get_rates`,
          { prefix: value.description === 'Canada' ? '1204' : value.description === 'United States' ? "1201" : value.prefix }
        );
        let mob = res.data.filter(d => d.description.indexOf("MOBILE") > -1);
        let sms = "";
        if (mob[0] === undefined) {
          mob = "";
        } else {
          sms = mob[0].sms; 
          mob = mob[0].voice_rate; 
        }
        let land = res.data.filter(d => d.prefix === value.description === 'Canada' ? '1204' : value.description === 'United States' ? "1201" : value.prefix);
        if (land[0] === undefined) {
          land = "";
        } else land = land[0].voice_rate;
        await this.setState({
          loading: false,
          landline: land,
          mobile: mob,
          sms: sms,
          label: value.description === "Canada" ? "Canada (1)" : value.label,
          countryCode: value.country_code
        });
      } catch (e) {
        await this.setState({
          loading: false,
          label: "Greece (30)",
          countryCode: "GR"
        });
        console.log(e);
      }
    }
  };

  render() {
    const { loading, data, landline, mobile, sms, label, countryCode } = this.state;
    return (
      <div>
        <GridContainer>
          <div
            style={{
              paddingTop: "20px",
              backgroundColor: "rgb(0, 154, 252)",
              borderRadius: "5px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingBottom: "20px",
              borderColor: "#006699",
              boxShadow: "1px 1px 5px #333",
              width: "100%"
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white", marginBottom: "15px" }}
            >
              HELLASFON Τιμοκατάλογος κλήσεων και SMS για Ελλάδα και Εξωτερικό
            </Typography>

            <Autocomplete
              onChange={this.onChange}
              loading={loading}
              options={data}
              getOptionLabel={option => option.label}
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "5px"
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Επιλέξτε χώρα"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              renderOption={option => {
                return (
                  <React.Fragment>
                    <ReactCountryFlag code={option.country_code} svg />
                    <Typography variant="body1">{option.label}</Typography>
                  </React.Fragment>
                );
              }}
            />

          </div>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Typography variant="h5" color="primary">
              Χρεώσεις κλήσεων για <ReactCountryFlag code={countryCode} svg />{" "}
              {label}
            </Typography>
          </GridItem>
        </GridContainer>
        {landline === "" ? null : (
          <GridContainer spacing={2}>
            <GridItem xs={12} sm={12} md={5}>
              <Typography variant="h4" color="primary">
                <PhoneIcon /> Σταθερά
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <Typography variant="h4" color="primary">
                {landline} €/min (EURO)
              </Typography>
            </GridItem>
          </GridContainer>
        )}
        <Divider />
        {mobile === "" ? null : (
          <GridContainer spacing={2}>
            <GridItem xs={12} sm={12} md={5}>
              <Typography variant="h4" color="primary">
                <PhoneAndroidIcon /> Κινητά
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <Typography variant="h4" color="primary">
                {mobile} €/min (EURO)
              </Typography>
            </GridItem>
          </GridContainer>
          )}
          {(sms === "" || sms === null )? null : (
          <React.Fragment>  
          <Divider />
          <GridContainer spacing={2}>
           <GridItem xs={12} sm={12} md={5}>
             <Typography variant="h4" color="primary">
               <PhoneAndroidIcon /> SMS
             </Typography>
           </GridItem>
           <GridItem xs={12} sm={12} md={7}>
             <Typography variant="h4" color="primary">
               {sms} €/sms (EURO)
             </Typography>
           </GridItem>
         </GridContainer>
         </React.Fragment> 
        )}
        <Divider />
        <GridItem xs={12} sm={12} md={12}>
            <Typography variant="caption" color="primary">
              * Οι τιμές περιλαμβάνουν ΦΠΑ 24% και Eιδικό Tέλος Tηλεφωνίας
            </Typography>
        </GridItem>
      </div>
    );
  }
}

RateSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(RateSection);
