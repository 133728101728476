export const GET_PBX_PLAN_PACKS = 'GET_PBX_PLAN_PACKSS';
export const GET_RETAIL_PLAN_PACKS = 'GET_RETAIL_PLAN_PACKS';
export const SELECT_PLAN_PACK = 'SELECT_PLAN_PACK';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const GET_LOCAL_ARES = 'GET_LOCAL_ARES';
export const VERIFY_MOBILE = 'VERIFY_MOBILE';
export const VERIFY_CODE = 'VERIFY_CODE';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_PLANS = 'GET_PLANS';
export const UPDATE_ALPHA_ORDER = 'UPDATE_ALPHA_ORDER';
export const CHECK_ORDER = 'CHECK_ORDER';
export const GET_TARIFF_RATES = 'GET_TARIFF_RATES';
export const APP_LOADING = 'APP_LOADING';

