import React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Slider from "@material-ui/core/Slider";
import PlansTable from "../../Sections/PlansTableSection.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import axios from "axios";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import {
  createOrder,
  createOrderSIP,
  updateAlphaOrder,
  updateDirectOrder,
  loadingFn,
} from "actions/retailActions";

class Review extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const {
      classes,
      retail_plan_pack,
      email,
      phonemobile,
      address,
      tk,
      city,
      local_areas,
      changePhone,
      phonemove,
      local_area,
    } = props;

    const did_name = local_areas.filter((local) => local.id === local_area);

    this.state = {
      affiliate: "",
      errors: {
        users: false,
      },
      submited: false,
      classSnackbar: classes.hideSnackbar,
      classSnackbarPay: classes.hideSnackbar,
      response: "",
      response_payment: "",
      value_radio: "paypal",
      loading_order: false,
      marchantEnable: true,
      paymentID: "",
      paymentToken: "",
      plans: [],
      top_up: "",
      topup: [
        {
          value: 0.0,
          label: "0 €",
        },
        {
          value: 10.0,
          label: "10 €",
        },
        {
          value: 20.0,
          label: "20 €",
        },
        {
          value: 50.0,
          label: "50 €",
        },
        {
          value: 100.0,
          label: "100 €",
        },
      ],
      products: [
        {
          id: 1,
          name: retail_plan_pack.name,
          desc:
            "Αρχικό κόστος: " +
            retail_plan_pack.startup_cost +
            " Μηνιαίο κόστος: " +
            retail_plan_pack.period_cost,
          price: (parseFloat(retail_plan_pack.startup_cost)+parseFloat(retail_plan_pack.period_cost)).toFixed(2),
        },
        {
          id: 2,
          name: changePhone
            ? "30" + phonemove
            : did_name[0].name + " (" + did_name[0].area_code + ")",
          desc: "Μηνιαίο Κόστος: 0.00",
          price: (0.0).toFixed(2),
        },
        {
          id: 3,
          name: "Μονάδες για κλήσεις εκτός πακέτου",
          desc: "",
          price: (0.0).toFixed(2),
        },
      ],
      addresses: [address, city, tk, email, phonemobile],
    };
  }
  selectPlan = async (id_plan, name, period_cost, selected) => {
    if (selected) {
      let newState = this.state.products.concat({
        id: id_plan,
        name: name,
        price: period_cost,
      });
      let newPlans = this.state.plans.concat(id_plan);
      await this.setState({ plans: newPlans, products: newState });
    } else {
      for (let i = 0; i < this.state.products.length; i++) {
        if (this.state.products[i].id === id_plan) {
          let newState = this.state.products.filter(
            (product) => product.id !== id_plan
          );
          let newPlans = this.state.plans.filter((plan) => plan !== id_plan);
          this.setState({ plans: newPlans, products: newState });
        }
      }
    }
  };
  onChangeCommitted = (e, value) => {
    let newState = Object.assign({}, this.state);
    newState.products[2].price = value.toFixed(2);
    this.setState(newState);
    this.setState({ top_up: value.toFixed(2) });
  };

  handleChangeAF = (e) => {
    this.setState(
      e.target.id === "affiliate" && e.target.value.length < 3
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: false,
            },
          }
    );
  };

  handleChange = (e) =>
    this.setState({
      value_radio: e.target.value,
    });

  onDirectBank = async (e) => {
    const { classes, verify_mobile, company, retail_plan_pack } = this.props;

    const { value_radio, loading_order, plans, top_up,  products} = this.state;

    let total_cost = 0;
    for (let num of products) {
      total_cost = total_cost + parseFloat(num.price);
    }


    if (!loading_order) {
      this.setState({
        loading_order: true,
        marchantEnable: false,
      });

      // update order items
      const res = await this.props.updateDirectOrder(
        plans,
        top_up,
        verify_mobile,
        value_radio
      );
      if (res) {
        this.setState({
          loading_order: false,
          classSnackbar: classes.errorSnackbar,
          response:
            "Κάτι πήγε στραβά Παραγγελία - #" +
            verify_mobile.order_id +
            " επικοινωνήστε με την υποστήριξη.",
        });
      } else {
       /* this.setState({
          submited: true,
        });
        window.scrollTo(0, 0);
      */
        let mark = company === "" ? 'retail' : 'sip';
        window.location ="/sucess/" + verify_mobile.order_id + "/EUR/"+ total_cost +"/reg/"+ mark +"/" + retail_plan_pack.id_plan_pack + "/banktransfer";
      }
    }
  };
  onPaypal = async (e) => {
    const { classes, verify_mobile } = this.props;

    const { value_radio, loading_order, plans, top_up, products } = this.state;

    let total = 0;
    for (let num of products) {
      total = total + parseFloat(num.price);
    }

    if (!loading_order) {
      this.setState({
        loading_order: true,
        marchantEnable: false,
      });

      // update order items

      const res = await this.props.updateAlphaOrder(
        plans,
        top_up,
        verify_mobile,
        value_radio
      );

      if (res) {
        this.setState({
          loading_order: false,
          classSnackbar: classes.errorSnackbar,
          response:
            "Κάτι πήγε στραβά Παραγγελία - #" +
            verify_mobile.order_id +
            " Επικοινωνήστε με την υποστήριξη.",
        });
      } else {
        let form = document.createElement("form");
        form.method = "POST";
        form.action = "https://www.paypal.com/cgi-bin/webscr";

        const bn = "PP-SubscribeBF";
        const business = "christo@hellasfon.com";
        const item_name = "Hellasfon Subscription";
        const a1 = total.toFixed(2);
        const p1 = "1";
        const t1 = "M";
        const a3 = total.toFixed(2);
        const p3 = "1";
        const t3 = "M";
        const src = "1";
        const no_note = "1";
        const rm = "1";
        const charset = "utf-8";
        const cancel_return =
          "https://myaccount.hellasfon.com/pbxres/api/" +
          verify_mobile.order_id +
          "paypal.cancel";
        const currency_code = "EUR";
        const lc = "GR";
        const custom = verify_mobile.order_id;
        const paymentaction = "sale";

        const content = [
          {
            Name: "cmd",
            Value: "_xclick-subscriptions",
          },
          {
            Name: "bn",
            Value: bn,
          },
          {
            Name: "business",
            Value: business,
          },
          {
            Name: "item_name",
            Value: item_name,
          },
          {
            Name: "a1",
            Value: a1,
          },
          {
            Name: "p1",
            Value: p1,
          },
          {
            Name: "t1",
            Value: t1,
          },
          {
            Name: "a3",
            Value: a3,
          },

          {
            Name: "p3",
            Value: p3,
          },
          {
            Name: "t3",
            Value: t3,
          },
          {
            Name: "src",
            Value: src,
          },
          {
            Name: "no_note",
            Value: no_note,
          },
          {
            Name: "rm",
            Value: rm,
          },
          {
            Name: "charset",
            Value: charset,
          },
          {
            Name: "form-charset",
            Value: "utf-8",
          },

          {
            Name: "return",
            Value:
              "https://myaccount.hellasfon.com/pbxres/api/" +
              verify_mobile.order_id +
              "paypal.back",
          },

          {
            Name: "cancel_return",
            Value: cancel_return,
          },
          {
            Name: "currency_code",
            Value: currency_code,
          },
          {
            Name: "lc",
            Value: lc,
          },
          {
            Name: "custom",
            Value: custom,
          },
          {
            Name: "paymentaction",
            Value: paymentaction,
          },
        ];

        for (let i in content) {
          let f = content[i];
          var el = document.createElement("input");
          el.value = f.Value;
          el.name = f.Name;
          form.appendChild(el);
        }

        document.body.appendChild(form);
        form.submit();
      }
    }
  };
  onAlphabank = async (e) => {
    const { email, address, tk, city, classes, verify_mobile } = this.props;

    const { value_radio, loading_order, plans, top_up, products } = this.state;

    let total = 0;
    for (let num of products) {
      total = total + parseFloat(num.price);
    }

    if (!loading_order) {
      this.setState({
        loading_order: true,
        marchantEnable: false,
      });

      var currentTime = new Date();
      currentTime.setDate(currentTime.getDate() + 1825);
      var month = currentTime.getMonth() + 1;
      var day = currentTime.getDate();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      var year = currentTime.getFullYear();

      const mid = "0023296635";
      const lang = "gr";
      const deviceCategory = "0";
      const orderid = verify_mobile.order_id;
      const orderDesc = "Hellasfon Σειρά #" + verify_mobile.order_id;
      const orderAmount = total.toFixed(2);
      const currency = "EUR";
      const payerEmail = email;
      const billCountry = "GR";
      const billState = "";
      const billZip = tk;
      const billCity = city;
      const billAddress = address;
      const payMethod = "";
      const trType = "1";
      const extRecurringfrequency = "28";
      const extRecurringenddate = year + "" + month + "" + day;
      const confirmUrl =
        "https://myaccount.hellasfon.com/pbxres/api/alphabank.back";
      const cancelUrl =
        "https://myaccount.hellasfon.com/pbxres/api/alphabank.cancel";

      const string = {
        str:
          mid +
          lang +
          deviceCategory +
          orderid +
          orderDesc +
          orderAmount +
          currency +
          payerEmail +
          billCountry +
          billState +
          billZip +
          billCity +
          billAddress +
          payMethod +
          trType +
          extRecurringfrequency +
          extRecurringenddate +
          confirmUrl +
          cancelUrl,
      };

      // sent to api str return digest
      const result = await axios.post(
        `https://sip1.easyvoipcall.com/pbxres/api/fsa4324567hgfd`,
        string
      );

      if (result.data) {
        // update order items
        const digest = result.data;
        const res = await this.props.updateAlphaOrder(
          plans,
          top_up,
          verify_mobile,
          value_radio
        );

        if (res) {
          this.setState({
            loading_order: false,
            classSnackbar: classes.errorSnackbar,
            response:
              "Κάτι πήγε στραβά Παραγγελία - #" +
              verify_mobile.order_id +
              " Επικοινωνήστε με την υποστήριξη.",
          });
        } else {
          let form = document.createElement("form");
          form.method = "POST";
          form.action = "https://www.alphaecommerce.gr/vpos/shophandlermpi";

          const content = [
            {
              Name: "mid",
              Value: mid,
            },
            {
              Name: "lang",
              Value: lang,
            },
            {
              Name: "deviceCategory",
              Value: deviceCategory,
            },
            {
              Name: "orderid",
              Value: orderid,
            },
            {
              Name: "orderDesc",
              Value: orderDesc,
            },
            {
              Name: "orderAmount",
              Value: orderAmount,
            },
            {
              Name: "currency",
              Value: currency,
            },
            {
              Name: "payerEmail",
              Value: payerEmail,
            },

            {
              Name: "billCountry",
              Value: billCountry,
            },
            {
              Name: "billState",
              Value: billState,
            },
            {
              Name: "billZip",
              Value: billZip,
            },
            {
              Name: "billCity",
              Value: billCity,
            },
            {
              Name: "billAddress",
              Value: billAddress,
            },
            {
              Name: "payMethod",
              Value: payMethod,
            },
            {
              Name: "trType",
              Value: trType,
            },

            {
              Name: "extRecurringfrequency",
              Value: extRecurringfrequency,
            },

            {
              Name: "extRecurringenddate",
              Value: extRecurringenddate,
            },

            {
              Name: "confirmUrl",
              Value: confirmUrl,
            },
            {
              Name: "cancelUrl",
              Value: cancelUrl,
            },
            {
              Name: "digest",
              Value: digest,
            },
          ];

          for (let i in content) {
            let f = content[i];
            var el = document.createElement("input");
            el.value = f.Value;
            el.name = f.Name;
            form.appendChild(el);
          }

          document.body.appendChild(form);
          form.submit();
        }
      } else {
        this.setState({
          loading_order: false,
          classSnackbar: classes.errorSnackbar,
          response:
            "Κάτι πήγε στραβά Παραγγελία - #" +
            verify_mobile.order_id +
            " επικοινωνήστε με την υποστήριξη.",
        });
      }
    }
  };
  onClick = async (e) => {
    e.preventDefault();

    const {
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      changePhone,
      phonemove,
      local_area,
      city,
      aoy,
    } = this.props;

    this.props.handleBack(
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      changePhone,
      phonemove,
      local_area,
      city,
      aoy
    );
  };

  render() {
    const {
      products,
      addresses,
      value_radio,
      loading_order,
      response,
      response_payment,
      classSnackbar,
      classSnackbarPay,
      marchantEnable,
      topup,
      affiliate,
      errors
    } = this.state;
    const {
      activeStep,
      classes,
      firstName,
      lastName,
      company,
      vat,
      aoy,
      plans
    } = this.props;

    let total = 0;
    for (let num of products) {
      total = total + parseFloat(num.price);
    }

    return (
      <React.Fragment>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Προσωπικα στοιχεια
                </Typography>
                <Typography gutterBottom>{company}</Typography>
                <Typography gutterBottom>
                  {firstName} {lastName}
                </Typography>
                <Typography gutterBottom>{addresses.join(", ")}</Typography>
                <Typography gutterBottom>{vat}</Typography>
                <Typography gutterBottom>{aoy}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Προαιρετικά Προγραμματα και Χρόνος Ομιλίας
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6">Προγράμματα</Typography>
                <GridContainer spacing={2}>
                  {plans.map((plan) => (
                    <PlansTable
                      plan={plan}
                      key={plan.id_plan}
                      selectPlan={this.selectPlan}
                      disabled={loading_order}
                    />
                  ))}
                </GridContainer>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6">Χρόνος Ομιλίας</Typography>
                <Typography variant="body1">
                  Προσθέσετε μονάδες για κλήσεις πέρα του πακέτου σας. Οι μονάδες δεν
                  λήγουν ποτέ!
                </Typography>
                <Slider
                  onChangeCommitted={this.onChangeCommitted}
                  defaultValue={0}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={10}
                  marks={topup}
                  disabled={loading_order}
                />
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>
              ΠΕΡΙΛΗΨΗ Εγγραφή
            </Typography>
            <List disablePadding>
              {products.map((product) => (
                <React.Fragment key={product.id}>
                  <ListItem className={classes.listItem} key={product.name}>
                    <ListItemText
                      primary={product.name}
                      secondary={product.desc}
                    />
                    <Typography variant="h6">{product.price}</Typography>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
              <ListItem className={classes.listItem}>
                <ListItemText primary="Σύνολο" />
                <Typography variant="h5" className={classes.total}>
                  € {total.toFixed(2)}
                </Typography>
              </ListItem>
            </List>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Τροπος πληρωμης
                </Typography>
                <RadioGroup
                  aria-label="payment"
                  id="payment"
                  value={value_radio}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="paypal"
                    control={<Radio />}
                    label={
                      <img
                        src="/img/paypal-logo.svg"
                        alt="Paypal"
                        height="30"
                      />
                    }
                  />
                  <FormControlLabel
                    value="alphabank"
                    control={<Radio />}
                    label={
                      <img
                        src="/img/credit_cards.png"
                        alt="CreditCards"
                        height="30"
                      />
                    }
                  />
                  <FormControlLabel
                    style={{ color: "#495057" }}
                    value="banktransfer"
                    control={<Radio />}
                    label={"Τραπεζική κατάθεση"}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                ΣΥΝΕΡΓΑΤΕΣ
              </Typography>
            </Grid>
            <GridItem xs={12} sm={6} md={3}>
              <CustomInput
                error={errors.affiliate ? true : false}
                required
                type="affiliate"
                labelText="κωδικός συνεργατών"
                id="affiliate"
                name="affiliate"
                value={affiliate}
                onChange={this.handleChangeAF}
                onFocus={this.handleChangeAF}
                onBlur={this.handleChangeAF}
                disabled={loading_order}
                helperText="Εισαγάγετε τον κωδικό συνεργατών"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <Divider />
            <ListItem className={classes.listItem}>
              <ListItemText primary="Σύνολο" />
              <Typography variant="h6" className={classes.total}>
                € {total.toFixed(2)}
              </Typography>
            </ListItem>
            <Divider />
            <GridContainer className={classes.buttons}>
              <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
                {activeStep !== 0 && marchantEnable && (
                  <Button
                    onClick={this.onClick}
                    className={classes.button}
                    disabled={loading_order}
                  >
                    Πίσω
                  </Button>
                )}
                {value_radio === "paypal" && marchantEnable ? (
                  <Button
                    onClick={this.onPaypal}
                    className={classes.button}
                    disabled={loading_order}
                    color="primary"
                  >
                    υποβάλετε την παραγγελία
                  </Button>
                ) : value_radio === "alphabank" && marchantEnable ? (
                  <Button
                    onClick={this.onAlphabank}
                    className={classes.button}
                    disabled={loading_order}
                    color="primary"
                  >
                    υποβάλετε την παραγγελία
                  </Button>
                ) : value_radio === "banktransfer" && marchantEnable ? (
                  <Button
                    onClick={this.onDirectBank}
                    className={classes.button}
                    disabled={loading_order}
                    color="primary"
                  >
                    υποβάλετε την παραγγελία
                  </Button>
                ) : null}
                {loading_order && (
                  <React.Fragment>
                    <b>
                      {" "}
                      <Typography variant="h6" style={{ textAlign: "left" }}>
                        Παρακαλώ περιμένετε ...
                      </Typography>
                    </b>
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </React.Fragment>
                )}
                <SnackbarContent
                  className={classSnackbarPay}
                  aria-describedby="client-snackbar_payment"
                  message={response_payment}
                />
                <SnackbarContent
                  className={classSnackbar}
                  aria-describedby="client-snackbar"
                  message={response}
                />
              </GridItem>
            </GridContainer>
          </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  retail_plan_pack: state.retail_reducer.retail_plan_pack,
  plans: state.retail_reducer.plans,
  verify_mobile: state.retail_reducer.verify_mobile,
  create_retail: state.retail_reducer.create_retail,
  local_areas: state.retail_reducer.local_areas,
});

export default connect(
  mapStateToProps,
  {
    createOrder,
    createOrderSIP,
    updateAlphaOrder,
    updateDirectOrder,
    loadingFn,
  }
)(withStyles(workStyle)(Review));
