import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import { CardMedia } from "@material-ui/core";
import CookieConsent from "react-cookie-consent";

class CompanyPage extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.eettClick = this.eettClick.bind(this);
    this.protothema = this.protothema.bind(this);
    this.neakriti = this.neakriti.bind(this);
    this.patris = this.patris.bind(this);
  }

  eettClick() {
    window.location.assign(
      "https://www.eett.gr/opencms/opencms/EETT/Electronic_Communications/Telecoms/Licencing/search.html?cat=tel"
    );
  }
  protothema() {
    window.location.assign(
      "https://hellasfon.com/sites/default/files/proto_thema.pdf"
    );
  }
  neakriti() {
    window.location.assign("https://hellasfon.com/mailgraphics/neakriti.png");
  }
  patris() {
    window.location.assign(
      "https://hellasfon.com/mailgraphics/patrisdeth-2019.pdf"
    );
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            {"Η εταιρία"}
          </h1>
          <div className={classes.container}>
            <div style={{ color: "black" }}>
              H Hellasfon Networks είναι πάροχος σταθερής τηλεφωνίας και
              ευρυζωνικών υπηρεσιών με έδρα το Ηράκλειο Κρήτης από το 1999. Η
              εταιρία δραστηριοποιείται πανελλαδικά και παρέχει ένα ευρύ φάσμα
              προϊόντων και υπηρεσιών.
              <h2 style={{ color: "#0178e2" }}>ΥΠΗΡΕΣΙΕΣ</h2>
              H Hellasfon επενδύει συνεχώς σε νέες τεχνολογίες και υποδομές.
              Παρέχει ολοκληρωμένες λύσεις επικοινωνιών που απευθύνονται σε
              οικιακούς καταναλωτές και σε επιχειρήσεις.
              <br />
              Όλες οι καινούριες υπηρεσίες της Hellasfon βασίζονται σε νέες
              καινοτόμες τεχνολογίες , προσφέροντας ευελιξία στην επικοινωνία.
              Συνδυάζοντας τα δίκτυα σταθερής τηλεφωνίας με ευρυζωνικές
              υπηρεσίες προσφέρουμε στους πελάτες μας δωρεάν εφαρμογές για εύκολη
              πρόσβαση στην υπηρεσία απευθείας από το κινητό τους. HELLASFON
              home για τους ιδιώτες και EVCOFFICE / SIP Trunk για τις
              επιχειρήσεις. Στόχος μας είναι η υλοποίηση ενός λειτουργικού,
              απόδοτικού και οικονομικού πακέτου υπηρεσιών που να ικανοποιήσει
              τις ιδιαίτερες απαιτήσεις σας και να μεγιστοποιήσει τα οφέλη για
              την επιχείρηση και τον ιδιώτη!
              <h2 style={{ color: "#0178e2" }}>ΆΔΕΙΕΣ</h2>
              <a href="https://www.eett.gr/opencms/opencms/EETT/Electronic_Communications/Telecoms/Licencing/search.html?cat=tel">
                <img
                  src={require("../assets/img/eett.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
              </a>
              <div style={{ color: "#0178e2" }}>
                99-176 HELLASFON NETWORKS SA ΙΔΟΜΕΝΕΩΣ 26 71202 ΗΡΑΚΛΕΙΟ
                +30-281-1300307/+30-281-1300308 Υπηρεσίες
              </div>
              H Hellasfon είναι εφοδιασμένη με τις παρακάτω{" "}
              <b>άδειες της ΕΕΤΤ που αφορούν:</b>
              <h2 style={{ color: "#0178e2" }}>
                Α.Μ. 99-176 HELLASFON NETWORKS SA
              </h2>
              Υπηρεσίες
              <br />
              <b>A.0101</b> Δίκτυο Σταθερής υπηρεσίας Ηλεκτρονικών επικοινωνιών
              <br />
              <b>B.0102</b> Εκμίσθωση χωρητικότητας
              <br />
              <b>B.0103</b> Παροχή ιδεατού ιδιωτικού δικτύου (VPN)
              <br />
              <b>B.0104</b> Παροχή Ευρυζωνικής Πρόσβασης/πρόσβασης στο διαδίκτυο
              <br />
              <b>B.0105</b> Μετάδοση δεδομένων
              <br />
              <b>B.0107</b> Υπηρεσίες Ηλεκτρονικού Ταχυδρομείου
              <br />
              <b>B.0201</b> Παροχή Τηλεφωνικών Υπηρεσιών - Συμπεριλαμβανομένων
              των υπηρεσιών καρτοκινητής τηλεφωνίας
              <br />
              <b>B.0203</b> Υπηρεσίες Τηλεφωνικών Υπηρεσιών μέσω Προπληρωμένου
              χρόνου ομιλίας
              <br />
              <br />
              <h2 style={{ color: "#0178e2" }}>
                ΑΡΙΘΜΟΔΟΤΗΣΗ - Προθέματα Δρομολόγησης για τη Φορητότητα Αριθμών
              </h2>
              <div style={{ color: "#0178e2" }}>
                581 HELLASFON NETWORKS SA 832/002/26-10-2017
              </div>
              <h2 style={{ color: "#0178e2" }}>
                ΑΡΙΘΜΟΔΟΤΗΣΗ - Γεωγραφικοί αριθμοί που έχουν εκχωρηθεί ή
                δεσμευθεί
              </h2>
              <div style={{ color: "#0178e2" }}>
                210&nbsp;310&nbsp;0000&nbsp; - 210&nbsp;310&nbsp;9999&nbsp; (ΑΘΗΝΑ) <br />
				XXX&nbsp;ΧΧΧ&nbsp;ΧΧΧΧ&nbsp; - ΧΧΧ&nbsp;ΧΧΧ&nbsp;ΧΧΧΧ&nbsp; (ΘΕΣΣΑΛΟΝΙΚΗ) <br />
				281&nbsp;130&nbsp;0000&nbsp; - 281&nbsp;130&nbsp;9999&nbsp; (ΗΡΑΚΛΕΙΟ) <br />
                283&nbsp;114&nbsp;1000&nbsp; - 283&nbsp;114&nbsp;1999&nbsp; (ΡΕΘΥΜΝΟ) <br />
				284&nbsp;113&nbsp;0000&nbsp; - 284&nbsp;113&nbsp;0999&nbsp; (ΑΓΙΟΣ ΝΙΚΟΛΑΟΣ) <br />
				284&nbsp;213&nbsp;0000&nbsp; - 284&nbsp;213&nbsp;0999&nbsp; (ΙΕΡΑΠΕΤΡΑ) <br />
				282&nbsp;114&nbsp;1000&nbsp; - 282&nbsp;114&nbsp;1999&nbsp; (ΧΑΝΙΑ) <br />
				274&nbsp;113&nbsp;0000&nbsp; - 274&nbsp;113&nbsp;0999&nbsp; (ΚΟΡΙΝΘΟΣ) <br />
				271&nbsp;040&nbsp;0000&nbsp; - 271&nbsp;040&nbsp;0999&nbsp; (ΤΡΙΠΟΛΗ) <br />
				272&nbsp;114&nbsp;1000&nbsp; - 272&nbsp;114&nbsp;1999&nbsp; (ΚΑΛΑΜΑΤΑ) <br />
				275&nbsp;213&nbsp;0000&nbsp; - 275&nbsp;213&nbsp;0999&nbsp; (ΝΑΥΠΛΙΟ) <br />
              </div>
              <br />
              <div style={{ color: "#0178e2" }}>
                Η HELLASFON είναι προσηλωμένη στην δέσμευσή της να προσφέρει ότι
                καλύτερο υπάρχει για τους πελάτες της και την Ελληνική αγορά.
                <br />
                Επενδύει συνεχώς σε νέες τεχνολογίες προσφέροντας βραβευμένες
                υπηρεσίες με παγκόσμια αναγνώριση, δοκιμασμένα συστήματα και
                CLASS 5 πλατφόρμες από τους 
                καλύτερους και μεγαλύτερους παρόχους επικοινωνίας.
                <br />
                <b>Χρησιμοποιώντας αποκλειστικά και μόνο</b> βραβευμένες λύσεις,
                εξασφαλίζουμε σε όλους τους συνδρομητές μας ασφάλεια και ποιότητα σε
                κάθε κλήση και σε κάθε επικοινωνία. Όλες οι πλατφόρμες και τα
                συστήματα που χρησιμοποιεί η HELLASFON έχουν βραβευτεί με τις
                ακόλουθες βραβεύσεις.
              </div>
              <p>
                <img
                  src={require("../assets/img/aw1.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
                <img
                  src={require("../assets/img/aw2.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
                <img
                  src={require("../assets/img/aw3.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
              </p>
              <p>
                <img
                  src={require("../assets/img/aw5.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
                <img
                  src={require("../assets/img/aw6.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
                <img
                  src={require("../assets/img/aw7.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
                <img
                  src={require("../assets/img/aw8.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
              </p>
              <CardMedia
                alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                className={classes.img}
                image={require("../assets/img/aw2.jpg")}
                title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
              />
              <CardMedia
                alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                className={classes.img}
                image={require("../assets/img/aw7.jpg")}
                title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
              />
              <h2 style={{ color: "#0178e2" }}>
                <b>Σχετικές Δημοσιεύσεις για τις Υπηρεσίες μας</b>
			         	
              </h2>
              <a href="/docs/proto_thema.pdf">
                <img
                  src={require("../assets/img/1protothema.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
              </a>
              
              <a href="docs/neakriti.png">
                <img
                  src={require("../assets/img/2nea_kriti.png")}
                  alt={"Hellasfon Κινητή"}
                />
              </a>
			  
              <a href="/docs/patrisdeth-2019.pdf">
                <img
                  src={require("../assets/img/3patris.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
              </a>
			  <br />
			  <a href="/docs/ef_sintakton_16072021.pdf">
                <img
                  src={require("../assets/img/4sintakton.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
              </a>
			  
              <a href="/docs/kathimerini_16072021.pdf">
                <img
                  src={require("../assets/img/5kathimerini.jpg")}
                  alt={"Hellasfon Κινητή"}
                />
              </a>
            </div>
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

CompanyPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(CompanyPage);
