import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusiness.jsx";

import CookieConsent from "react-cookie-consent";

class HotelPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Λύσεις για Ξενοδοχεία "}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
		  
		  
		  
            <GridItem cs={12} sm={12} md={6} style={{ padding: 10 }}>
              <h2>Απευθείας γραμμές - Υψηλής ποιότητας σύνδεση με όλους τους Διεθνής προορισμούς</h2>
              <Typography variant="body1">
			  <ul>
			    <li><b>Χαμηλές χρεώσεις</b></li>
				<li>Υψηλής ποιότητας κλήσεις πρός ΟΛΟ τον κόσμο</li>
                <li>Απεριόριστες Γραμμές & κανάλια φωνής</li>
				<li><b>Απεριόριστες κλήσεις προς την Ελλάδα καθώς και κινητά Ελλάδος μόνο με 19,50 €/μήνα </b> (στην τιμή συμπεριλαμβάνεται ΦΠΑ 24%)</li>
				<li><b>ΔΩΡΕΑΝ SIP Trunk</b> με κάθε πακέτο που θα επιλέξετε </li>
			  </ul>
				<br></br>
				<strong>Ζητήστε μας μια προσφορά βάση των αναγκών σας, και εμείς θα σχεδιάσουμε μια λύση 
				κομμένη-ραμμένη στα μέτρα σας χωρίς περιττά έξοδα ή άλλες πρόσθετες χρεώσεις.
				</strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/contact" color="primary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
			  
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/hotels-solution.jpg"
                alt="Λύσεις Hellasfon για ξενοδοχεία"
                title="Hellasfon λύσεις για ξενοδοχεία"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 30 }}
                component="img"
                image="../img/reception1.jpg"
                alt="Cloud Τηλεφωνικό κέντρο EVCOFFICE"
                title="Hellasfon - Cloud PBX EVCOFFICE"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Cloud Τηλεφωνικό Κέντρο EVCOFFICE</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
			  <ul>
			    <li>Απεριόριστες Γραμμές & Κανάλια Φωνής</li>
				<li>Ευκολος Επαγγελματικός Αριθμός</li>
				<li>Επεκτασιμότητα για όσους χρήστες επιθυμείτε χωρίς την ανάγκη αγοράς εξοπλισμού</li>
				<li>HD Ποιότητα Φωνής</li>
				<li>Αυτόματη υποδοχή κλήσεων</li>
				<li>Πλατφόρμα για Αποστολή SMS για την επιχείρηση σας</li>
			  </ul>
				<br></br>
                H Hellasfon διαθέτει πρωτοπορειακές υπηρεσίες για επαγγελματικούς πελάτες παρέχοντας :
				  <ul>
					<li><b> HD Ποιότητα ήχου </b></li>
					<li><b> Χαμηλότερες χρεώσεις κλήσεων χωρίς μεσάζοντες! </b></li>
                  </ul>
			  <Button href="/contact" color="warning">
                  ΖΗΤΗΣΤΕ ΜΑΣ ΠΡΟΣΦΟΡΑ
              </Button>
              <br></br>
			 Και σύντομα ένας εκπρόσωπος μας θα επικοινωνήσει μαζί σας για να αναλύσουμε τις ανάγκες σας και να βρούμε την βέλτιση λύση!
			 </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 30 }}>
              <h2>Αποστολή SMS για το ξενοδοχείο σας μέσω API</h2>
              <Typography variant="body1">
			  <ul>
			    <li> Στείλτε <b>μηνύματα SMS</b> στους πελάτες του ξενοδοχείου σας <b>μέσα απο την ιστοσελίδα σας !</b></li>
                <li><b> REST API</b> για πραγματικά εύκολη διασύνδεση.</li>
                <li> Οι μονάδες <b>ΔΕΝ</b> λήγουν ποτέ! </li>
			  </ul>
                <br></br>
				
				<h3><strong> Ολοκληρωμένες Λύσεις για το Ξενοδοχείο σας! </strong></h3>
				<Button href="https://myaccount.easyclicksms.com/register" color="primary">
                ΔΩΡΕΑΝ ΕΓΓΡΑΦΗ
              </Button>
			  <Button href="/sms" color="secondary">
                ΔΕΙΤΕ ΣΧΕΤΙΚΑ
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/sms1.jpg"
                alt="Υπηρεσίες SMS για ξενοδοχείαs"
                title="Hellasfon - Υπηρεσίες SMS για ξενοδοχεία"
              />
            </GridItem>
						
		  
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  
		  <AppSection />
		  </GridContainer>
		  
		  <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών σας.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

HotelPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(HotelPage);
