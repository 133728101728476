import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.jsx";

import CardMedia from "@material-ui/core/CardMedia";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";
//import SolutionSection from "./Sections/SolutionSection.jsx";
import AppSection from "../Sections/AppSectionBusiness.jsx";

import CookieConsent from "react-cookie-consent";

class WorkFromHomePage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Τηλεργασία / Τηλεδιάσκεψη"}
          </h1>
          <div>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
                <h2>Τηλεργασία - Εργασία από το σπίτι.</h2>
                <Typography variant="body1">
                  Με το τηλεφωνικό κέντρο της HELLASFON η απομακρυσμένη εργασία
                  είναι απλή υπόθεση.
                  <br />
                  <b>Τηλεργασία / Τηλεδιάσκεψη ( Audio, Video, Chat ):</b>
                  <ul>
                    <li>
                      <b>Χωρίς έξοδα για συσκευές και εξοπλισμό</b>,
                      καλείς/δέχεσαι κλήσεις στο κινητό και τον υπολογιστή σου όταν
                      είσαι σπίτι/εκτός γραφείου.
                    </li>
                    <li>
                      Κάθε χρήστης έχει επικοινωνία με όλη την εταιρεία
                      χρησιμοποιώντας βίντεο, μηνύματα και τηλέφωνο απευθείας
                      από τον υπολογιστή του στο{" "}
                      <a href="https://myaccount.hellasfon.com/vuc4">
                        MYACCOUNT
                      </a>
                      .
                    </li>
                    <li>
                      Επικοινωνήστε ταυτόχρονα με τρείς ή και περισσότερους
                      συνεργάτες.
                    </li>
                    <li>
                      Η υπηρεσία τηλεργασίας / τηλεδιάσκεψης, περιλαμβάνεται σε
                      όλα τα τηλεφωνικά κέντρα <b>EVCOFFICE</b> και για <b>ΟΛΟΥΣ</b> τους χρήστες!
                    </li>
                  </ul>
				  <Button fullWidth href="/cloudpbx#pricing" color="primary">
                  ΕΓΓΡΑΦΗ
                </Button>
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  component="img"
                  image="../img/coronavirus-hellasfon-01.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="../img/coronavirus-hellasfon-03.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  Οι εταιρικοί χρήστες του κέντρου μπορούν να συμμετέχουν στην
                  διάσκεψη:
                </h2>
                <Typography variant="body1" style={{ color: "#fff" }}>
                  <ol>
                    <li>από την επιτραπέζια συσκευή γραφείου τους, </li>
                    <li>από το κινητό τους με την EVCoffice εφαρμογή, και </li>
                    <li>
                      από τον υπολογιστή τους στο{" "}
                      <a
                        style={{ color: "white" }}
                        href="https://myaccount.hellasfon.com/VUC4"
                      >
                        MYACCOUNT
                      </a>{" "}
                      - Πύλη πελατών.
                    </li>
                  </ol>
				  <Button fullWidth href="/cloudpbx#pricing" color="secondary">
                  ΕΓΓΡΑΦΗ
                </Button>
                </Typography>
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>Οι εξωτερικοί συνεργάτες, συμμετέχουν:</h2>
                <Typography variant="body1">
                  <ol>
                    <li>
                      <b>Από το τηλέφωνο:</b> καλώντας τον τηλεφωνικό αριθμό πρόσβασης, και
                      εισάγοντας τον κωδικό που έχουν λάβει.
                    </li>
                    <li>
                      <b>Από τον υπολογιστή:</b> Ακολουθώντας τον σύνδεσμο που τους
                      έχει σταλεί,{" "}
                      συμμετέχουν στην διάσκεψη με <b>Βίντεο, φωνή και chat</b>
                    </li>
                    <li>
                      Εύκολη χρήση, χωρίς εγκαταστάσεις και πολύπλοκες
                      διαδικασίες, χωρίς ανάγκη εγγραφής.
                    </li>
                    <li>
                      <b>PLUG AND PLAY</b> - ακολουθώντας το σύνδεσμο, αυτόματα
                      συμμετέχεις στην σύσκεψη.
                    </li>
                  </ol>
				  <Button fullWidth href="/cloudpbx#pricing" color="primary">
                  ΕΓΓΡΑΦΗ
                </Button>
                </Typography>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  component="img"
                  image="../img/coronavirus-hellasfon-06.jpg"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff" }}
              >
                <CardMedia
                  style={{ padding: 70 }}
                  component="img"
                  image="../img/coronavirus_PBX-01.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ background: "#009bff", padding: 70 }}
              >
                <h2 style={{ color: "#fff" }}>
                  Δυνατότητες επικοινωνίας τηλεργασίας
                </h2>
                <Typography variant="body1" style={{ color: "#fff" }}>
                  <ul>
                    <li>Video</li>
                    <li>Φωνή</li>
                    <li>Chat και μηνύματα</li>
                  </ul>
                  <h2>Άλλες δυνατότητες:</h2>
                  <ul>
                    <li>
                      Μοιραστείτε αρχεία , φωτογραφίες, τοποθεσία με τους
                      συμμετέχοντες.
                    </li>
                  </ul>
                  <h2>Εκπαιδευτικές υπηρεσίες κέντρου</h2>
                  <ul>
                    <li>
                      * Μοιραστείτε την οθόνη σας με τους συμμετέχοντες με ένα
                      κλικ!
                    </li>
                  </ul>
				  <Button fullWidth href="/cloudpbx#pricing" color="secondary">
                  ΕΓΓΡΑΦΗ
                </Button>
                </Typography>
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
              }}
            >
              <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
                <h2>ΜΕΝΟΥΜΕ ΣΠΙΤΙ!! WORK FROM HOME!!</h2>
                <Typography variant="body1">
                  Αυξήσετε την παραγωγικότητα της επιχείρηση σας, αξιοποιώντας τα
                  <b> ΔΩΡΕΑΝ</b> εργαλεία ευέλικτης και απομακρυσμένης εργασίας που σας
                  προσφέρει το <b>EVCOFFICE Cloud Τηλεφωνικό Κέντρο</b>.
                  <br /> <br />
                </Typography>
                <Typography variant="h5">
                  <b>ONLINE Εγγραφή! Έτοιμο σε 5’ λεπτά!</b>
                  <br />
                  <br />
                </Typography>
                <Button fullWidth href="/cloudpbx#pricing" color="primary">
                  ΕΓΓΡΑΦΗ
                </Button>
              </GridItem>
              <GridItem
                cs={12}
                sm={12}
                md={6}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <CardMedia
                  style={{
                    paddingTop: 100,
                    paddingRight: 100,
                    paddingBottom: 100,
                  }}
                  component="img"
                  image="../img/coronavirus_PBX-03.png"
                  alt="home office PBX hellasfon"
                  title="home office PBX hellasfon"
                />
              </GridItem>
            </GridContainer>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

WorkFromHomePage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(WorkFromHomePage);
